import React, { useMemo } from 'react'
import {
  Collapsable,
  Column,
  RenderStringHtml,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IContentfulBasicEntry, ItemFields } from '@smu-chile/pkg-unimarc-hooks'
import { PagesContainerDescriptionTitle } from './PagesContainerDescriptionTitle'

interface PagesContainerDescriptionProps {
  items: IContentfulBasicEntry[]
  isMobile?: boolean
}

export const PagesContainerDescription = ({
  items,
  isMobile = false
}: PagesContainerDescriptionProps): React.ReactElement => {
  const backgroundColor = isMobile ? 'light' : 'white'
  const pagesContainerDescriptions = useMemo(() => {
    return [items].reverse()
  }, [items])

  const getCollapsableData = (collapsableData) => {
    const html = collapsableData?.['collapsableData']?.['html'] ? (
      <RenderStringHtml
        htmlString={collapsableData?.['collapsableData']?.['html']}
      />
    ) : (
      collapsableData?.['collapsableData']?.['text']?.['content']?.[0]?.[
        'content'
      ]?.[0]?.['value']
    )

    return {
      html: html,
      collapsableTitle: collapsableData?.['collapsableData']?.['subTitle']
    }
  }

  return (
    <>
      <Column
        alignItems='center'
        backgroundColor={getGlobalStyle(
          `--color-neutral-gray-${backgroundColor}`
        )}
        justifyContent='center'
        padding='12px'
      >
        <Spacer.Horizontal size={18} />
        <Spacer.Horizontal size={2} />
        {[pagesContainerDescriptions].map(
          (pagesContainerDescription: ItemFields) => {
            const title = pagesContainerDescription?.[0]?.['title']

            if (!title) return null

            return (
              <React.Fragment key={pagesContainerDescription?.[0]?.['title']}>
                <PagesContainerDescriptionTitle
                  key={pagesContainerDescription?.[0]?.['title']}
                  title={pagesContainerDescription?.[0]?.['title']}
                />
                {pagesContainerDescription?.map((collapsableData) => {
                  const { html, collapsableTitle } = getCollapsableData({
                    collapsableData
                  })
                  if (!html || !collapsableTitle) return null

                  return (
                    <Collapsable
                      key={collapsableTitle}
                      text={html}
                      title={collapsableTitle}
                    />
                  )
                })}
              </React.Fragment>
            )
          }
        )}
      </Column>
    </>
  )
}
