import { useEffect, useRef } from 'react'
import {
  Container,
  CouponProps,
  CouponV2
} from '@smu-chile/pkg-unimarc-components'
import {
  getCouponName,
  initCouponsImpression,
  TaggableCoupon,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { CouponSimpleListLoader } from './CouponListLoader/CouponListLoader'

export interface CouponSimpleListProps {
  coupons: CouponProps[]
  isLoading?: boolean
  selectedFilter?: string
}

export const CouponSimpleList = ({
  coupons = [],
  isLoading,
  selectedFilter
}: CouponSimpleListProps) => {
  const { isMobile } = useMobile()
  const container = useRef()
  const couponsToActivate = useRef<TaggableCoupon<HTMLButtonElement>[]>([])

  const assignCouponRef = (
    ref: HTMLButtonElement | HTMLAnchorElement,
    coupon: CouponProps,
    index: number
  ) => {
    const taggableCoupon: TaggableCoupon = {
      ref,
      id: coupon.id,
      index: `${coupon.index + 1}`,
      name: getCouponName(coupon)
    }
    if (coupon.status === undefined && ref instanceof HTMLButtonElement) {
      couponsToActivate.current[index] =
        taggableCoupon as TaggableCoupon<HTMLButtonElement>
      return
    }
  }

  useEffect(() => {
    if (!selectedFilter)
      return () => {
        return
      }
    const removeEventListener = initCouponsImpression({
      coupons: couponsToActivate.current,
      creativeName: 'boton-activar',
      eventKey: 'boton-activar',
      locationId: `shelf-cupones-${selectedFilter}`,
      couponType: selectedFilter
    })

    couponsToActivate.current = []
    return removeEventListener
  }, [couponsToActivate.current, selectedFilter])

  if (isLoading) {
    return <CouponSimpleListLoader />
  }

  return (
    <>
      {coupons.map((coupon, index) => {
        return (
          <Container
            customHeight='100%'
            key={index}
            maxWidth='353px'
            ref={container}
          >
            <CouponV2
              activeButtonRef={(ref) => {
                return assignCouponRef(ref, coupon, index)
              }}
              couponStatus={coupon.status}
              couponType={coupon.label}
              cutterHeight={isMobile ? 16 : 24}
              discount={coupon.discount}
              expiration={coupon.endDate}
              imageUrl={coupon.imageSrc}
              isLoading={coupon.loading}
              isMobile={isMobile}
              onClickButton={coupon.onClick}
              onClickCoupon={coupon.onClickViewProducts}
              product={coupon.description}
              subtitle={coupon.subtitle}
              tag={coupon.tag}
              title={coupon.title}
              unitsPerClient={coupon.maxUses.toString()}
              width='100%'
            />
          </Container>
        )
      })}
    </>
  )
}
