import { Button } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export const ErrorGoToHomeButton = (): React.ReactElement => {
  const { isMobile } = useMobile()
  const errorButtonLabel = 'Ver otras ofertas'
  const errorButtonWidth = isMobile ? '100%' : '335px'
  const typeButton = 'secondary'

  const handdleReloadPage = () => {
    window.location.href = process.env.NEXT_PUBLIC_HOMEURL
  }

  return (
    <>
      <Button
        borderRadius={getGlobalStyle('--border-radius-xl')}
        fontWeight='500'
        height='45px'
        label={errorButtonLabel}
        onClick={handdleReloadPage}
        type={typeButton}
        width={errorButtonWidth}
      />
    </>
  )
}
