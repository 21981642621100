import { ReactChild } from 'react'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { CouponDetailStamp } from '../../CouponDetailStamp/CouponDetailStamp'

export const CouponChannelColorMap: Record<string, string> = {
  all: '',
  'Solo App': getGlobalStyle('--color-campaigns-orange'),
  'Solo Tienda': getGlobalStyle('--color-campaigns-blue')
}

export interface CouponDetailHeaderStampProps {
  children: ReactChild
}

export const CouponDetailHeaderStamp = ({
  children
}: CouponDetailHeaderStampProps) => {
  return (
    <>
      <SmallScreen>
        <CouponDetailStamp
          shadowless
          stampSize={32}
        >
          {children}
        </CouponDetailStamp>
      </SmallScreen>

      <BigScreen>
        <CouponDetailStamp stampSize={32}>{children}</CouponDetailStamp>
      </BigScreen>
    </>
  )
}
