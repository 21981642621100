export const paramsToCheck = [
  'brands',
  'container',
  'foodWorld',
  'priceRange',
  'priceRangeFrom',
  'priceRangeTo',
  'volume',
  'warningStamps'
]
