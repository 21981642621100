import {
  Column,
  Container,
  ModalBottomSheet,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { ButtonStatus } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { useState } from 'react'

interface ModalConfirmChangeAddressProps {
  confirmAddressModalImage: string
  isMobile: boolean
  openModalConfirmChangeAddress: boolean
  confirmUserAddress: (value?: boolean) => void
  setOpenModalConfirmChangeAddress: React.Dispatch<
    React.SetStateAction<boolean>
  >
}
export const ModalConfirmChangeAddress = ({
  confirmAddressModalImage,
  isMobile,
  openModalConfirmChangeAddress,
  confirmUserAddress,
  setOpenModalConfirmChangeAddress
}: ModalConfirmChangeAddressProps) => {
  const customMaxWidth = isMobile ? '265px' : '340px'
  const customMargin = isMobile ? '15.5px 0 0 0' : '37pxpx 0 0 0'
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
  const [secondaryBtnStatus, setSecondaryBtnStatus] =
    useState<ButtonStatus>('initial')

  const handleChangeAddress = () => {
    confirmUserAddress()
    setLoadingBtn(true)
    setSecondaryBtnStatus('disabled')
  }

  const handleKeepAddress = () => {
    setOpenModalConfirmChangeAddress(false)
  }

  return (
    <ModalBottomSheet
      customHeightContent='100%'
      isLoadingBtn={loadingBtn}
      isMobile={isMobile}
      isOpen={openModalConfirmChangeAddress}
      isWindowBlocked={true}
      maxWidthModal='440px'
      minHeightModal='500px'
      minHeightModalMobile='500px'
      onClose={() => {
        return setOpenModalConfirmChangeAddress(false)
      }}
      primaryBtnLabel='Sí, continuar'
      primaryCTA={handleChangeAddress}
      secondaryBtnLabel='No gracias'
      secondaryBtnStatus={secondaryBtnStatus}
      secondaryCTA={handleKeepAddress}
    >
      <>
        <Column
          alignItems='center'
          justifyContent='center'
          width='250px'
        >
          <Picture
            alt='sandwich'
            height='auto'
            src={confirmAddressModalImage}
            width={isMobile ? '180px' : '243px'}
          />
          <Container
            justifyContent='center'
            margin={customMargin}
          >
            <Text
              fontSize='xl'
              fontWeight='semibold'
              textAlign='center'
            >
              Que no se te escapen...
            </Text>
          </Container>
        </Column>
        <Spacer.Horizontal size={isMobile ? 16 : 12} />
        <Row maxWidth={customMaxWidth}>
          <Text
            fontWeight='regular'
            isFlex
            textAlign='center'
          >
            Recuerda que si haces este cambio, alguno de tus productos podrían
            estar agotados ¿deseas continuar?
          </Text>
        </Row>
      </>
    </ModalBottomSheet>
  )
}
