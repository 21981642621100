import {
  Text,
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  InputLabel,
  LegalsAndToggleSwitch,
  Button
} from '@smu-chile/pkg-unimarc-components'
import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { formatTextWithLineBreaks } from '@smu-chile/pkg-unimarc-components/shared/helpers'
import { useEvents, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { IToastWrapperProps, ToastWrapper } from 'components'
import { IUseMembershipQueryLevelProps } from 'components/ModalMembershipLevelQueryWrapper/helpers/useQueryLevelMembershipForm'
import { useState } from 'react'
import { ModalTermsAndConditions } from '..'
import { ModalPrivacyPolicy } from '../ModalPrivacyPolicy'
import { emailPattern } from 'components/SignUpPage/SignUpForm/helpers'

type TMembershipIcon = {
  name: keyof typeof Icons
  backgroundColor?: string
  color?: string
  width?: number
  heigth?: number
  pathTransform?: string
  viewBoxProps?: string
}

const MEMBERSHIP_ICONS: TMembershipIcon[] = [
  {
    name: 'WinkyIconMembership',
    backgroundColor: getGlobalStyle('--color-background-pacific-blue'),
    color: getGlobalStyle('--color-base-white'),
    viewBoxProps: '0 0 25 25',
    pathTransform: 'translate(1.5, 2.5)',
    heigth: 25
  },
  {
    name: 'HeartIconMembership',
    backgroundColor: getGlobalStyle('--color-background-dark-orange2'),
    color: getGlobalStyle('--color-base-white'),
    viewBoxProps: '0 0 35 25',
    pathTransform: 'translate(0.5, 3.5)',
    heigth: 25
  },
  {
    name: 'CrownIconMembership',
    backgroundColor: getGlobalStyle('--color-background-air-superiority-blue'),
    color: getGlobalStyle('--color-base-white'),
    viewBoxProps: '0 0 20 25',
    heigth: 25
  },
  {
    name: 'DiamondMembership',
    backgroundColor: getGlobalStyle('--color-background-peacock-blue'),
    color: getGlobalStyle('--color-base-white'),
    viewBoxProps: '0 0 21 21',
    pathTransform: 'translate(1, -1)',
    heigth: 25
  }
]

export type IModalMembershipLevelQueryContentProps =
  IUseMembershipQueryLevelProps & {
    blockId: string
    isBlockedBySecurity: boolean
    isLoading: boolean
    handleKnowMyLevel: (rut: string, email?: string) => void
  }

export const ModalMembershipLevelQueryContent = ({
  value,
  blockId,
  rutStatus,
  emailStatus,
  isSelectCheck,
  isBlockedBySecurity,
  isLoading,
  handleCleanRut,
  handleCleanEmail,
  handleChange,
  handleSelectCheck,
  handleKnowMyLevel
}: IModalMembershipLevelQueryContentProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)
  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] =
    useState(false)
  const [toastProps, setToastProps] = useState<
    IToastWrapperProps & { show: boolean }
  >({
    errorCode: '',
    success: false,
    message: '',
    show: false
  })
  const buttonStatus = isSelectCheck ? 'initial' : 'disabled'
  useEvents({
    eventType: 'membershipToast',
    callBack: ({ detail }) => {
      setToastProps(detail)
    }
  })
  const handleToggleTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(!isTermsAndConditionsOpen)
  }

  const handleTogglePrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen)
  }
  const handleCloseToast = () => {
    setToastProps({
      ...toastProps,
      show: false
    })
  }
  const buttonContinueStatus = () => {
    if (
      isBlockedBySecurity ||
      value?.rut?.length === 0 ||
      (value?.email && !emailPattern.test(value?.email))
    ) {
      return 'disabled'
    }
    return buttonStatus
  }

  return (
    <>
      {isTermsAndConditionsOpen && (
        <ModalTermsAndConditions
          handleToggle={handleToggleTermsAndConditions}
        />
      )}
      {isPrivacyPolicyOpen && (
        <ModalPrivacyPolicy handleToggle={handleTogglePrivacyPolicy} />
      )}

      {toastProps.show && !isMobile && (
        <ToastWrapper
          containerProps={{
            position: 'absolute',
            zIndex: '80',
            top: '20'
          }}
          errorCode={toastProps.errorCode}
          message={toastProps.message}
          onClose={handleCloseToast}
          success={toastProps.success}
        />
      )}

      <Container
        alignItems='center'
        customHeight='100%'
        justifyContent='center'
        position='relative'
        tagName='section'
      >
        <BigScreen>
          <Row>
            <Column minWidth='320px'>
              {/* welcome section */}
              <Row tagName='article'>
                <Column
                  minWidth='100%'
                  tagName='section'
                >
                  {/* welcome messages */}
                  <Row tagName='article'>
                    <Column alignItems='center'>
                      <Text
                        fontSize='5xl'
                        id={getBemId(blockId, 'welcome-text')}
                      >
                        ¡Hola!
                      </Text>
                      <Spacer.Horizontal customSize={7} />
                      <Text
                        fontSize='xl'
                        id={getBemId(blockId, 'welcome-subtitle')}
                        textAlign='center'
                      >
                        {formatTextWithLineBreaks(
                          'Revisa tu nivel de socio y disfruta de\ndescuentos y beneficios'
                        )}
                      </Text>
                    </Column>
                  </Row>
                  <Spacer.Horizontal customSize={33} />
                  {/* club unimarc logo */}
                  <Row
                    id={getBemId(blockId, 'altiro-logo')}
                    justifyContent='center'
                    position='relative'
                    tagName='article'
                    width='100%'
                  >
                    <Icon
                      key='clubUnimarcLogo'
                      name='ClubUnimarcLogo'
                    />
                  </Row>
                  <Spacer.Horizontal size={18} />
                  {/* row of membership icons */}
                  <Row
                    justifyContent='center'
                    tagName='article'
                    width='100%'
                  >
                    <Container
                      justifyContent='between'
                      maxWidth='215px'
                    >
                      {MEMBERSHIP_ICONS.map((membershipIcon) => {
                        return (
                          <Column
                            alignItems='center'
                            backgroundColor={membershipIcon?.backgroundColor}
                            borderRadius='50%'
                            id={getBemId(blockId, membershipIcon?.name)}
                            justifyContent='center'
                            key={membershipIcon?.name}
                            maxWidth='45px'
                            minHeight='45px'
                            padding='10px 5px'
                          >
                            <Icon
                              color={membershipIcon?.color}
                              height={membershipIcon?.heigth}
                              name={membershipIcon?.name}
                              pathTransform={membershipIcon?.pathTransform}
                              viewBoxProp={membershipIcon?.viewBoxProps}
                              width={membershipIcon?.width}
                            />
                          </Column>
                        )
                      })}
                    </Container>
                  </Row>
                </Column>
              </Row>
            </Column>
            <Spacer.Horizontal customSize={41} />
            <Column minWidth='343px'>
              <Row tagName='article'>
                {/* balance section */}
                <Column
                  border={`0.3px solid ${getGlobalStyle(
                    '--color-neutral-gray-divider'
                  )}`}
                  borderRadius={getGlobalStyle('--border-radius-2lg')}
                  maxWidth={isMobile ? '100%' : '375px'}
                  minWidth='100%'
                  padding='20px 16px'
                  tagName='section'
                >
                  {/* rut input validation */}
                  <Row tagName='article'>
                    <InputLabel
                      appendSlotContainerProps={{
                        width: 55
                      }}
                      error={rutStatus === 'error'}
                      fontSize='base'
                      fullWidth
                      height={44}
                      id={getBemId(blockId, 'rutInputLabel')}
                      inputWidth='360px'
                      label='Rut'
                      name='rut'
                      onChange={handleChange}
                      onClickIcon={() => {
                        handleCleanRut()
                      }}
                      showXIcon={value?.rut?.length > 0}
                      value={value?.rut}
                      variant='compact'
                    />
                  </Row>
                  <Spacer.Horizontal customSize={16} />
                  {/* email input validation */}
                  <Row tagName='article'>
                    <InputLabel
                      appendSlotContainerProps={{
                        width: 55
                      }}
                      error={emailStatus === 'error'}
                      fontSize='base'
                      fullWidth
                      height={44}
                      id={getBemId(blockId, 'emailInputLabel')}
                      inputWidth='360px'
                      label='Correo electrónico (Opcional)'
                      name='email'
                      onChange={handleChange}
                      onClickIcon={() => {
                        handleCleanEmail()
                      }}
                      showXIcon={value?.email?.length > 0}
                      value={value?.email}
                      variant='compact'
                    />
                  </Row>
                  <Spacer.Horizontal customSize={16} />
                  {/* t&c and p&c */}
                  <Row tagName='article'>
                    <LegalsAndToggleSwitch
                      blockId={blockId}
                      handleSelectCheck={handleSelectCheck}
                      handleTogglePrivacyPolicy={handleTogglePrivacyPolicy}
                      handleToggleTermsAndConditions={
                        handleToggleTermsAndConditions
                      }
                      isSelectCheck={isSelectCheck}
                    />
                  </Row>
                  <Spacer.Horizontal customSize={23} />
                  {/* cta */}
                  <Row tagName='article'>
                    <Button
                      borderRadius={getGlobalStyle('--border-radius-xl')}
                      fontSize={getGlobalStyle('--font-size-base')}
                      fontWeight={getGlobalStyle('--font-weight-medium')}
                      height='40px'
                      id={getBemId(blockId, 'cta-button')}
                      isLoading={isLoading}
                      label='Conocer mi nivel'
                      onClick={() => {
                        return handleKnowMyLevel(value?.rut, value?.email)
                      }}
                      padding='10px 20px'
                      status={buttonContinueStatus()}
                      width='100%'
                    />
                  </Row>
                </Column>
              </Row>
              <Spacer.Horizontal customSize={25} />
              {/* altiro logo */}
              <Row
                alignItems='center'
                id={getBemId(blockId, 'altiro-logo')}
                justifyContent='center'
                tagName='article'
                width='100%'
              >
                <Icon
                  color={getGlobalStyle('--color-icons-black')}
                  key='altiroLogo'
                  name='AltiroLogo'
                />
              </Row>
            </Column>
          </Row>
        </BigScreen>
        <SmallScreen>
          <Column maxWidth='375px'>
            {toastProps.show && (
              <ToastWrapper
                containerProps={{
                  position: 'absolute',
                  zIndex: '80'
                }}
                errorCode={toastProps.errorCode}
                maxWidth='343px'
                message={toastProps.message}
                onClose={handleCloseToast}
                right='initial'
                success={toastProps.success}
              />
            )}

            {/* welcome section */}
            <Row tagName='article'>
              <Column
                maxWidth='100%'
                minWidth='100%'
                tagName='section'
              >
                {/* club unimarc logo */}
                <Row
                  id={getBemId(blockId, 'altiro-logo')}
                  justifyContent='center'
                  position='relative'
                  tagName='article'
                  width='100%'
                >
                  <Icon
                    key='clubUnimarcLogo'
                    name='ClubUnimarcLogo'
                  />
                </Row>
                <Spacer.Horizontal size={20} />
                {/* row of membership icons */}
                <Row
                  justifyContent='center'
                  tagName='article'
                  width='100%'
                >
                  <Container
                    justifyContent='between'
                    maxWidth='215px'
                  >
                    {MEMBERSHIP_ICONS.map((membershipIcon) => {
                      return (
                        <Column
                          alignItems='center'
                          backgroundColor={membershipIcon?.backgroundColor}
                          borderRadius='50%'
                          id={getBemId(blockId, membershipIcon?.name)}
                          justifyContent='center'
                          key={membershipIcon?.name}
                          maxWidth='45px'
                          minHeight='45px'
                          padding='10px 5px'
                        >
                          <Icon
                            color={membershipIcon?.color}
                            height={membershipIcon?.heigth}
                            name={membershipIcon?.name}
                            pathTransform={membershipIcon?.pathTransform}
                            viewBoxProp={membershipIcon?.viewBoxProps}
                            width={membershipIcon?.width}
                          />
                        </Column>
                      )
                    })}
                  </Container>
                </Row>
                <Spacer.Horizontal size={20} />
                <Row tagName='article'>
                  <Column alignItems='center'>
                    <Text
                      fontSize='3xl'
                      id={getBemId(blockId, 'welcome-text')}
                    >
                      ¡Hola!
                    </Text>
                    <Spacer.Horizontal customSize={7} />
                    <Text
                      fontSize='lg'
                      id={getBemId(blockId, 'welcome-subtitle')}
                      textAlign='center'
                    >
                      {formatTextWithLineBreaks(
                        'Revisa tu nivel de socio y disfruta de\ndescuentos y beneficios'
                      )}
                    </Text>
                  </Column>
                </Row>
              </Column>
            </Row>
            <Spacer.Horizontal customSize={26} />
            <Row tagName='article'>
              {/* balance section */}
              <Column
                border={`0.3px solid ${getGlobalStyle(
                  '--color-neutral-gray-divider'
                )}`}
                borderRadius={getGlobalStyle('--border-radius-2lg')}
                maxWidth='100%'
                minWidth='100%'
                padding='20px 16px'
                tagName='section'
              >
                {/* rut input validation */}
                <Row tagName='article'>
                  <InputLabel
                    appendSlotContainerProps={{
                      width: 55
                    }}
                    error={rutStatus === 'error'}
                    fontSize='base'
                    fullWidth
                    height={44}
                    id={getBemId(blockId, 'rutInputLabel')}
                    inputWidth='360px'
                    label='Rut'
                    name='rut'
                    onChange={handleChange}
                    onClickIcon={() => {
                      handleCleanRut()
                    }}
                    showXIcon={value?.rut?.length > 0}
                    value={value?.rut}
                    variant='compact'
                  />
                </Row>
                <Spacer.Horizontal customSize={16} />
                {/* email input validation */}
                <Row tagName='article'>
                  <InputLabel
                    appendSlotContainerProps={{
                      width: 55
                    }}
                    error={emailStatus === 'error'}
                    fontSize='base'
                    fullWidth
                    height={44}
                    id={getBemId(blockId, 'emailInputLabel')}
                    inputWidth='360px'
                    label='Correo electrónico (Opcional)'
                    name='email'
                    onChange={handleChange}
                    onClickIcon={() => {
                      handleCleanEmail()
                    }}
                    showXIcon={value?.email?.length > 0}
                    value={value?.email}
                    variant='compact'
                  />
                </Row>
                <Spacer.Horizontal customSize={16} />
                {/* t&c and p&c */}
                <Row
                  minWidth='100%'
                  tagName='article'
                >
                  <LegalsAndToggleSwitch
                    blockId={blockId}
                    handleSelectCheck={handleSelectCheck}
                    handleTogglePrivacyPolicy={handleTogglePrivacyPolicy}
                    handleToggleTermsAndConditions={
                      handleToggleTermsAndConditions
                    }
                    isSelectCheck={isSelectCheck}
                  />
                </Row>
                <Spacer.Horizontal customSize={23} />
                {/* cta */}
                <Row tagName='article'>
                  <Button
                    borderRadius={getGlobalStyle('--border-radius-xl')}
                    fontSize={getGlobalStyle('--font-size-base')}
                    fontWeight={getGlobalStyle('--font-weight-medium')}
                    height='40px'
                    id={getBemId(blockId, 'cta-button')}
                    isLoading={isLoading}
                    label='Conocer mi nivel'
                    onClick={() => {
                      return handleKnowMyLevel(value?.rut, value?.email)
                    }}
                    padding='10px 20px'
                    status={buttonContinueStatus()}
                    width='100%'
                  />
                </Row>
              </Column>
            </Row>
            <Spacer.Horizontal customSize={9} />
            {/* altiro logo */}
            <Row
              alignItems='center'
              id={getBemId(blockId, 'altiro-logo')}
              justifyContent='center'
              tagName='article'
              width='100%'
            >
              <Icon
                color={getGlobalStyle('--color-icons-black')}
                key='altiroLogo'
                name='AltiroLogo'
              />
            </Row>
          </Column>
        </SmallScreen>
      </Container>
    </>
  )
}
