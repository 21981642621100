interface ProductTitleProps {
  descriptionShort?: string
  name?: string
  brand?: string
  format?: string
}
export const productTitleName = ({
  descriptionShort,
  name,
  brand,
  format
}: ProductTitleProps): string => {
  if (!descriptionShort) {
    return name
  }
  return `${brand} ${descriptionShort} ${format}`
}

export const productPDPTitleName = ({
  descriptionShort,
  name,
  brand,
  format
}: ProductTitleProps): string => {
  if (!descriptionShort) {
    return name
  }
  return `${descriptionShort} ${brand} ${format}`
}
