import {
  Backbone,
  Button,
  Column,
  Container,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  datalayerSimpleEvent,
  useEvents,
  useMiniCart
} from '@smu-chile/pkg-unimarc-hooks'
import ButtonMakeOrder from './ButtonMakeOrder'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMiniCart'
import { useState } from 'react'
import { useRouter } from 'next/router'
import styles from './FooterMiniCart.module.css'
import { SelectedAddresses } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'

export const FooterMiniCart = ({
  selectedAddresses
}: {
  selectedAddresses: SelectedAddresses
}): React.ReactElement => {
  const router = useRouter()
  const { data, isRefetching, refetch } = useMiniCart()
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading } }) => {
      if (isOrderFormLoading && !loading) refetch()
      setIsOrderFormLoading(loading)
    }
  })

  const orderForm: Data = data?.data

  const goToCart = () => {
    setButtonLoading(true)
    datalayerSimpleEvent({
      event: 'interacciones-minicarro',
      eventAction: 'clic',
      eventCategory: 'mini-carro',
      eventLabel: 'ver carro'
    })

    setTimeout(() => {
      setButtonLoading(false)
      router.push(process.env.NEXT_PUBLIC_CARTURL)
    }, 600)
  }

  return (
    <Container
      backgroundColor={getGlobalStyle('--color-base-white')}
      isWrap
    >
      <Row justifyContent='between'>
        <Column>
          <Text
            fontSize='md'
            fontWeight='semibold'
          >
            Subtotal:
          </Text>
        </Column>
        <Column width='65px'>
          {(isOrderFormLoading || isRefetching) && (
            <Backbone
              backgroundColor={getGlobalStyle('--color-neutral-gray')}
              borderRadius={getGlobalStyle('--border-radius-xs')}
              height={23}
              width={100}
            />
          )}
          {!isOrderFormLoading && orderForm && !isRefetching && (
            <Text
              fontSize='md'
              fontWeight='semibold'
            >
              {orderForm?.subtotal}
            </Text>
          )}
        </Column>
      </Row>
      <Spacer.Horizontal size={16} />
      <Row justifyContent='center'>
        <ButtonMakeOrder
          loading={isOrderFormLoading || isRefetching}
          selectedAddresses={selectedAddresses}
        />
      </Row>
      <Spacer.Horizontal size={8} />
      <Row>
        <Button
          borderRadius={getGlobalStyle('--border-radius-xl')}
          customClassName={styles.button__makeOrder}
          fontWeight={getGlobalStyle('--font-weight-semibold')}
          height='45px'
          label='Ver carro'
          onClick={goToCart}
          size='xs'
          status={
            isOrderFormLoading || buttonLoading || isRefetching
              ? 'loading'
              : 'initial'
          }
          type='secondary'
          width='100%'
        />
      </Row>
    </Container>
  )
}
