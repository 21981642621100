import {
  Column,
  Container,
  Icon,
  Row,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const TooltipAlcohol = ({
  onClose
}: {
  onClose: () => void
}): React.ReactElement => {
  return (
    <Tooltip
      alignAllItems='center'
      background={getGlobalStyle('--color-neutral-black-olive')}
      bottom=''
      closeIcon
      left='16px'
      maxWidth='377px'
      onClick={onClose}
      padding='16px'
      position='sticky'
      positionContainer='absolute'
      right=''
      text=''
      textComponent={
        <Container maxHeight='60px'>
          <Row alignItems='center'>
            <Column
              margin='0 10px 0 0'
              maxWidth='max-content'
            >
              <Icon
                color={getGlobalStyle('--color-base-white')}
                customSize={20}
                name='Info'
              />
            </Column>
            <Column maxWidth='286px'>
              <Text
                color='white'
                fontSize='md'
              >
                Deberás mostrar tu carnet al recibir compras de bebidas
                alcohólicas.
              </Text>
            </Column>
          </Row>
        </Container>
      }
      top='136px'
      type='bottom'
      width='377px'
    />
  )
}
