export const filterIconDictionary = (key: string): string => {
  const dictionary: Record<string, string> = {
    'libre-de-soya': 'soy_free',
    'libre-de-lactosa': 'lactose_free',
    'libre-de-huevo': 'egg_free',
    'libre-de-mariscos': 'seafood_free',
    'libre-de-mani': 'peanut_free',
    'libre-de-frutos-secos': 'nuts_free',
    'libre-de-nuez': 'walnuts_free',
    vegano: 'vegan',
    vegetariano: 'vegetarian',
    'libre-de-peces': 'fish_free',
    'apto-para-diabeticos': 'diabetes_suitable',
    'apto-para-aplv': 'aplv_suitable',
    'libre-de-gluten': 'gluten_free',
    'libre-de-trigo': 'wheat_free',
    'libre-de-sulfitos': 'sulphite_free',
    halal: 'halal',
    kosher: 'kosher'
  }

  return dictionary[key] || ''
}
