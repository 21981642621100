import { Button } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface IErrorReloadPageButton {
  errorType: string
}

export const ErrorReloadPageButton = ({
  errorType
}: IErrorReloadPageButton): React.ReactElement => {
  const { isMobile } = useMobile()
  const errorButtonLabel = 'Recargar página'
  const errorButtonWidth = isMobile ? '100%' : '335px'
  const typeButton = 'primary-gray'

  const handdleReloadPage = () => {
    if (errorType !== '400' && errorType !== '500') {
      window.location.href = process.env.NEXT_PUBLIC_HOMEURL
    } else {
      window.location.reload()
    }
  }

  return (
    <>
      <Button
        borderRadius={getGlobalStyle('--border-radius-xl')}
        fontWeight='500'
        height='45px'
        label={errorButtonLabel}
        onClick={handdleReloadPage}
        type={typeButton}
        width={errorButtonWidth}
      />
    </>
  )
}
