import { GetServerSidePropsContext } from 'next'
import { AxiosRequestHeaders } from '@smu-chile/pkg-unimarc-hooks'

export const getHeaderCookies = (
  context: GetServerSidePropsContext
): AxiosRequestHeaders => {
  let headers = {}
  const cookies = context?.req?.cookies
  if (cookies?.co_sc || cookies?.co_idtoken) {
    if (!cookies?.co_sc) cookies.co_sc = '1'

    headers = {
      Cookie: `co_sc=${cookies?.co_sc?.toString()};co_idtoken=${cookies?.co_idtoken?.toString()}`
    }
  }
  return headers
}
