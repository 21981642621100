import { ReactNode, useState } from 'react'
import {
  Button,
  Column,
  Container,
  Divider,
  Icon,
  Modal,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './SignUpLabelTooltip.module.css'

export interface SignUpLabelTooltipProps {
  children?: ReactNode
  iconColor?: string
  tooltipMobile?: ReactNode
  tooltipMobileTitle?: ReactNode
}

export const SignUpLabelTooltip = ({
  children,
  iconColor = getGlobalStyle('--color-neutral-gray-input'),
  tooltipMobile,
  tooltipMobileTitle
}: SignUpLabelTooltipProps) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  const handleInfoIconSmallScreenClick = () => {
    setIsInfoModalOpen(true)
  }

  const handleInfoModalCloseClick = () => {
    setIsInfoModalOpen(false)
  }

  return (
    <Row
      justifyContent='end'
      position='relative'
    >
      <BigScreen>
        <Row
          absoluteDefault='topRight'
          clickable='pointer'
          customClassName={styles['sign-up-label__tooltip']}
          margin='13px 14px 0 0'
          position='absolute'
          width='auto'
          zIndex='2'
        >
          <Icon
            clickable='pointer'
            color={iconColor}
            customSize={16}
            name='InfoThin2'
          />

          <Column
            absoluteDefault='topRight'
            alignItems='center'
            margin='6px -124px 0 0'
            position='absolute'
            width='262px'
          >
            <Column
              clickable='pointer'
              customClassName={styles['sign-up-label__arrow']}
              margin='0 24px'
              maxWidth='24px'
            />

            <Column
              backgroundColor={getGlobalStyle('--color-neutral-gray-dark')}
              borderRadius='8px'
              boxShadow={getGlobalStyle('--box-shadow-3md')}
              padding='12px 16px'
            >
              {children}
            </Column>
          </Column>
        </Row>
      </BigScreen>

      <SmallScreen>
        <>
          <Row
            absoluteDefault='topRight'
            clickable='pointer'
            customClassName={styles['sign-up-label__tooltip']}
            margin='13px 14px 0 0'
            position='absolute'
            width='auto'
            zIndex='2'
          >
            <Icon
              clickable='pointer'
              color={iconColor}
              customSize={16}
              name='InfoThin2'
              onClick={handleInfoIconSmallScreenClick}
            />

            {!tooltipMobile && (
              <Column
                absoluteDefault='topRight'
                alignItems='end'
                margin='4px -29px 0 0'
                position='absolute'
                width='262px'
              >
                <Column
                  customClassName={styles['sign-up-label__arrow']}
                  margin='0 24px'
                  maxWidth='24px'
                />

                <Column
                  backgroundColor={getGlobalStyle('--color-neutral-gray-dark')}
                  borderRadius='8px'
                  boxShadow={getGlobalStyle('--box-shadow-3md')}
                  padding='8px 16px'
                >
                  {children}
                </Column>
              </Column>
            )}
          </Row>

          <Modal
            customContainerClassName={styles['sign-up-label__modal']}
            draggButton={false}
            fullScreen
            hiddenX
            iconPadding='22px'
            isOpen={isInfoModalOpen}
            minHeightFullScreen='auto'
            padding='1rem 0 0'
            sizeIconClose={20}
            toggle={handleInfoModalCloseClick}
            toggleOutside={handleInfoModalCloseClick}
          >
            <Column>
              <Column
                alignItems='center'
                gap={24}
                padding='.5rem 0 0'
              >
                <Row
                  alignItems='center'
                  justifyContent='center'
                  position='relative'
                >
                  <Text
                    fontWeight='semibold'
                    lineHeight='17px'
                  >
                    {tooltipMobileTitle}
                  </Text>

                  <Container
                    absoluteDefault='topRight'
                    clickable='pointer'
                    onClick={handleInfoModalCloseClick}
                    padding='0 1.5rem'
                    position='absolute'
                    width='auto'
                    zIndex='10'
                  >
                    <Icon
                      clickable='pointer'
                      color='black'
                      customSize={20}
                      name='CloseThin'
                      onClick={handleInfoModalCloseClick}
                    />
                  </Container>
                </Row>

                <Divider
                  borderHeight='3xs'
                  color={getGlobalStyle('--color-neutral-gray-divider')}
                  verticalMargin={0}
                />
              </Column>
            </Column>

            {tooltipMobile}

            <Column
              borderRadius='1rem'
              padding='1rem'
            >
              <Button
                borderRadius='1.5rem'
                customClassName={styles['sign-up-label-label__button']}
                fullWidth
                label='Entendido'
                onClick={handleInfoModalCloseClick}
              />
            </Column>
          </Modal>
        </>
      </SmallScreen>
    </Row>
  )
}
