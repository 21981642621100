import { Text, Column, Horizontal } from '@smu-chile/pkg-unimarc-components'
import NameStyles from './Name.module.css'

export interface NameProps {
  brand?: string
  measurement?: string
  name: string
}

export const Name = ({
  brand,
  measurement,
  name
}: NameProps): React.ReactElement<NameProps> => {
  return (
    <Column>
      <Text
        customClassName={NameStyles.productName__brand}
        fontSize='xs'
        fontWeight='semibold'
      >
        {`${brand}`.toLowerCase()}
      </Text>
      <Horizontal size={2} />
      <Text
        fontSize='sm'
        lineClamp={1}
      >
        {name}
      </Text>
      <Text
        color='gray'
        fontSize='2xs'
      >
        {measurement}
      </Text>
    </Column>
  )
}
