import {
  Column,
  Container,
  Row,
  Text,
  Button,
  Spacer,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { formatTextWithLineBreaks } from '@smu-chile/pkg-unimarc-components/shared/helpers'
import { useContentful, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { MACARONS_ERROR_IMG_V2 } from 'shared/utils/constanst'

interface ImageSizesProps {
  width: string
  height: string
}
interface ProductDetailErrorProps {
  hideButton?: boolean
  handleReload?: () => void
  customImageSize?: ImageSizesProps
  modalPLP?: boolean
}

const getPropsByPortView = ({
  isMobile = false,
  modalPLP = false,
  customImageSize
}) => {
  if (isMobile)
    return {
      containerMinHeight: '100%',
      containerPadding: '10px 0 0 0',
      columnPadding: '0',
      buttonWidth: '295px',
      imageWidth: customImageSize?.width ?? '200px',
      imageHeight: customImageSize?.height ?? '250px'
    }

  return {
    containerMinHeight: '505px',
    containerPadding: modalPLP ? '0' : '68px 0 0 0',
    columnPadding: '42px 0 0 0',
    buttonWidth: '410px',
    imageHeight: customImageSize?.height ?? '369px',
    imageWidth: customImageSize?.width ?? '320px'
  }
}
export const ProductDetailError = ({
  hideButton = false,
  handleReload,
  customImageSize,
  modalPLP = false
}: ProductDetailErrorProps) => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const handleDetailErrorFunction = () => {
    window.location.reload()
  }
  const onReload = handleReload ?? handleDetailErrorFunction

  const macaronsErrorImg = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-macarons-img',
    options: {
      'sys.id': MACARONS_ERROR_IMG_V2[0]
    },
    type: 'assets'
  })
  const macaronsImg =
    macaronsErrorImg.data?.['items']?.[0]?.fields?.file?.url || ''

  const portViewSizes = getPropsByPortView({
    isMobile,
    modalPLP,
    customImageSize
  })
  const modalHeight = () => {
    if (modalPLP && !isMobile) {
      return '550px'
    }
    return 'max-content'
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-background-white')}
      justifyContent='center'
      minHeight={modalHeight()}
    >
      <Container
        backgroundColor={getGlobalStyle('--color-background-white')}
        customWidth='90'
        gap='45px'
        justifyContent='center'
        maxWidth='1220px'
        minHeight={portViewSizes.containerMinHeight}
        padding={portViewSizes.containerPadding}
      >
        <Column
          alignItems='end'
          maxWidth='410px'
          padding={portViewSizes.columnPadding}
        >
          <Column alignItems='center'>
            <Picture
              height='140px'
              src={macaronsImg}
              width='180px'
            />
            <Spacer.Horizontal size={8} />
          </Column>
          <Row justifyContent='center'>
            <Text
              fontSize='xl'
              fontWeight='semibold'
              textAlign='center'
            >
              {isMobile
                ? formatTextWithLineBreaks(
                    'Sabemos lo mucho que\n deseas este producto'
                  )
                : formatTextWithLineBreaks(
                    'Sabemos lo mucho\n  que deseas este\n  producto'
                  )}
            </Text>
          </Row>
          <Spacer.Horizontal size={16} />
          <Row
            alignSelf='center'
            maxWidth='max-content'
          >
            <Text
              customColor={getGlobalStyle('--color-neutral-gray-dark')}
              fontSize='lg'
              textAlign='center'
            >
              Estamos haciendo lo posible para buscártelo.
            </Text>
          </Row>
          <Row
            alignSelf='center'
            maxWidth='max-content'
          >
            <Text
              customColor={getGlobalStyle('--color-neutral-gray-dark')}
              fontSize={isMobile ? 'lg' : 'md'}
              textAlign='center'
            >
              Recarga la página y continúa con tus compras.
            </Text>
          </Row>

          <Spacer.Horizontal size={8} />
          <Row
            justifyContent='center'
            maxWidth='100%'
            padding='0 12px'
          >
            <Text
              customColor={getGlobalStyle('--color-neutral-gray-input')}
              fontSize='md'
              textAlign='center'
            >
              Error: CRT-01
            </Text>
          </Row>

          <Spacer.Horizontal size={18} />
          <Row
            alignSelf='center'
            maxWidth='321px'
          >
            {!hideButton && (
              <Button
                background={getGlobalStyle('--color-primary-red-qty-button')}
                borderRadius={getGlobalStyle('--border-radius-lg')}
                label='Recargar'
                onClick={onReload}
                size='large'
                width={portViewSizes.buttonWidth}
              />
            )}
          </Row>
        </Column>
      </Container>
    </Container>
  )
}
