import {
  Collapsable,
  Column,
  Container,
  RichText,
  Spacer,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData, useSession } from '@smu-chile/pkg-unimarc-hooks'
import { ClubBlockProps } from './ClubBlock'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './ClubBlockFaq.module.css'

export type ClubBlockFaqProps = ClubBlockProps

export const ClubBlockFaq = ({ description, items }: ClubBlockProps) => {
  const { isLoggedIn } = useSession()

  const renderRichtext = (richtext: object[]) => {
    return (
      <>
        <BigScreen>
          <RichText richTextData={richtext} />
        </BigScreen>

        <SmallScreen>
          <Container
            customClassName={styles['club-block-faq--richtext-mobile']}
          >
            <RichText richTextData={richtext} />
          </Container>
        </SmallScreen>
      </>
    )
  }

  return (
    <Container>
      <BigScreen>
        <Column>
          {description && (
            <Column>
              <Spacer.Horizontal size={24} />

              {isLoggedIn ? (
                <Text
                  fontSize='2xl'
                  fontWeight='semibold'
                  id={getBemId('clubBlockFaq', 'title')}
                >
                  {description}
                </Text>
              ) : (
                <Title
                  fontWeight='medium'
                  headingLevel='h1'
                  id={getBemId('clubBlockFaq', 'title')}
                >
                  {description}
                </Title>
              )}

              <Spacer.Horizontal size={24} />
            </Column>
          )}

          {isValidArrayWithData(items) &&
            items.map((item) => {
              const richtext = renderRichtext([
                item?.['value']?.['content']
              ]) as unknown

              return (
                <Column key={item?.['sys']?.['id']}>
                  <Collapsable
                    backgroundColor='var(--color-neutral-gray-white)'
                    dynamicIcon
                    iconName='DropDownCloseV2'
                    text={richtext as HTMLElement}
                    title={item?.['name']}
                  />
                </Column>
              )
            })}
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column gap={16}>
          {description && (
            <Text
              fontSize='lg'
              fontWeight='medium'
            >
              {description}
            </Text>
          )}

          <Column>
            {isValidArrayWithData(items) &&
              items.map((item) => {
                const richtext = renderRichtext([
                  item?.['value']?.['content']
                ]) as unknown

                return (
                  <Column key={item?.['sys']?.['id']}>
                    <Collapsable
                      backgroundColor='var(--color-neutral-gray-white)'
                      bottomSpace={16}
                      dynamicIcon
                      iconName='DropDownCloseV2'
                      text={richtext as HTMLElement}
                      title={item?.['name']}
                      titleContainerHeight='44px'
                      titleFontWeight='regular'
                    />
                  </Column>
                )
              })}
          </Column>
        </Column>
      </SmallScreen>
    </Container>
  )
}
