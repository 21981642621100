import {
  Button,
  Column,
  Container,
  Divider,
  Icon,
  Modal,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  datalayerSimpleEvent,
  trigger,
  useLocalStorage
} from '@smu-chile/pkg-unimarc-hooks'
import styles from './WelcomeModal.module.css'
import { useEffect } from 'react'

export const SSOWelcomeModal = () => {
  const [showWelcome, setShowWelcome] = useLocalStorage(
    'SSO/showWelcome',
    'false'
  )

  const handleClose = () => {
    setShowWelcome('false')
    trigger({ eventType: 'deliverySelector', data: { show: true } })
  }

  const renderHeader = (title: string) => {
    return (
      <Column>
        <Column padding='.5rem 0'>
          <Row
            alignItems='center'
            gap={8}
            padding='.5rem 1rem'
          >
            <Container
              maxHeight='2.25rem'
              maxWidth='2.25rem'
              minHeight='2.25rem'
              minWidth='2.25rem'
              padding='.5rem'
            ></Container>

            <Container
              justifyContent='center'
              width='100%'
            >
              <Text
                fontSize='xl'
                fontWeight='semibold'
              >
                {title}
              </Text>
            </Container>

            <Container
              clickable='pointer'
              maxHeight='2.25rem'
              maxWidth='2.25rem'
              minHeight='2.25rem'
              minWidth='2.25rem'
              onClick={handleClose}
              padding='.5rem'
            >
              <Icon
                color={getGlobalStyle('--color-neutral-black')}
                customSize={20}
                name='CloseThin'
              />
            </Container>
          </Row>
        </Column>

        <Divider
          borderHeight='3xs'
          color={getGlobalStyle('--color-neutral-gray-divider')}
          verticalMargin={0}
        />
      </Column>
    )
  }

  const renderContent = () => {
    return (
      <>
        <Column
          alignItems='center'
          gap={16}
        >
          <Icon
            color='#24BA00'
            customSize={32}
            name='CheckCircle'
          />

          <Column
            alignItems='center'
            gap={16}
            maxWidth='343px'
          >
            <Text
              display='block'
              fontSize='md'
              textAlign='center'
            >
              <strong>
                Ya puedes disfrutar de tu primera <br /> compra en Unimarc.
              </strong>
            </Text>

            <Text
              display='block'
              fontSize='md'
              textAlign='center'
            >
              Además, ya eres parte del nuevo <strong>Club Unimarc</strong>,
              donde podrás descubrir nuevos beneficios y descuentos que tenemos
              para ti
            </Text>
          </Column>
        </Column>
        <Column>
          <Row
            justifyContent='center'
            padding='1rem'
          >
            <Button
              borderRadius='1.5rem'
              fontSize='1rem'
              fontWeight='500'
              height='40px'
              label='Ir a comprar'
              onClick={handleClose}
              width='343px'
            />
          </Row>
        </Column>
      </>
    )
  }

  useEffect(() => {
    if (showWelcome === 'true') {
      datalayerSimpleEvent({
        event: 'sign_up'
      })
    }
  }, [showWelcome])

  return (
    <>
      <BigScreen>
        <Modal
          hiddenX
          isOpen={showWelcome === 'true'}
          maxWidth='391px'
          padding='0'
          toggleOutside={handleClose}
        >
          <Column gap={16}>
            {renderHeader('¡Te registraste correctamente!')}

            {renderContent()}
          </Column>
        </Modal>
      </BigScreen>

      <SmallScreen>
        <Modal
          customContainerClassName={styles['welcome__modal']}
          draggButton={false}
          fullScreen
          hiddenX
          isOpen={showWelcome === 'true'}
          minHeightFullScreen='auto'
          padding='0'
          toggleOutside={handleClose}
        >
          <Column gap={12}>
            {renderHeader('Registro exitoso')}

            {renderContent()}
          </Column>
        </Modal>
      </SmallScreen>
    </>
  )
}
