import {
  Backbone,
  Column,
  Container,
  Horizontal,
  Vertical,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'

export const LiveShopingBackbone = () => {
  const shelvesCount =
    typeof window !== 'undefined' && Math.floor(window.innerWidth / 220)

  return (
    <Container>
      <Column alignItems='center'>
        <BigScreen>
          <>
            <Horizontal size={24} />
            <Row maxWidth='1136px'>
              <Backbone
                borderRadius='12px'
                height={388}
                width={768}
              />
              <Vertical size={160} />
              <Backbone
                borderRadius='12px'
                height={388}
                width={340}
              />
            </Row>
            <Horizontal size={24} />
            <Row
              justifyContent='between'
              maxWidth='1136px'
            >
              <Column>
                <Row maxWidth='174px'>
                  <Backbone
                    borderRadius='8px'
                    height={23}
                  />
                </Row>
                <Horizontal size={12} />
                <Row maxWidth='105px'>
                  <Backbone
                    borderRadius='8px'
                    height={19}
                  />
                </Row>
              </Column>
            </Row>
            <Horizontal size={24} />
            <Row
              justifyContent='between'
              maxWidth='1136px'
            >
              {[...Array(4)].map((_, i) => {
                return (
                  <Column key={i}>
                    <Row maxWidth='256px'>
                      <Backbone
                        borderRadius='8px'
                        height={142}
                      />
                    </Row>
                  </Column>
                )
              })}
              <Column maxWidth='40px'>
                <Row maxWidth='40px'>
                  <Backbone
                    borderRadius='8px'
                    height={142}
                  />
                </Row>
              </Column>
            </Row>
          </>
        </BigScreen>

        <SmallScreen>
          <Container tagName='main'>
            <Column alignItems='center'>
              <Horizontal size={16} />
              <Row
                justifyContent='center'
                maxWidth='92%'
              >
                <Backbone
                  borderRadius='10px'
                  height={370}
                />
              </Row>
              <Horizontal size={12} />
              <Row
                justifyContent='between'
                maxWidth='92%'
              >
                <Column>
                  <Row maxWidth='174px'>
                    <Backbone
                      borderRadius='8px'
                      height={23}
                    />
                  </Row>
                  <Horizontal size={12} />
                  <Row maxWidth='105px'>
                    <Backbone
                      borderRadius='8px'
                      height={19}
                    />
                  </Row>
                </Column>
              </Row>
              <Horizontal size={12} />
              <Row
                justifyContent='between'
                maxWidth='92%'
              >
                {[...Array(shelvesCount)].map((_, i) => {
                  return (
                    <Column key={i}>
                      <Row maxWidth='95%'>
                        <Backbone
                          borderRadius='8px'
                          height={142}
                        />
                      </Row>
                    </Column>
                  )
                })}
                <Column maxWidth='40px'>
                  <Row maxWidth='40px'>
                    <Backbone
                      borderRadius='8px'
                      height={142}
                    />
                  </Row>
                </Column>
              </Row>
              <Horizontal size={12} />
            </Column>
          </Container>
        </SmallScreen>
      </Column>
    </Container>
  )
}
