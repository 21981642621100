import { useEffect, useState } from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { datalayerSimpleEvent, useMobile } from '@smu-chile/pkg-unimarc-hooks'
export interface ErrorPageSearchProps {
  message?: string
  search: string
}

export const ErrorPageSearch = ({
  search,
  message = 'resultados para tu búsqueda'
}: ErrorPageSearchProps) => {
  const { isMobile } = useMobile()
  const [tagged, setTagged] = useState(false)
  const fontWeight = isMobile ? 'semibold' : 'bold'
  const fontSize = isMobile ? 'base' : '3xl'
  const lineHeight = isMobile ? '' : '41px'
  const fontSizeSuggestions = isMobile ? 'base' : 'xl'
  const constSpaceStart = isMobile ? 32 : 64

  useEffect(() => {
    if (!tagged) {
      datalayerSimpleEvent({
        event: 'evento-interactivo',
        eventCategory: 'Busqueda Vacia',
        eventLabel: search
      })
      setTagged(true)
    }
  }, [])

  return (
    <Column alignItems='center'>
      <Spacer.Horizontal size={constSpaceStart} />
      <Container
        backgroundColor={getGlobalStyle('--color-background-white')}
        customWidth='90'
        isWrap
        maxWidth={getGlobalStyle('--width-max-desktop')}
        minHeight='543px'
        padding='36px'
      >
        <Column alignItems='center'>
          <Spacer.Horizontal size={56} />
          <Row justifyContent='center'>
            <Icon
              customSize={134}
              name='ErrorSearch'
            />
          </Row>
          <Spacer.Horizontal size={16} />
          <Row maxWidth='580px'>
            <Column alignItems='center'>
              <Text
                fontSize={fontSize}
                fontWeight={fontWeight}
                isFlex={false}
                lineHeight={lineHeight}
                textAlign='center'
              >
                Lo sentimos, no encontramos
              </Text>
              <Text
                fontSize={fontSize}
                fontWeight={fontWeight}
                isFlex={false}
                lineHeight={lineHeight}
                textAlign='center'
              >
                {message} &nbsp;
                <Text
                  color='primary'
                  fontSize={fontSize}
                  fontWeight='bold'
                  isFlex={false}
                  type='span'
                >
                  {`“${search}”`}
                </Text>
              </Text>
            </Column>
          </Row>
          <Spacer.Horizontal size={24} />
          <Row
            isWrap
            justifyContent='center'
            maxWidth='280px'
          >
            <Text
              color='gray'
              fontSize={fontSizeSuggestions}
              fontWeight='regular'
              textAlign='left'
            >
              Te recomendamos:
            </Text>
            <Spacer.Horizontal size={2} />
            <Row justifyContent='center'>
              <Text
                color='gray'
                fontSize={fontSizeSuggestions}
                fontWeight='regular'
                type='span'
              >
                <ul>
                  <li> Revisar la ortografía </li>
                  <li> Buscar otra palabra </li>
                  <li> Simplificar la búsqueda. </li>
                </ul>
              </Text>
            </Row>
          </Row>
        </Column>
      </Container>
      <Spacer.Horizontal size={64} />
    </Column>
  )
}
