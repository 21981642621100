import { NextRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  handleOrderBy,
  orderByOptions,
  returnMatchedKeyName
} from 'shared/helpers'

export interface UseOrderData {
  onApplyFilterModal: () => void
  onClickFilter: (orderByName: string) => void
  onClickRadioOrderByOption: (radioValue: string) => void
  selectedRadio: string
  disabledButton?: boolean
}

export const useOrder = (
  router: NextRouter,
  setShowFiltersModal?: (value: boolean) => void
): UseOrderData => {
  const orderByRadioName = returnMatchedKeyName(
    router?.query?.orderBy?.toString()
  )

  const [disabledButton, setDisabledButton] = useState(false)

  const [selectedRadio, setSelectedRadio] = useState(
    orderByRadioName || 'Recomendados'
  )

  const onClickFilter = handleOrderBy({ router })
  const onApplyFilterModal = (): void => {
    if (typeof setShowFiltersModal === 'function') setShowFiltersModal(false)
    onClickFilter(selectedRadio)
  }

  const validCurrentSelectionWithRouterQuery = (radioValue: string) => {
    const orderByValueFromParams = router?.query?.orderBy as string
    const validRadioValueAgainsOrderOptions = orderByOptions.filter(
      (orderValue) => {
        return orderValue?.value === radioValue
      }
    )
    const isRadioValueCurrentlySelected =
      validRadioValueAgainsOrderOptions?.[0]?.['orderKey'] ===
      orderByValueFromParams

    const isRecomendedRadioValueSelected =
      radioValue === 'Recomendados' && !router?.query?.orderBy

    return isRadioValueCurrentlySelected || isRecomendedRadioValueSelected
  }

  const onClickRadioOrderByOption = (radioValue: string): void => {
    const isButtonDisabled = validCurrentSelectionWithRouterQuery(radioValue)
      ? true
      : false
    setSelectedRadio(radioValue)
    setDisabledButton(isButtonDisabled)
  }

  // whe mounting the component, check if the current selected radio is also in the router query
  // and if it is, set the disabled button to true
  useEffect(() => {
    if (validCurrentSelectionWithRouterQuery(selectedRadio)) {
      setDisabledButton(true)
    }
  }, [router?.query?.orderBy])

  return {
    onApplyFilterModal,
    onClickFilter,
    onClickRadioOrderByOption,
    selectedRadio,
    disabledButton
  }
}
