import {
  IEntriesProps,
  Item,
  searchContentfulItemsBy
} from '@smu-chile/pkg-unimarc-hooks'
import { ImageBanner } from 'shared/interfaces/ClubUnimarc'

export function searchContentfulItems<T = Item>(
  items: T[],
  field: string,
  value: string
) {
  return searchContentfulItemsBy(items, field, value) as T[]
}

export enum MembershipLabels {
  SocioClub = 'Socio Club',
  SocioOro = 'Socio Oro',
  SocioPlatino = 'Socio Platino',
  SocioDiamante = 'Socio Diamante'
}

export const MembershipLevels = {
  [MembershipLabels.SocioClub]: 'clubunimarc',
  [MembershipLabels.SocioOro]: 'oro',
  [MembershipLabels.SocioPlatino]: 'platino',
  [MembershipLabels.SocioDiamante]: 'diamante'
}

export const getMembershipLabel = (level?: string) => {
  const result = Object.entries(MembershipLevels).find((membershipLevel) => {
    return membershipLevel[1] === level
  })

  if (result) {
    return result[0]
  }

  return MembershipLabels.SocioClub
}

export const mapLevelPins = (entries: IEntriesProps) => {
  const [levelPinsBlock] = entries?.items ?? []
  const levelPinsBlockItems = levelPinsBlock?.fields?.['items'] ?? []

  const emptyPin = (label?: string) => {
    return {
      fields: {
        label: label,
        image: {
          fields: {
            file: {
              url: ''
            }
          }
        }
      }
    } as ImageBanner
  }

  const [clubPin] = searchContentfulItems<ImageBanner>(
    levelPinsBlockItems,
    'label',
    MembershipLabels.SocioClub
  )

  const [oroPin] = searchContentfulItems<ImageBanner>(
    levelPinsBlockItems,
    'label',
    MembershipLabels.SocioOro
  )

  const [platinoPin] = searchContentfulItems<ImageBanner>(
    levelPinsBlockItems,
    'label',
    MembershipLabels.SocioPlatino
  )

  const [diamantePin] = searchContentfulItems<ImageBanner>(
    levelPinsBlockItems,
    'label',
    MembershipLabels.SocioDiamante
  )

  return [
    clubPin || emptyPin(MembershipLabels.SocioClub),
    oroPin || emptyPin(MembershipLabels.SocioOro),
    platinoPin || emptyPin(MembershipLabels.SocioPlatino),
    diamantePin || emptyPin(MembershipLabels.SocioDiamante)
  ]
}
