import NextImage from 'next/image'
import { Row, Column } from '@smu-chile/pkg-unimarc-components'
import { formatURLfromVtex, useMobile } from '@smu-chile/pkg-unimarc-hooks'

export interface ImageProps {
  srcPicture: string
  altPicture: string
}

type ImageLoader = {
  src: string
}

const reBuildImageUrlFromVtex = (imageURL: string, size: number): string => {
  return formatURLfromVtex(imageURL, size, size)
}

const imageNextJsLoader = ({ src }: ImageLoader) => {
  return `${src}&q=75`
}

export const Image = ({
  altPicture,
  srcPicture
}: ImageProps): React.ReactElement<ImageProps> => {
  const { isMobile } = useMobile()
  const sizeByDevice = isMobile ? 140 : 250
  const reBuildedImgSrc: string = reBuildImageUrlFromVtex(
    srcPicture,
    sizeByDevice
  )
  return (
    <Column>
      <Row justifyContent='start'>
        <Column
          maxHeight='100px'
          maxWidth='100px'
        >
          <NextImage
            alt={altPicture}
            height={100}
            loader={imageNextJsLoader}
            objectFit='cover'
            src={reBuildedImgSrc}
            width={100}
          />
        </Column>
      </Row>
    </Column>
  )
}
