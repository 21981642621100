export const getErrorRetryAfterMessage = (retryAfter: number) => {
  let suffix = 'segundos'
  let time = retryAfter

  if (retryAfter > 60) {
    suffix = 'minutos'
    time = retryAfter / 60
  }

  return `${time.toFixed(0)} ${suffix}`
}
