import classNames from 'classnames'
import {
  Container,
  Coupon,
  CouponProps,
  CouponV2,
  Row
} from '@smu-chile/pkg-unimarc-components'
import styles from './CouponCarousel.module.css'
import { useRef } from 'react'
import {
  TaggableCoupon,
  getCouponName,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import times from 'lodash/times'

export interface CouponCarouselProps {
  coupons?: CouponProps[]
  isLoading?: boolean
  selectedFilter?: string
}

export const CouponCarousel = ({
  coupons = [],
  isLoading
}: CouponCarouselProps) => {
  const { isMobile } = useMobile()
  const couponsToActivate = useRef<TaggableCoupon<HTMLButtonElement>[]>([])

  const assignCouponRef = (
    ref: HTMLButtonElement | HTMLAnchorElement,
    coupon: CouponProps,
    index: number
  ) => {
    const taggableCoupon: TaggableCoupon = {
      ref,
      id: coupon.id,
      index: `${coupon.index + 1}`,
      name: getCouponName(coupon)
    }
    if (coupon.status === undefined && ref instanceof HTMLButtonElement) {
      couponsToActivate.current[index] =
        taggableCoupon as TaggableCoupon<HTMLButtonElement>
    }
  }

  return (
    <Row
      className={classNames(styles['coupon-carousel'])}
      gap={8}
      padding='0 16px'
    >
      {isLoading ? (
        <>
          {times(3, () => {
            return (
              <Container minWidth='340px'>
                <Coupon
                  description=''
                  isFetching
                  subtitle=''
                  title=''
                />
              </Container>
            )
          })}
        </>
      ) : (
        coupons.map((coupon, index) => {
          return (
            <Container
              key={coupon?.id}
              minWidth='343px'
            >
              <CouponV2
                activeButtonRef={(ref) => {
                  return assignCouponRef(ref, coupon, index)
                }}
                couponStatus={coupon.status}
                couponType={coupon.label}
                cutterHeight={isMobile ? 16 : 24}
                discount={coupon.discount}
                expiration={coupon.endDate}
                imageUrl={coupon.imageSrc}
                isLoading={coupon.loading}
                isMobile={isMobile}
                onClickButton={coupon.onClick}
                onClickCoupon={coupon.onClickViewProducts}
                product={coupon.description}
                subtitle={coupon.subtitle}
                tag={coupon.badgeText}
                title={coupon.title}
                unitsPerClient={coupon.maxUses.toString()}
                width='100%'
              />
            </Container>
          )
        })
      )}
    </Row>
  )
}
