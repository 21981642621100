import React from 'react'
import {
  Backbone,
  BackboneShelves,
  Container,
  Divider,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './MobileModalBackBone.module.css'

export const MobileModalBackBone = () => {
  const fromPDP = window.location.href.includes('product')
  return (
    <Container
      isWrap
      maxWidth='100%'
      padding={fromPDP ? '24px 0 0 10px' : '0 0 0 10px'}
    >
      <Row
        customClassName={styles.mobileModalBackBone}
        justifyContent='center'
        width='100%'
      >
        <Row maxWidth='170px'>
          <Backbone
            borderRadius='5px'
            height={30}
          />
        </Row>
      </Row>
      <Divider
        color={getGlobalStyle('--color-loaders-background')}
        verticalMargin={20}
      />
      <BackboneShelves
        shelvesForRow={2}
        shelvesQuantityMobile={4}
      />
    </Container>
  )
}
