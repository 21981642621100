import {
  FilterProps,
  FiltersPills,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'

export interface ListFiltersProps {
  data: FilterProps[]
  handleClick: (arg: object) => void
}

export const ListFilters = ({ data, handleClick }: ListFiltersProps) => {
  const handleDeleteOne = ({ itemName }) => {
    handleClick({ value: itemName, option: 'one' })
  }

  const handleDeleteAll = () => {
    handleClick({ option: 'all' })
  }

  return (
    <BigScreen>
      <Row padding='12px 0 20px 0'>
        <FiltersPills
          enterFilter={data}
          onClickItemRemove={handleDeleteOne}
          onRemoveAll={handleDeleteAll}
        />
      </Row>
    </BigScreen>
  )
}
