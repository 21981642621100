import { setBannerLocation } from './url'

export const bannerData = (data) => {
  const {
    creativeName,
    creativeSlot,
    locationId,
    promotionId,
    promotionName,
    reference,
    saleChannels,
    url,
    urlPath
  } = data

  const promoData = {
    creativeName: creativeName,
    creativeSlot: creativeSlot,
    locationId: setBannerLocation(locationId),
    promotionId: promotionId,
    promotionName: promotionName,
    saleChannels: saleChannels,
    urlPath: urlPath || reference || url
  }
  return promoData
}
