import {
  Button,
  Container,
  Horizontal,
  Icon,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { trigger } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'

export const EmptyMiniCart = (): React.ReactElement => {
  const router = useRouter()

  const redirectToHome = () => {
    trigger({ eventType: 'miniCart', data: { show: false } })
    router.push(process.env.NEXT_PUBLIC_HOMEURL)
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
      justifyContent='center'
      tagName='section'
    >
      <Container
        customWidth='90'
        isWrap
        justifyContent='center'
        maxWidth='1440px'
      >
        <Horizontal size={64} />

        <Horizontal size={16} />
        <Horizontal size={24} />
        <Icon
          customSize={170}
          name='EmptyCart'
        />
        <Horizontal size={4} />
        <Title
          fontWeight='semibold'
          headingLevel='h4'
          text='Tu carrito está vacío'
          textAlign='center'
        />
        <Horizontal size={4} />
        <Horizontal size={2} />
        <Container
          alignItems='center'
          justifyContent='center'
        >
          <Text
            fontSize='md'
            textAlign='center'
          >
            Te invitamos a descubrir todos los productos que tenemos para ti
          </Text>
        </Container>
        <Horizontal size={32} />

        <Button
          borderRadius={getGlobalStyle('--border-radius-xl')}
          fontWeight={getGlobalStyle('--font-weight-semibold')}
          label='Seguir comprando'
          onClick={redirectToHome}
          size='large'
          width='335px'
        />
        <Horizontal size={160} />
      </Container>
    </Container>
  )
}
