import React from 'react'
import {
  Backbone,
  BackboneShelves,
  Column,
  Container,
  Divider,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './ProductDetailModal.module.css'

export const MobileBackBone = () => {
  return (
    <>
      <Container
        isWrap
        maxWidth='100%'
        padding='0 0 0 10px'
      >
        <Row
          isWrap
          padding='10px 20px 10px 10px'
        >
          {/* image */}
          <Row justifyContent='center'>
            <Row maxWidth='250px'>
              <Backbone
                borderRadius='12px'
                height={250}
              />
            </Row>
          </Row>
        </Row>
        <Row
          gap='14px'
          overflow='hidden'
          padding='10px 0'
          width='100%'
        >
          <Row maxWidth='95px'>
            <Backbone
              borderRadius='5px'
              height={29}
            />
          </Row>
          <Row maxWidth='95px'>
            <Backbone
              borderRadius='5px'
              height={29}
            />
          </Row>
          <Row maxWidth='95px'>
            <Backbone
              borderRadius='5px'
              height={29}
            />
          </Row>
          <Row maxWidth='95px'>
            <Backbone
              borderRadius='5px'
              height={29}
            />
          </Row>
        </Row>
        <Divider
          color={getGlobalStyle('--color-loaders-background')}
          verticalMargin={20}
        />
        <Row>
          <Column>
            <Row
              gap='6px'
              isWrap
            >
              <Row width='100%'>
                <Row maxWidth='71px'>
                  <Backbone
                    borderRadius='5px'
                    height={20}
                  />
                </Row>
              </Row>
              <Row width='100%'>
                <Row maxWidth='245px'>
                  <Backbone
                    borderRadius='5px'
                    height={30}
                  />
                </Row>
              </Row>
              <Row width='100%'>
                <Row maxWidth='71px'>
                  <Backbone
                    borderRadius='5px'
                    height={15}
                  />
                </Row>
              </Row>
              <Row width='100%'>
                <Row maxWidth='171px'>
                  <Backbone
                    borderRadius='5px'
                    height={15}
                  />
                </Row>
              </Row>
              <Row width='100%'>
                <Row maxWidth='171px'>
                  <Backbone
                    borderRadius='5px'
                    height={30}
                  />
                </Row>
              </Row>
            </Row>
          </Column>
          <Column
            alignItems='end'
            gap='6px'
            isWrap
            padding='0 20px 0 0'
          >
            <Row maxWidth='30px'>
              <Backbone
                borderRadius='30px'
                height={30}
              />
            </Row>
            <Row maxWidth='30px'>
              <Backbone
                borderRadius='30px'
                height={30}
              />
            </Row>
          </Column>
        </Row>
        <Divider
          color={getGlobalStyle('--color-loaders-background')}
          verticalMargin={20}
        />
        <Row>
          <Column gap='12px'>
            <Row width='100%'>
              <Row maxWidth='171px'>
                <Backbone
                  borderRadius='5px'
                  height={30}
                />
              </Row>
            </Row>
            <Row width='100%'>
              <Row maxWidth='171px'>
                <Backbone
                  borderRadius='5px'
                  height={30}
                />
              </Row>
            </Row>
          </Column>
          <Column>
            <Row width='100%'>
              <Row
                maxWidth='171px'
                padding='42px 0 0 0'
              >
                <Backbone
                  borderRadius='5px'
                  height={30}
                />
              </Row>
            </Row>
          </Column>
        </Row>
        <Row
          gap='6px'
          padding='33px 0 0 0'
        >
          <Row maxWidth='30px'>
            <Backbone
              borderRadius='30px'
              height={30}
            />
          </Row>
          <Row maxWidth='171px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
        </Row>
        <Row
          gap='20px'
          padding='12px 0 0 0'
        >
          <Row maxWidth='49px'>
            <Backbone
              borderRadius='49px'
              height={49}
            />
          </Row>
          <Row maxWidth='49px'>
            <Backbone
              borderRadius='49px'
              height={49}
            />
          </Row>
          <Row maxWidth='49px'>
            <Backbone
              borderRadius='49px'
              height={49}
            />
          </Row>
        </Row>
        <Divider
          color={getGlobalStyle('--color-loaders-background')}
          verticalMargin={20}
        />
        <Row>
          <Row maxWidth='30px'>
            <Backbone
              borderRadius='30px'
              height={30}
            />
          </Row>
          <Row maxWidth='205px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
        </Row>
        <Row
          gap='6px'
          isWrap
          padding='25px 0 0 0'
        >
          <Row maxWidth='100%'>
            <Row maxWidth='375px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
          <Row maxWidth='100%'>
            <Row maxWidth='375px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
          <Row maxWidth='100%'>
            <Row maxWidth='375px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
        </Row>
        <Divider
          color={getGlobalStyle('--color-loaders-background')}
          verticalMargin={20}
        />
        <Row
          gap='6px'
          isWrap
          padding='25px 0 0 0'
        >
          <Row maxWidth='100%'>
            <Row maxWidth='375px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
          <Row maxWidth='100%'>
            <Row maxWidth='375px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
          <Row maxWidth='100%'>
            <Row maxWidth='375px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
        </Row>
        <Divider
          color={getGlobalStyle('--color-loaders-background')}
          verticalMargin={20}
        />
        <Row padding='0 0 20px 0'>
          <Row width='100%'>
            <Row maxWidth='171px'>
              <Backbone
                borderRadius='5px'
                height={30}
              />
            </Row>
          </Row>
        </Row>
        <BackboneShelves
          shelvesForRow={2}
          shelvesQuantityMobile={2}
        />
        <Row padding='24px 0 20px 0'>
          <Row width='100%'>
            <Row maxWidth='171px'>
              <Backbone
                borderRadius='5px'
                height={30}
              />
            </Row>
          </Row>
        </Row>
        <BackboneShelves
          shelvesForRow={2}
          shelvesQuantityMobile={2}
        />
      </Container>
      <Row
        alignItems='end'
        backgroundColor={getGlobalStyle('--color-base-white')}
        borderRadius='0px 0px 12px 12px'
        boxShadow='0px 0px 6px rgba(27, 33, 56, 0.15)'
        customClassName={styles.containerStickyButtons}
        gap={16}
      >
        <Row maxWidth='163px'>
          <Backbone
            borderRadius='43px'
            height={45}
          />
        </Row>
        <Row maxWidth='163pxpx'>
          <Backbone
            borderRadius='43px'
            height={45}
          />
        </Row>
      </Row>
    </>
  )
}
