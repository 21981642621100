import { datalayerSimpleEvent } from '@smu-chile/pkg-unimarc-hooks'
import { IAuthRegisterErrorData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces'

const defaultErrorMessage = null

const errorMessages = {
  SSO_36: 'Este teléfono ya tiene una cuenta asociada',
  SSO_37: 'Este teléfono ya tiene una cuenta asociada'
}

export const getPhoneErrorMessage = (error: IAuthRegisterErrorData) => {
  const code = error.error?.code

  const safeErrorMessage = errorMessages[`${code}`]

  safeErrorMessage &&
    datalayerSimpleEvent({
      event: 'register',
      eventAction: 'notification',
      eventCategory: 'validations',
      eventLabel: 'phone_in_use'
    })

  return safeErrorMessage ?? defaultErrorMessage
}
