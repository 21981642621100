import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import {
  Backbone,
  Breadcrumbs,
  Container,
  FiltersBarV2,
  Row,
  Spacer,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { clickPlp } from '../../shared/utils'
import Link from 'next/link'
import { GeneralObjectCategories } from './index'
import { useRouter } from 'next/router'
import useScrollListener from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/helpers/useScrollListener'
import { useCustomHeader } from './helpers/useCustomHeader'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './CustomHeader.module.css'

interface CustomHeaderProps {
  breadcrumbData: GeneralObjectCategories[]
  title: string
  totalQuantity: string | number
  isMobile: boolean
  isSuggestionsPage?: boolean
  selectedRadio: string
  hasFacets?: boolean
  isLoadingFacets?: boolean
  handleOpenFiltersModal?: Dispatch<SetStateAction<boolean>>
}

const TextBackBone = ({ width, height }: { width: string; height: number }) => {
  return (
    <Container width={width}>
      <Backbone
        borderRadius='20px'
        height={height}
      />
    </Container>
  )
}

export const CustomHeader = ({
  breadcrumbData,
  isMobile,
  isSuggestionsPage,
  selectedRadio,
  title,
  totalQuantity,
  hasFacets,
  isLoadingFacets,
  handleOpenFiltersModal
}: CustomHeaderProps) => {
  const router = useRouter()
  const primary = useRef(null)
  const scroll = useScrollListener()
  const fontSizeQuantity = isMobile ? 'sm' : 'base'
  const { innerWidth } = useMobile()
  const [showBackBone, setShowBackBone] = useState(false)
  const [firstRender, setFirstRender] = useState(0)
  const titleText = isSuggestionsPage ? 'Resultados para' : title
  const searchTerm = `“${title}”`
  const resultText = isSuggestionsPage
    ? `${totalQuantity} productos`
    : `(${totalQuantity} resultados)`

  useCustomHeader({ isMobile, innerWidth, scroll, primary })

  useEffect(() => {
    if (isLoadingFacets && firstRender === 0) {
      setFirstRender(1)
      setShowBackBone(true)
    }
    if (!isLoadingFacets && firstRender === 1) {
      setShowBackBone(false)
    }
  }, [isLoadingFacets, firstRender])

  if (isMobile && !router?.query?.brand) {
    return (
      <Row
        customClassName={styles.customHeader}
        ref={primary}
      >
        <FiltersBarV2
          filterQuantity={hasFacets}
          handleOpenFiltersModal={handleOpenFiltersModal}
          orderByName={selectedRadio}
          totalQuantity={totalQuantity}
        />
      </Row>
    )
  }

  return (
    <>
      <Row>
        {!isSuggestionsPage && (
          <>
            {!showBackBone ? (
              <Breadcrumbs
                afterClick={(label) => {
                  clickPlp(label, 'breadcrumb')
                }}
                linkWrapper={Link}
                links={breadcrumbData}
              />
            ) : (
              <TextBackBone
                height={10}
                width='200px'
              />
            )}
          </>
        )}
      </Row>
      <Spacer.Horizontal size={12} />
      <Row alignItems='baseline'>
        {!showBackBone ? (
          <Title
            fontWeight={isSuggestionsPage ? 'medium' : 'semibold'}
            headingLevel={isSuggestionsPage ? 'h3' : 'h1'}
            size='small'
          >
            {titleText}
          </Title>
        ) : (
          <TextBackBone
            height={20}
            width='100px'
          />
        )}
        {isSuggestionsPage && (
          <>
            <Spacer.Vertical size={4} />
            <Title
              color={getGlobalStyle('--color-primary-light-red')}
              fontWeight={isSuggestionsPage ? 'medium' : 'semibold'}
              headingLevel={isSuggestionsPage ? 'h3' : 'h1'}
              size='small'
            >
              {searchTerm}
            </Title>
          </>
        )}
        <Spacer.Vertical size={8} />
        {!showBackBone ? (
          <Text
            color='gray'
            fontSize={fontSizeQuantity}
          >
            {resultText}
          </Text>
        ) : (
          <TextBackBone
            height={10}
            width='60px'
          />
        )}
      </Row>
    </>
  )
}
