import { useRef } from 'react'
import {
  Container,
  Icon,
  Row,
  Text,
  Tooltip,
  TooltipProps
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useAfterOf } from 'components/SignUpPage/SignUpToast/helpers/useAfterOf'
import { useOutside } from 'components/SignUpPage/SignUpToast/helpers/useOutsideAlerter'

export type ConfirmCodeToastTooltipPropsPick = Pick<
  TooltipProps,
  'background' | 'onClick' | 'top'
>

export interface ConfirmCodeToastProps
  extends ConfirmCodeToastTooltipPropsPick {
  iconColor?: string
  message?: string
}

export const ConfirmCodeToast = ({
  background,
  iconColor,
  message,
  onClick,
  top
}: ConfirmCodeToastProps) => {
  const wrapperRef = useRef(null)

  useAfterOf({
    onFinish: onClick,
    ms: 5000
  })

  useOutside({
    onClick,
    ref: wrapperRef
  })

  return (
    <Container
      maxHeight='0'
      zIndex='1000'
    >
      <Tooltip
        alignAllItems='center'
        background={background}
        borderRadius={getGlobalStyle('--border-radius-2sm')}
        boxShadow='var(--box-shadow-3md)'
        closeColor='var(--color-icons-black)'
        closeIcon
        margin='.5rem 0 0'
        maxWidth='22rem'
        onClick={onClick}
        padding='.75rem'
        position='fixed'
        right='auto'
        textComponent={
          <Container
            padding='0 .75rem 0 0'
            ref={wrapperRef}
          >
            <Row
              alignItems='center'
              gap={8}
            >
              <Icon
                color={iconColor}
                name='InfoFill'
                sizes='md'
              />

              <Text
                color='black'
                fontSize='md'
              >
                {message}
              </Text>
            </Row>
          </Container>
        }
        top={top}
        type='top'
        width='22rem'
      />
    </Container>
  )
}
