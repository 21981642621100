import {
  Footer as FooterComponent,
  PromiseBrandData,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  FooterLoader,
  BottomFooterData,
  MiddleFooterData
} from '@smu-chile/pkg-unimarc-components/stories/organisms/Footer'
import {
  getContentFulDataContent,
  getEntriesByContentType,
  useContentful
} from '@smu-chile/pkg-unimarc-hooks'
import { TRANSBANK_FOOTER_IMG } from 'shared/utils/constanst'

export interface FooterProps {
  isVisible?: boolean
}

export const Footer = ({ isVisible }: FooterProps): React.ReactElement => {
  const { data, isLoading } = useContentful({
    id_contentful: 'footer',
    options: {
      'sys.contentType.sys.id[in]':
        'unimarcWebTopFooter,unimarcWebMiddleFooter,unimarcWebBottomFooter',
      'metadata.tags.sys.id[in]': 'siteUnimarc'
    }
  })

  const transbankIcon = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-transbank-img',
    options: {
      'sys.id': TRANSBANK_FOOTER_IMG[0]
    },
    type: 'assets'
  })
  const transbankImg =
    transbankIcon.data?.['items']?.[0]?.fields?.file?.url || ''
  const contentFullData = data?.['items'] ? getContentFulDataContent(data) : []

  const handleDataTopFooter = (): PromiseBrandData[] => {
    return (
      (getEntriesByContentType({
        data: contentFullData,
        contentType: 'topFooterItem',
        platForm: 'Web Unimarc eComm'
      })?.[0]?.entries as PromiseBrandData[]) || []
    )
  }

  const handleDataMiddleFooter = (): MiddleFooterData[] => {
    return (
      (getEntriesByContentType({
        data: contentFullData,
        contentType: 'middleFooterItem',
        platForm: 'Web Unimarc eComm'
      }) as MiddleFooterData[]) || []
    )
  }

  const handleDataBottomFooter = (): BottomFooterData[] => {
    return (
      (getEntriesByContentType({
        data: contentFullData,
        contentType: 'bottomFooterItem',
        platForm: 'Web Unimarc eComm'
      }) as BottomFooterData[]) || []
    )
  }

  if (
    isLoading ||
    handleDataBottomFooter().length == 0 ||
    handleDataMiddleFooter().length == 0 ||
    handleDataTopFooter().length == 0
  ) {
    return <FooterLoader />
  }

  return (
    <>
      {isVisible && (
        <>
          <BigScreen>
            <Spacer.Horizontal
              backgroundColor={getGlobalStyle('--color-base-white')}
              className='footer__bottom-spacer'
              size={96}
            />
          </BigScreen>
          <SmallScreen>
            <Spacer.Horizontal
              backgroundColor={getGlobalStyle('--color-base-white')}
              className='footer__bottom-spacer'
              size={32}
            />
          </SmallScreen>
          <FooterComponent
            dataBottomFooter={handleDataBottomFooter()}
            dataLinks={handleDataMiddleFooter()}
            dataTopFooter={handleDataTopFooter()}
            transbankImg={transbankImg}
          />
        </>
      )}
    </>
  )
}
