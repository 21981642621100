import {
  Column,
  Container,
  Icon,
  Row,
  Tooltip,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { toCapitalize } from '@smu-chile/pkg-unimarc-components/utils'
import { useEffect } from 'react'

export interface ITooltipAddOrderToList {
  createListButton: boolean
  isMobile: boolean
  selectedListName: string
  tooltipPrefixText?: string
  onClose: () => void
  setNotificationAddToList: (type: boolean) => void
}

export const TooltipAddOrderToList = ({
  createListButton,
  isMobile,
  selectedListName,
  tooltipPrefixText = 'La lista',
  onClose,
  setNotificationAddToList
}: ITooltipAddOrderToList) => {
  const newList = createListButton ? 'se guardó ' : 'se actualizó'
  const tooltipPrefixTextFinal = createListButton
    ? tooltipPrefixText
    : 'Tu lista'
  useEffect(() => {
    setTimeout(() => {
      setNotificationAddToList(false)
    }, 6000)
  }, [])

  return (
    <Tooltip
      alignAllItems='center'
      background={getGlobalStyle('--color-feedback-success-bg')}
      borderRadius={getGlobalStyle('--border-radius-2sm')}
      boxShadow={getGlobalStyle('--box-shadow-3md')}
      closeColor={getGlobalStyle('--color-icons-black')}
      customJustifyContent='end'
      display='flex'
      height='56px'
      iconMargin='0 4px 0 0'
      left={isMobile ? '0' : ''}
      margin={isMobile ? '0 auto' : ''}
      maxWidth='343px'
      onClick={onClose}
      padding='0 8px'
      position='absolute'
      positionContainer='sticky'
      right='0'
      textComponent={
        <Container>
          <Row
            alignItems='center'
            gap='8px'
          >
            <Column maxWidth='max-content'>
              <Icon
                color={getGlobalStyle('--color-feedback-success-light')}
                customSize={20}
                name='CheckCircle'
              />
            </Column>
            <Column margin='0 8px 0 0'>
              <Text
                color='black'
                display='inline-block'
                fontSize='md'
                fontWeight='medium'
              >
                {tooltipPrefixTextFinal}
                <Text
                  color='black'
                  display='inline-block'
                  fontSize='md'
                  fontWeight='medium'
                  type='span'
                >
                  &nbsp;&quot;
                  <Text
                    color='black'
                    display='inline-block'
                    fontSize='md'
                    fontWeight='semibold'
                    truncate={11}
                    type='span'
                  >
                    {toCapitalize(`${selectedListName}`)}
                  </Text>
                  &quot;&nbsp;{newList}
                </Text>
              </Text>
            </Column>
            <Column
              clickable='pointer'
              maxWidth='35px'
              onClick={() => {
                return setNotificationAddToList(false)
              }}
              padding='10px'
            >
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-neutral-gray-dark')}
                customSize={14}
                name='Close'
              />
            </Column>
          </Row>
        </Container>
      }
      type='bottom'
      width='343px'
    />
  )
}
