import NextLink from 'next/link'
import { useEffect, useState } from 'react'
import {
  Icon,
  Link,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { SmallScreen } from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import { LinkComponentV2 } from './LinkComponentV2'
import { FacetsFilterV2 } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { useSession, useSessionState } from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useRouter } from 'next/router'
import { capitalize, getLastPartUrl } from 'shared/helpers'
import styles from './SubcategoriesV2.module.css'

export interface SubcategoriesPropsV2 {
  data: FacetsFilterV2
  onClick?: () => void
}

export const SubcategoriesV2 = (data: SubcategoriesPropsV2) => {
  const { isLoggedIn } = useSession()
  const router = useRouter()
  const [selectedCategory, setSelectedCategory] = useSessionState(
    'selectedCategory',
    data?.data?.category1?.[0]?.name || ''
  )
  const [linkToRef, setLinkToRef] = useState(
    `/category/${data?.data?.category1?.[0]?.value}`
  )

  const urlEndsWith = getLastPartUrl(router.asPath)

  const categoryLevel1 = selectedCategory === data?.data?.category1?.[0]?.name

  const categoryLevel2 = () => {
    const result = data?.data?.category2?.find((value) => {
      return value?.name === selectedCategory
    })
    if (result) return true
    return false
  }

  const categoryLevel3 = () => {
    const result = data?.data?.category3?.find((value) => {
      return value?.value === urlEndsWith
    })
    if (result) return true
    return false
  }

  const handleClearSubCategories = () => {
    setSelectedCategory(data?.data?.category1?.[0]?.name)
  }

  const primaryCategory = categoryLevel1 ? 'Todos' : selectedCategory
  const longPrimaryCategory = primaryCategory?.length >= 35
  const secondLine = primaryCategory?.length >= 17

  useEffect(() => {
    sessionStorage.removeItem('selectedCategory')
    if (router.asPath.endsWith(data?.data?.category1?.[0]?.value)) {
      handleClearSubCategories()
    }
    //validate selected category when user paste url
    const categoryLevel2 = getLastPartUrl(router.asPath, 3)
    const notLoggedParam = '?login=true'
    const urlNotLoggedParam = categoryLevel2?.includes(notLoggedParam)
    const categoryNotLoggedParam = categoryLevel2?.slice(
      0,
      categoryLevel2?.lastIndexOf(notLoggedParam)
    )
    const categoryValue = urlNotLoggedParam
      ? categoryNotLoggedParam
      : categoryLevel2

    if (categoryLevel2 !== undefined) {
      setSelectedCategory(
        data?.data.category2?.filter((item) => {
          if (item.value !== categoryValue) {
            return undefined
          }
          return item
        })?.[0]?.name
      )
    } else {
      handleClearSubCategories()
    }
  }, [data?.data?.category1?.[0]?.name, isLoggedIn])

  useEffect(() => {
    if (router?.asPath?.includes('/ofertas/')) {
      const segments = router?.asPath.split('/')
      setLinkToRef(`/${segments[1]}/${segments[2]}`)
    } else {
      setLinkToRef(`/category/${data?.data?.category1?.[0]?.value}`)
    }
  }, [data?.data?.category1?.[0]?.value])

  return (
    <>
      <SmallScreen>
        <>
          <Spacer.Horizontal size={16} />
          <Row
            maxHeight='36px'
            padding='0px 16px'
          >
            {!categoryLevel1 && (
              <Row
                alignItems='center'
                alignSelf='center'
                justifyContent='center'
                margin='0 4px'
                maxWidth='fit-content'
                minWidth={'fit-content'}
                onClick={handleClearSubCategories}
              >
                <Link
                  customClassName={styles.swiper_closeIcon}
                  href={linkToRef}
                  preventDefault={false}
                  underline='none'
                  wrapper={NextLink}
                >
                  <Icon
                    clickable='pointer'
                    color={getGlobalStyle('--color-base-black')}
                    name='CloseCircle'
                    sizes='2xs'
                  />
                </Link>
              </Row>
            )}
            <Row
              className={styles.swiper_primary_selection}
              justifyContent='center'
              maxHeight={secondLine ? '35px' : ''}
              maxWidth={secondLine ? '120px' : 'fit-content'}
              minHeight={'36px'}
              padding='5px 8px'
            >
              <Text
                fontSize='sm'
                fontWeight='semibold'
                lineClamp={2}
                lineHeight={secondLine ? '14px' : ''}
                textAlign='center'
                truncate={longPrimaryCategory ? 34 : null}
              >
                {capitalize(primaryCategory)}
              </Text>
            </Row>
            {categoryLevel1 && (
              <Row className={styles.swiper_container}>
                {data?.data?.category2?.map((item) => {
                  return (
                    <Row
                      customClassName={styles.swiper_wrapper}
                      key={item?.id}
                      minWidth='fit-content'
                    >
                      <LinkComponentV2
                        categoryLevel2={categoryLevel2()}
                        categoryLevel3={categoryLevel3()}
                        label={item?.name}
                        setSelectedCategory={setSelectedCategory}
                        url={router.asPath}
                        urlEndsWith={urlEndsWith}
                        value={item?.value}
                        {...item}
                      />
                    </Row>
                  )
                })}
              </Row>
            )}
            {!categoryLevel1 && (
              <Row className={styles.swiper_container2}>
                {data?.data?.category3?.map((category) => {
                  const stylesSelectedCategory =
                    urlEndsWith === category?.value && categoryLevel3()
                      ? styles.swiper_selectedCategory
                      : styles.swiper_wrapper
                  return (
                    <Row
                      customClassName={stylesSelectedCategory}
                      key={category?.id}
                      minWidth='fit-content'
                    >
                      <LinkComponentV2
                        categoryLevel2={categoryLevel2()}
                        categoryLevel3={categoryLevel3()}
                        label={category?.name}
                        setSelectedCategory={setSelectedCategory}
                        url={router.asPath}
                        urlEndsWith={urlEndsWith}
                        value={category?.value}
                        {...category}
                      />
                    </Row>
                  )
                })}
              </Row>
            )}
          </Row>
          <Spacer.Horizontal size={16} />
        </>
      </SmallScreen>
    </>
  )
}
