import { NextRouter } from 'next/router'

/**
 * Cleans a specific query parameter from the URL and toggles a state.
 *
 * @param {NextRouter} router - The Next.js router instance.
 * @param {() => void} handleToggle - The function to toggle a state after cleaning the query parameter.
 * @param {string} [param='membresia'] - The query parameter to be cleaned from the URL. Defaults to 'membresia'.
 *
 * @example
 * ```typescript
 * import { useRouter } from 'next/router';
 *
 * const router = useRouter();
 *
 * const handleToggle = () => {
 *   console.log('Toggled state');
 * };
 *
 * cleanMembershipQueryParam(router, handleToggle);
 * ```
 */
export const cleanMembershipQueryParam = (
  router: NextRouter,
  handleToggle: () => void,
  param = 'membresia'
) => {
  const { query } = router
  if (query[param]) {
    const restQuery: { [key: string]: string | string[] } = {}
    for (const key in query) {
      if (key !== param) {
        restQuery[key] = query[key]
      }
    }
    router.replace(
      {
        pathname: router.pathname,
        query: restQuery
      },
      undefined,
      { shallow: true }
    )
    handleToggle()
  }
}
