import { useEffect } from 'react'

export interface UseAfterOfProps {
  ms: number
  onFinish: () => void
}

export function useAfterOf({ ms, onFinish }: UseAfterOfProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinish()
    }, ms)
    return () => {
      return clearTimeout(timer)
    }
  }, [])
}
