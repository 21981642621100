import {
  Column,
  Container,
  DesktopModalWrapper,
  MembershipHeader,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { Image } from '@smu-chile/pkg-unimarc-components/stories/organisms/AddToListModal/Image'
import { CarouselBanners } from '@smu-chile/pkg-unimarc-components/stories/organisms/Banners/CarouselBanners'
import { currencyFormat, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  setMembershipButtons,
  setMembershipData,
  setNextMembershipCycle
} from 'shared/helpers/membershipHeader'
import {
  IMembershipDataCF,
  IUserClubUnimarc
} from 'shared/interfaces/IMembership'

interface MembershipHeaderBrowseProps {
  membershipDataCF?: IMembershipDataCF
  userData?: IUserClubUnimarc
  goalType?: string
  levelGoal?: string
  marginTop?: string
  shoppingGoal?: string
  totalSavings?: number
}

export const MembershipHeaderBrowse = ({
  goalType,
  levelGoal,
  marginTop,
  membershipDataCF,
  shoppingGoal,
  totalSavings,
  userData
}: MembershipHeaderBrowseProps) => {
  const router = useRouter()
  const { isMobile } = useMobile()
  const [isOpen, setIsOpen] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [membershipUserData, setMembershipUserData] = useState(null)
  const modalHeight = modalType === 'ahorro' ? 'none' : '442px'
  const mobileModalHeight = modalType === 'ahorro' ? '396px' : '532px'

  const accountNumber = currencyFormat({
    number: totalSavings ?? 0,
    bool: false
  })

  const banners = membershipDataCF?.imageLevels?.map((banner) => {
    return { img: `https:${banner?.file?.url}` }
  })

  const handleOpenModal = (buttonLabel: string, url: string) => {
    if (buttonLabel?.includes('ahorr')) {
      setModalType('ahorro')
      setIsOpen(true)
    } else if (buttonLabel?.includes('beneficio')) {
      setModalType('beneficio')
      setIsOpen(true)
    } else if (url) {
      router.push(`${router.route}${url}`)
    } else {
      setIsOpen(false)
    }
  }

  const handleClickModalButton = () => {
    if (modalType === 'ahorro' && userData?.level !== 'clubunimarc') {
      router.push('/club-unimarc/beneficios')
    } else {
      setIsOpen(false)
    }
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setMembershipUserData(setMembershipData(membershipDataCF?.membershipLevels))
  }, [membershipDataCF, userData])

  return (
    <>
      <MembershipHeader
        bodyHeight={isMobile ? '327px' : '254px'}
        buttons={setMembershipButtons(
          membershipDataCF?.headerButtons?.items,
          accountNumber,
          handleOpenModal
        )}
        buttonsAligment='center'
        endDate={setNextMembershipCycle()}
        fontColor={membershipUserData?.fontColor}
        goalImage={membershipUserData?.goalImage}
        goalType={goalType}
        isMobile={isMobile}
        levelGoal={levelGoal}
        limitType={isMobile ? 'inner' : 'outer'}
        mainBackgroundColor={membershipUserData?.backgroundColor}
        mainContentAlign='center'
        marginTop={marginTop}
        progressBar={membershipUserData?.progressBar}
        secondaryBackgroundColor={membershipUserData?.secondaryBackgroundColor}
        shoppingGoal={shoppingGoal}
        type={isMobile ? 'full' : 'half'}
        userIcon={membershipUserData?.img}
        userLevel={membershipUserData?.userLevel}
        userName={userData?.firstName}
      />
      <DesktopModalWrapper
        blockId={getBemId('membership', 'modal')}
        bodyChildrenProps={{
          minHeight: isMobile ? mobileModalHeight : modalHeight,
          maxHeight: '500px'
        }}
        borderEdge={modalType === 'ahorro' ? 'none' : 'bottom'}
        footerChildrenProps={{
          default: true,
          buttonDefaultProps: {
            label:
              userData?.level === 'clubunimarc' ? 'Entendido' : 'Ver panoramas'
          },
          onClick: handleClickModalButton
        }}
        hasfooter={modalType === 'ahorro' ? true : false}
        headerCloseIcon={{
          default: true,
          name: 'CloseThin',
          sizes: '2xs'
        }}
        headerTitle={modalType === 'ahorro' ? null : 'Mis beneficios'}
        modalConfigsProps={{
          isOpen,
          toggle: handleCloseModal,
          toggleOutside: handleCloseModal,
          minHeight: '500px',
          fullScreen: isMobile,
          minHeightFullScreen: modalType === 'ahorro' ? '500px' : '590px',
          marginFullScreen:
            modalType === 'ahorro'
              ? 'calc(100vh - 520px) 0 0'
              : 'calc(100vh - 590px) 0 0'
        }}
        styleProps={{
          height: isMobile ? '520px' : '500px'
        }}
      >
        {modalType === 'ahorro' && (
          <Column
            alignItems='center'
            gap='18px'
            margin='-20px 0 0'
          >
            <Text
              fontSize='xl'
              fontWeight='medium'
              id={getBemId('modal', 'title', 'ahorro')}
            >
              ¡Felicidades!
            </Text>
            <Container id={getBemId('modal', 'image', 'ahorro')}>
              <Image
                altPicture='Club Unimarc image'
                mobileCustomSize='246px'
                srcPicture={membershipDataCF?.imageSaving}
              />
            </Container>
            <Text
              id={getBemId('modal', 'text', 'ahorro')}
              textAlign='center'
            >
              Haz ahorrado {accountNumber} en tus compras realizadas en las
              tiendas de Unimarc, app y web.
            </Text>
          </Column>
        )}
        {modalType === 'beneficio' && (
          <Container>
            <CarouselBanners
              banners={[...banners]}
              infinite={false}
              itemsToShow={1}
              minHeight={isMobile ? '600px' : '500px'}
              minWidthMobile='100%'
              showDots={false}
            />
          </Container>
        )}
      </DesktopModalWrapper>
    </>
  )
}
