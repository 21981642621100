import React from 'react'
import {
  Column,
  Container,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { ErrorReloadPageButton } from './ErrorReloadPageButton'
import { ErrorImage } from './ErrorImage'
import { ErrorGoToHomeButton } from './ErrorGoToHomeButton'
import { formatTextWithLineBreaks } from '@smu-chile/pkg-unimarc-components/shared/helpers'

export type ErrorType = '400' | '404' | '500'

interface ErrorMessage {
  title: string
  subTitle: string
}

interface ErrorData {
  mobileBody?: string
  mobilePadding?: string
  errorTitle: string | JSX.Element
  errorSubTitle: string | JSX.Element
}

export interface ErrorPageV2Props {
  bodyBoxShadow?: string
  errorType?: ErrorType
  titleMaxWidth?: string
  imgUrl?: string
}

export const ErrorPageV2 = ({
  bodyBoxShadow = '',
  errorType = '500',
  titleMaxWidth,
  imgUrl = ''
}: ErrorPageV2Props): React.ReactElement => {
  // custom hooks
  // get viewport width
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  // define error messages in diccionary
  const errorMessages: Record<ErrorType, ErrorMessage> = {
    '400': {
      title: 'No eres tú, somos nosotros...',
      subTitle:
        'Estamos haciendo lo posible para buscar tu producto, recarga la página y los mostraremos altiro.'
    },
    '404': {
      title: '¡EsPERA!',
      subTitle:
        'Estamos buscando tus productos, recarga la página y los mostraremos altiro.'
    },
    '500': {
      title: `¡Ups!`,
      subTitle: isMobile
        ? 'Estamos buscando tus productos\n nuevamente, espera unos segundos y\n recarga la página.'
        : 'Estamos buscando tus productos nuevamente, espera\n unos segundos y recarga la página.'
    }
  }
  // create error object to reuseability
  const errorData = (errorType: ErrorType, isMobile: boolean): ErrorData => {
    const mobileStyles = isMobile
      ? { mobileBody: '73dvh', mobilePadding: '12px 16px' }
      : {}
    const { title, subTitle } = errorMessages[errorType] || errorMessages['500']

    return {
      ...mobileStyles,
      errorTitle: title,
      errorSubTitle: subTitle
    }
  }
  // define variables to DRY
  const { mobileBody, mobilePadding, errorTitle, errorSubTitle } =
    errorData(errorType, isMobile) || {}
  const bodyHeight = mobileBody
  const bodyPadding = mobilePadding
  const errorSubtitle = errorSubTitle

  return (
    <Column
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-base-white')}
      borderRadius={getGlobalStyle('--border-radius-md')}
      boxShadow={bodyBoxShadow}
      height={bodyHeight}
      justifyContent='between'
      maxWidth={titleMaxWidth}
      minHeight={bodyHeight}
      padding={bodyPadding}
    >
      <Column alignItems='center'>
        {/* desktop spacers */}
        {!isMobile ? (
          <>
            <Spacer.Horizontal size={48} />
            <Spacer.Horizontal size={48} />
          </>
        ) : (
          <>
            <Spacer.Horizontal size={20} />
            <Spacer.Horizontal size={20} />
          </>
        )}
        {/* desktop/mobile image */}
        <ErrorImage
          errorType={errorType}
          imageUrl={imgUrl}
          imgHeight='230px'
          imgWidth='230px'
          isMobile={isMobile}
        />
        <Spacer.Horizontal size={24} />
        <Text
          fontSize='2xl'
          fontWeight='semibold'
          textAlign='center'
        >
          {(errorTitle as string).split('<br />').map((line, index) => {
            return (
              <React.Fragment key={index}>
                {line}
                {index < (errorTitle as string).split('<br />').length - 1 && (
                  <br />
                )}
              </React.Fragment>
            )
          })}
        </Text>

        <Spacer.Horizontal size={24} />
        <Container
          justifyContent='center'
          maxWidth='100%'
          padding='0 12px'
        >
          <Text
            fontSize='md'
            textAlign='center'
          >
            {typeof errorSubtitle === 'string'
              ? formatTextWithLineBreaks(errorSubtitle)
              : errorSubtitle}
          </Text>
        </Container>
        <Spacer.Horizontal size={24} />
        <Container
          justifyContent='center'
          maxWidth='100%'
          padding='0 12px'
        >
          <Text
            customColor={getGlobalStyle('--color-neutral-gray-input')}
            fontSize='xs'
            textAlign='center'
          >
            {errorType === '500' ? 'Error: CRT-01' : `Código: ${errorType}`}
          </Text>
        </Container>
        <Spacer.Horizontal size={20} />
        <Spacer.Horizontal size={20} />
      </Column>
      <Container>
        <Column alignItems='center'>
          <ErrorReloadPageButton errorType={errorType} />
          {errorType === '500' && (
            <>
              <Spacer.Horizontal size={16} />
              <ErrorGoToHomeButton />
            </>
          )}
        </Column>
      </Container>
      {!isMobile && (
        <>
          <Spacer.Horizontal size={32} />
          <Spacer.Horizontal size={12} />
          <Spacer.Horizontal size={1} />
        </>
      )}
    </Column>
  )
}
