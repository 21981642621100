import {
  Column,
  Container,
  Spacer,
  Tabs,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { ClubContent } from '../../ClubContent'
import { ClubBlockMobileContainer } from '../ClubBlockMobileContainer/ClubBlockMobileContainer'

export type ClubBlockBannersTabsProps = {
  items?: unknown[]
  title?: string
}

export const ClubBlockBannersTabs = ({
  items = [],
  title
}: ClubBlockBannersTabsProps) => {
  const tabs = () => {
    if (isValidArrayWithData(items)) {
      return items.map((item, index) => {
        return (
          <Container key={index}>
            <ClubContent
              isBanner
              items={[item]}
            />
          </Container>
        )
      })
    }

    return []
  }

  const titles = () => {
    if (isValidArrayWithData(items)) {
      return items.map((item) => {
        return item?.['title']
      })
    }

    return []
  }

  return (
    <Container>
      <Column>
        <Container justifyContent='center'>
          <BigScreen>
            <Text
              fontSize='3xl'
              fontWeight='medium'
              id={getBemId('ClubBlockBannersTabs', 'title')}
            >
              {title}
            </Text>
          </BigScreen>

          <SmallScreen>
            <Text
              fontSize='lg'
              fontWeight='medium'
              id={getBemId('ClubBlockBannersTabs', 'title')}
            >
              {title}
            </Text>
          </SmallScreen>
        </Container>

        <BigScreen>
          <Spacer.Horizontal customSize={40} />
        </BigScreen>

        <SmallScreen>
          <Spacer.Horizontal customSize={24} />
        </SmallScreen>

        <BigScreen>
          <Container minHeight='290px'>
            <Tabs
              containerSpacing={40}
              showTitlesDivider={false}
              tabs={tabs()}
              titles={titles()}
              titlesAccentFontWeight='medium'
              titlesFontSize='xl'
              titlesFontWeight='regular'
              titlesJustify='center'
            />
          </Container>
        </BigScreen>

        <SmallScreen>
          <ClubBlockMobileContainer
            minHeight='180px'
            spacing={16}
          >
            <Tabs
              containerSpacing={24}
              showTitlesDivider={false}
              tabs={tabs()}
              titles={titles()}
              titlesFontSize='md'
              titlesFontWeight='regular'
              titlesJustify='start'
              titlesPadding='0 16px'
            />
          </ClubBlockMobileContainer>
        </SmallScreen>
      </Column>
    </Container>
  )
}
