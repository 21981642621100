import {
  Banners,
  BannersLayoutEnum,
  Column
} from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { ClubFeatureProps } from '../ClubFeature'
import { ImageBanner } from 'shared/interfaces/ClubUnimarc'
import styles from './ClubFeatureBanners.module.css'

const MAX_ITEMS_TO_SHOW = 4

export type ClubFeatureBannersProps = ClubFeatureProps & {
  maxItemsToShow?: number
}

export const ClubFeatureBanners = ({
  imageBanner,
  layout,
  maxItemsToShow = MAX_ITEMS_TO_SHOW
}: ClubFeatureBannersProps) => {
  const itemsLength = isValidArrayWithData(imageBanner) ? imageBanner.length : 0
  const itemsToShow = Math.min(maxItemsToShow, itemsLength)
  const itemsIsOver = itemsToShow < itemsLength

  const banners = () => {
    if (isValidArrayWithData(imageBanner)) {
      return imageBanner.map((item) => {
        const { image, referenceWeb } = item as Partial<ImageBanner>

        return {
          img: image?.fields?.file?.url,
          url: referenceWeb
        }
      })
    }

    return []
  }

  return (
    <Column
      customClassName={styles['clubFeatureBanners__container']}
      id={getBemId('clubFeatureBanners', 'container')}
    >
      <Banners
        bannersCarousel={banners()}
        bannersGrid={banners()}
        containerMobileProps={{
          padding: '0 0 0 16px'
        }}
        imageBanner={imageBanner}
        infinite={itemsIsOver}
        itemsToShow={itemsToShow}
        layout={layout as BannersLayoutEnum}
        marginArrows='4px'
        showCarousel
        showDots={false}
      />
    </Column>
  )
}
