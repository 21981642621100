import React from 'react'
import {
  Row,
  Column,
  Title,
  Divider,
  Text,
  Icon,
  Spacer,
  Container
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { degreesCleaner } from '../../../shared/helpers'
import {
  Ingredients,
  NutritionalTable
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { NutritionalFactTable } from '@smu-chile/pkg-unimarc-components/stories/organisms/NutritionalFactTable/NutritionalFactTable'
import { ingredientList } from '../helpers'

export interface DescriptionProps {
  description: string
  degrees?: string
  volume?: string
  container?: string
  ingredients?: Array<Ingredients>
  nutritionalTablesSets?: NutritionalTable
}

export const ProductDescription = ({
  description,
  degrees,
  ingredients,
  nutritionalTablesSets,
  volume,
  container
}: DescriptionProps): React.ReactElement => {
  const degreesCleaned = degreesCleaner(degrees)
  const nutritionalFactProps = {
    name: nutritionalTablesSets?.name,
    basicUnit: nutritionalTablesSets?.basicUnit,
    portionText: nutritionalTablesSets?.portionText,
    portionValue: nutritionalTablesSets?.portionValue,
    portionUnit: nutritionalTablesSets?.portionUnit,
    numPortions: nutritionalTablesSets?.numPortions,
    nutritionalInfo: nutritionalTablesSets?.nutritionalInfo
  }

  const ingredientsListData = ingredientList(ingredients)

  return (
    <>
      <BigScreen>
        <Container isWrap>
          <Divider
            borderHeight='3xs'
            color={getGlobalStyle('--color-neutral-gray-divider')}
            verticalMargin={24}
          />
          <Row tagName='section'>
            <Column>
              {/* Description */}
              {description && (
                <Column
                  alignItems='start'
                  justifyContent='start'
                  tagName='article'
                >
                  <Row
                    isWrap
                    tagName='article'
                  >
                    <Title
                      color={getGlobalStyle('--color-text-black')}
                      fontWeight='medium'
                      headingLevel='h4'
                      text='Información del producto'
                    />
                    <Divider
                      borderHeight='3xs'
                      color={getGlobalStyle('--color-neutral-gray-divider')}
                      verticalMargin={24}
                    />
                  </Row>
                  <Row tagName='section'>
                    <Row
                      justifyContent='start'
                      maxWidth='min-content'
                      tagName='article'
                    >
                      <Icon
                        color={getGlobalStyle('--color-icons-black')}
                        customSize={24}
                        name='Description'
                      />
                    </Row>

                    <Row
                      justifyContent='start'
                      margin='0px 12px'
                      tagName='article'
                      width={100}
                    >
                      <Title
                        color={getGlobalStyle('--color-text-black')}
                        fontWeight='medium'
                        headingLevel='h5'
                        text='Descripción'
                      />
                    </Row>
                  </Row>

                  <Row
                    isWrap
                    margin='24px 0'
                    tagName='section'
                  >
                    <Text
                      color='gray'
                      fontSize='lg'
                      fontWeight='regular'
                      type='p'
                    >
                      {description}
                    </Text>
                  </Row>
                </Column>
              )}

              {/* Ingredients */}
              {Array.isArray(ingredients) &&
                ingredients?.length > 0 &&
                !(degrees && volume) && (
                  <Column
                    alignItems='start'
                    justifyContent='start'
                    tagName='article'
                  >
                    <Row tagName='section'>
                      <Row
                        justifyContent='start'
                        maxWidth='min-content'
                        tagName='article'
                      >
                        <Icon
                          color={getGlobalStyle('--color-icons-black')}
                          customSize={24}
                          name='Ingredients'
                        />
                      </Row>

                      <Row
                        justifyContent='start'
                        margin='0px 12px'
                        tagName='article'
                        width={100}
                      >
                        <Title
                          color={getGlobalStyle('--color-text-black')}
                          fontWeight='medium'
                          headingLevel='h5'
                          text='Ingredientes'
                        />
                      </Row>
                    </Row>

                    <Row
                      isWrap
                      margin='24px 0'
                      tagName='section'
                    >
                      <Text
                        color='gray'
                        fontSize='lg'
                        fontWeight='regular'
                        type='p'
                      >
                        {ingredientsListData}
                      </Text>
                    </Row>
                  </Column>
                )}

              {/* Alcohol description */}
              {degrees && volume && (
                <Column
                  alignItems='start'
                  justifyContent='start'
                  tagName='article'
                >
                  <Row tagName='section'>
                    <Row
                      justifyContent='start'
                      maxWidth='min-content'
                      tagName='article'
                    >
                      <Icon
                        color={getGlobalStyle('--color-icons-black')}
                        customSize={24}
                        name='Alcohol'
                      />
                    </Row>

                    <Row
                      justifyContent='start'
                      margin='0px 12px'
                      tagName='article'
                    >
                      <Title
                        color={getGlobalStyle('--color-text-black')}
                        fontWeight='medium'
                        headingLevel='h5'
                        text='Contenido alcohólico'
                      />
                    </Row>
                  </Row>

                  <Row
                    isWrap
                    margin='24px 0'
                    tagName='section'
                  >
                    <Text
                      color='gray'
                      fontSize='lg'
                      fontWeight='regular'
                      type='p'
                    >
                      {`${volume} - ${container} - ${degreesCleaned} de alcohol.`}
                    </Text>
                  </Row>
                </Column>
              )}
            </Column>
            {Object.keys(nutritionalTablesSets).length !== 0 && (
              <>
                <div
                  style={{
                    display: 'flex',
                    borderLeft: `1px solid ${getGlobalStyle(
                      '--color-neutral-gray'
                    )}`,
                    alignSelf: 'stretch',
                    margin: '0 30px'
                  }}
                ></div>
                <Column
                  alignItems='end'
                  justifyContent='center'
                  maxWidth='358px'
                  width='100%'
                >
                  {nutritionalTablesSets && (
                    <NutritionalFactTable {...nutritionalFactProps} />
                  )}
                </Column>
              </>
            )}
          </Row>
        </Container>
      </BigScreen>

      <SmallScreen>
        <Container isWrap>
          <Column
            alignItems='start'
            justifyContent='start'
            tagName='section'
          >
            <Divider
              color={getGlobalStyle('--color-neutral-gray')}
              verticalMargin={24}
            />

            <Row tagName='article'>
              <Icon
                color={getGlobalStyle('--color-icons-black')}
                customSize={24}
                name='Description'
              />
              <Spacer.Vertical size={12} />
              <Title
                color={getGlobalStyle('--color-text-black')}
                fontWeight='medium'
                headingLevel='h5'
                text='Descripción'
              />
            </Row>

            <Spacer.Horizontal size={16} />

            <Row tagName='article'>
              <Text
                color='gray'
                fontSize='md'
                fontWeight='regular'
                type='p'
              >
                {description}
              </Text>
            </Row>
          </Column>
          {volume && degrees && (
            <Column
              alignItems='start'
              justifyContent='start'
              tagName='section'
            >
              <Spacer.Horizontal size={8} />
              <Row tagName='article'>
                <Icon
                  color={getGlobalStyle('--color-icons-black')}
                  customSize={24}
                  name='Alcohol'
                />
                <Spacer.Vertical size={12} />
                <Title
                  color={getGlobalStyle('--color-text-black')}
                  fontWeight='medium'
                  headingLevel='h5'
                  text='Contenido alcohólico'
                />
              </Row>

              <Spacer.Horizontal size={16} />

              <Row tagName='article'>
                <Text
                  color='gray'
                  fontSize='md'
                  fontWeight='regular'
                  type='p'
                >
                  {`${volume} - ${container} - ${degreesCleaned} de alcohol.`}
                </Text>
              </Row>
            </Column>
          )}
        </Container>
      </SmallScreen>
    </>
  )
}
