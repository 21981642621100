import React, { useEffect, useRef, useState } from 'react'
import {
  AddToCartAndQuantity,
  AddToCartProps,
  Button,
  Column,
  Container,
  IconProps,
  Modal,
  pageTypeProp,
  Row,
  Spacer,
  TooltipProps
} from '@smu-chile/pkg-unimarc-components'
import { filteredDetailedData } from '../../ProductDetailPage/helpers'
import {
  COUPON_STATUS_MAP,
  postUsersNavigationEvent,
  productClickTag,
  sleep,
  tagOrderForm,
  useEvents,
  useQueryClient,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { ProductDetailImage } from '../../ProductDetailPage/ProductDetailImage'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ProductDetailPLP } from '../ProductDetailPLP/ProductDetailPLP'
import { ProductDescription } from '../../ProductDetailPage/ProductDescription'
import { CarouselMyUsual } from '../../CarouselMyUsual'
import { ProductDetailCarousel } from '../../ProductDetailPage/ProductDetailCarousel'
import { CouponModalProps } from '../../Shelves'
import { ShelfCouponButton } from '@smu-chile/pkg-unimarc-components/stories/organisms/Shelf/v2/ShelfCouponButton/ShelfCouponButton'
import { BackBoneModal } from './BackBoneModal'
import { ProductDetailError } from '../../ProductDetailError'
import { ProductTabsDetail } from '../../ProductDetailPage/ProductTabsDetail/ProductTabsDetail'
import { handlePdpTags } from 'shared/utils'
import { ModalProps } from '@smu-chile/pkg-unimarc-components/stories/molecules/Modal/helpers'
import { SimilarsModal } from 'components/SimilarsModal/SimilarsModal'
import { ShelfPromoTooltip } from '@smu-chile/pkg-unimarc-components/stories/organisms/Shelf/v2/ShelfPromoTooltip'
import classNames from 'classnames'
import { getTooltipPromoData } from '@smu-chile/pkg-unimarc-components/stories/organisms/Shelf/utils'
import { ProductDetailData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import {
  TUseProductNutrionalDataProps,
  useProductNutrionalData
} from 'components/ProductDetailModalWrapper/helpers/hooks/useProductNutrionalData'
import {
  TUseProductDetailModal,
  useProductDetailModal
} from 'components/ProductDetailModalWrapper/helpers'
import styles from './ProductDetailModal.module.css'
import { useLocalAddress } from 'shared/utils/hooks/useLocalAddress'

let debounce
interface ProductDetailModalProps {
  coupon?: CouponModalProps
  columnSearchRef?: React.RefObject<HTMLDivElement>
  productSlug: string
  isMobile?: boolean
  text?: string | string[]
  stock?: boolean
  productNutritionalData?: TUseProductNutrionalDataProps
  productDetailModalData?: TUseProductDetailModal
  handleModalClose?: () => void
  setIsOpenModalDetail?: (state?: boolean) => void
}

interface AddToCarButtonStyleGetType {
  couponButton?: boolean
  isMobile?: boolean
}

interface IaddToCarButtonStyleGetType {
  maxWidth: string
  maxHeight: string
  sizeCircle: number
}
const addToCarButtonStyleGetProps = ({
  couponButton,
  isMobile
}: AddToCarButtonStyleGetType): IaddToCarButtonStyleGetType => {
  if (isMobile) {
    return {
      maxWidth: couponButton ? '161.5px' : '90%',
      maxHeight: '36px',
      sizeCircle: 25
    }
  }
  return {
    maxWidth: couponButton ? '380px' : '400px',
    maxHeight: '40px',
    sizeCircle: 30
  }
}

export const ProductDetailModal = ({
  coupon,
  columnSearchRef,
  productSlug,
  isMobile = false,
  text,
  handleModalClose
}: ProductDetailModalProps) => {
  const queryClient = useQueryClient()
  const tooltipContainer = useRef(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const orientation = 'vertical'
  const [isOrderFormLoading, setIsOrderFormLoading] = useState<boolean>(false)
  const [couponStatus, setCouponStatus] = useState<string>(coupon?.couponStatus)
  const [isLoadingCoupon, setIsLoadingCoupon] = useState<boolean>(false)
  const [isOpenSimilarsModal, setIsOpenSimilarsModal] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(false)
  const site = 'unimarc'
  const hoverUnimarc = site === 'unimarc' ? styles.similarsButton_initial : ''

  const {
    productDataInfo,
    productSearchBySlugV2QueryInfo,
    productPromotionInfo
  } = useProductDetailModal(productSlug, coupon)

  const { hasLocalAddress } = useLocalAddress()

  const {
    getOldDataFromNewData,
    hasAlcohol,
    isLoading: isLoadingNutritionalData,
    productDataByEan
  } = useProductNutrionalData(
    productDataInfo?.productEan,
    productDataInfo?.isNonFoodType,
    productDataInfo?.isValidEan
  )

  const [showMoreCertificates, setShowMoreCertificates] =
    useState<boolean>(false)

  const remapedDetailedData: ProductDetailData = {
    ...productDataInfo?.productSearchBySlugV2Data,
    ingredients_sets: [...(getOldDataFromNewData?.ingredients_sets || [])],
    certificates: [...(getOldDataFromNewData?.certificates || [])],
    warnings: [...(getOldDataFromNewData?.warnings || [])],
    nutritional_tables_sets: {
      ...getOldDataFromNewData?.nutritional_tables_sets
    }
  }
  const tooltipConditionRender =
    openTooltip && Boolean(productPromotionInfo?.itemsRequiredForPromotion)
  const formatedText = !Array.isArray(text) && text?.replace(/%20/g, ' ')
  const isLoadingCertificates = productDataByEan?.isLoading
  const { nutritionalTablesSets, certificates, warnings, ingredients } =
    filteredDetailedData(remapedDetailedData)

  const handleReloadModal = () => {
    productSearchBySlugV2QueryInfo.refetch()
    productDataByEan.refetch()
  }

  const handleCertificates = () => {
    setShowMoreCertificates((current) => {
      return !current
    })
  }
  const handleBack = () => {
    setIsOpenSimilarsModal(false)
  }
  const {
    shelvesData,
    editTempOrderForm,
    handleOnClick,
    handleAdd,
    handleChange,
    handleRemove
  } = useUpdateFromShelf({
    isOrderFormLoading: isOrderFormLoading,
    isSearch: true,
    products: [productDataInfo?.formatedData],
    hasLocalAddress
  })

  const {
    stock,
    showQuantity,
    buttonStatus: statusButton,
    quantityButtonProps,
    tooltipProps: tooltipPropsData,
    unitMultiplier
  } = shelvesData[0]
  const tooltipProps = {
    ...tooltipPropsData,
    bottom: '10px',
    position: 'relative'
  } as TooltipProps

  const productDetailError = productSearchBySlugV2QueryInfo?.isError

  const couponButton =
    stock && coupon?.couponLabel && productPromotionInfo?.isProductCouponUsed

  const addToCarButtonStyleProps = addToCarButtonStyleGetProps({
    couponButton,
    isMobile
  })

  const modalProps: ModalProps = isMobile
    ? {
        styleProps: {
          borderRadius: '0.75rem 0.75rem 0 0',
          padding: '20px 0 0 0'
        },
        iconsProps: {
          customCloseIcon: 'Close' as IconProps['name'],
          customClassNameCloseIcon: styles.modalCloseIcon,
          sizeIconClose: 20,
          iconPadding: '20px 16px 10px 16px',
          customIconLeft: true,
          customCloseIconLeft: 'ArrowBackNavigate' as IconProps['name'],
          sizeIconLeft: 20,
          iconLeftPadding: '20px 16px 10px 16px',
          iconLefttoggle: handleBack
        },
        dragProps: {
          dragRatio: 0.2,
          draggButton: false
        },
        modalConfigsProps: {
          hiddenX: false,
          isWindowBlocked: true,
          isOpen: isOpenSimilarsModal,
          toggle: handleModalClose,
          toggleOutside: handleModalClose,
          openAnimation: true,
          maxWidth: 'max-content',
          fullScreenSticky: true,
          isAutoHeight: true
        }
      }
    : {
        styleProps: {
          height: 'max-content'
        },
        iconsProps: {
          customCloseIcon: 'Close',
          sizeIconClose: 20,
          iconPadding: isOpenSimilarsModal ? '23px 20px 20px 20px' : '',
          customIconLeft: true,
          customCloseIconLeft: 'Back5' as IconProps['name'],
          sizeIconLeft: 20,
          iconActionHover: true,
          iconLeftPadding: '23px 20px 20px 20px',
          iconLefttoggle: handleBack
        },
        modalConfigsProps: {
          isOpen: isOpenSimilarsModal,
          maxHeight: '550px',
          maxWidth: '837px',
          toggle: handleModalClose,
          toggleOutside: handleModalClose
        }
      }

  const isVertical: boolean = orientation === 'vertical'

  const conditionTooltip = (isAdded?: boolean) => {
    if (isAdded === true) {
      clearTimeout(debounce)
      setOpenTooltip(true)
      debounce = setTimeout(() => {
        setOpenTooltip(false)
      }, 3000)
    } else {
      setOpenTooltip(false)
    }
  }
  const handleOnAddQuantity = (props) => {
    handleAdd(props)
    conditionTooltip(true)
  }
  const handleOnRemoveQuantity = (props) => {
    handleRemove(props)
    conditionTooltip(false)
  }

  const handleOnAddFirst = (props) => {
    handleOnClick && handleOnClick(props)
    conditionTooltip(true)
  }

  const dataAddToCart: AddToCartProps = {
    disabled: false,
    itemId: productDataInfo?.formatedData?.itemId,
    quantity: stock ? showQuantity : 0,
    fullWidth: !isMobile,
    source: 'modal',
    statusButton,
    tooltipProps,
    quantityButtonProps: {
      maxQuantity: Math.floor(
        quantityButtonProps?.maxQuantity * unitMultiplier
      ),
      quantity: Math.floor(quantityButtonProps?.quantity * unitMultiplier),
      handleAdd: handleOnAddQuantity,
      handleChange,
      handleRemove: handleOnRemoveQuantity
    },
    quantityButtonStyles: { ...addToCarButtonStyleProps },
    handleOnClick: handleOnAddFirst
  }

  const productDescriptionData = {
    description: productDataInfo?.formatedData?.description,
    volume: productDataInfo?.formatedData?.['volume'],
    degrees: productDataInfo?.formatedData?.['degrees'],
    container: productDataInfo?.formatedData?.['container'],
    nutritionalTablesSets,
    ingredients
  }
  const productDetailData = {
    hasAlcohol: hasAlcohol,
    pageType: 'PDP' as pageTypeProp,
    product: productDataInfo?.formatedData,
    promotion: productPromotionInfo?.productPromotions,
    propsAddToCart: dataAddToCart,
    stock: stock,
    certificates,
    warnings,
    showMoreCertificates,
    isLoading: isLoadingCertificates,
    handleCertificates
  }

  const productDetailDataTabs = {
    ...productDetailData,
    ...productDescriptionData,
    isMobile: isMobile
  }
  const { titleMessage, subtitle, colorBackground, isComplete } =
    getTooltipPromoData(
      productPromotionInfo?.itemsRequiredForPromotion,
      dataAddToCart?.quantity
    )

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  useEvents({
    eventType: 'couponUpdate',
    callBack: ({ detail: { id, isLoading, status } }) => {
      if (id === coupon?.id) {
        setIsLoadingCoupon(isLoading)
        setCouponStatus(COUPON_STATUS_MAP[status])
      }
    }
  })

  useEffect(() => {
    if (!productDataInfo?.products?.[0]) return
    handlePdpTags({
      product: productDataInfo?.formatedData,
      quantity: quantityButtonProps.quantity,
      orderForm: tagOrderForm
    })
    postUsersNavigationEvent({
      productId: productDataInfo?.products?.[0]?.item?.productId,
      text: formatedText
    })
    const cleanLastSeenProductsWhenProductDataLoads = async () => {
      await sleep(2000)
      queryClient.invalidateQueries('lastSeenProducts')
    }
    cleanLastSeenProductsWhenProductDataLoads()
  }, [productDataInfo?.products?.[0]])

  if (
    productSearchBySlugV2QueryInfo?.isLoading ||
    productSearchBySlugV2QueryInfo?.isFetching ||
    productDataInfo?.products?.length === 0 ||
    isLoadingNutritionalData ||
    isLoadingCertificates ||
    isLoadingNutritionalData
  ) {
    return <BackBoneModal isMobile={isMobile} />
  }

  if (isMobile && productDetailError) {
    return (
      <>
        <Column customHeight='96%'>
          <Row customHeight='100%'>
            <ProductDetailError
              customImageSize={{ width: '246', height: '369' }}
              hideButton
              modalPLP
            />
          </Row>
        </Column>
        <Row
          backgroundColor={getGlobalStyle('--color-base-white')}
          borderRadius='12px 12px 0 0'
          customClassName={styles.containerStickyButtonsMobile}
          justifyContent='center'
          padding='16px 10px'
        >
          <Button
            background={getGlobalStyle('--color-neutral-black')}
            borderRadius={getGlobalStyle('--border-radius-lg')}
            label='Recargar'
            onClick={handleReloadModal}
            size='large'
          />
        </Row>
      </>
    )
  }

  if (isOpenSimilarsModal) {
    return (
      <Row
        customClassName={isVertical ? '' : styles.scrollStyle}
        isWrap={isVertical}
        overflow='auto'
      >
        <Modal {...modalProps}>
          <SimilarsModal
            isMobile={isMobile}
            productSlug={productSlug}
            setIsOpenSimilarsModal={setIsOpenSimilarsModal}
          />
        </Modal>
      </Row>
    )
  }

  if (isMobile) {
    return (
      <Container
        isWrap
        maxHeight='100vh'
        maxWidth='max-content'
        ref={modalRef}
      >
        {tooltipConditionRender && dataAddToCart?.quantity !== 0 && (
          <Container
            absoluteDefault='bottomRight'
            customClassName={classNames(styles['shelf__tooltipPositionMobile'])}
            id='promoTooltipContainer'
            maxHeight='max-content'
            width='fit-content'
            zIndex='3'
          >
            <ShelfPromoTooltip
              backgroundColor={colorBackground}
              borderRadius={getGlobalStyle('--border-radius-md')}
              fontSize='md'
              isMobile={isMobile}
              maxWidth='200px'
              subTitle={subtitle}
              textColor={isComplete ? 'black' : 'white'}
              textLineHeight='17.64px'
              title={titleMessage}
            />
          </Container>
        )}
        <Row
          justifyContent='center'
          margin={tooltipConditionRender ? '-51px 0 0 0' : ''}
          padding='0 0 0 10px'
          position='relative'
        >
          <ProductDetailImage
            alignItems='center'
            alt={productDataInfo?.formatedData?.['name']}
            carouselContainerHeight='270px'
            carouselContainerWidth='270px'
            closePreviousModal
            dots={false}
            handleModalClose={handleModalClose}
            height='270px'
            img={productDataInfo?.formatedData?.['images']?.slice(0, 4)}
            isMobile={isMobile}
            padding={
              productDataInfo?.formatedData?.['images']?.length > 1
                ? '0 0 36px 0'
                : '20px 0 26px 0'
            }
            pictureHeight='270px'
            pictureWidth='270px'
            previewImgSize='50px'
            productName={productDataInfo?.products?.[0]?.item?.name}
            stock={stock}
            title={productDataInfo?.formatedData?.['name']}
            width='270px'
          />
        </Row>
        <Row
          isWrap
          padding='0 0 0 10px'
        >
          <Row
            minHeight='240px'
            padding='0 0 0 10px'
            tagName='article'
          >
            <ProductTabsDetail {...productDetailDataTabs} />
          </Row>
          <Spacer.Horizontal size={24} />
          {stock && (
            <ProductDetailCarousel
              categoryId={productDataInfo?.formatedData?.categorySlug}
              customItemToShow={2}
              customItemToSlide={2}
              ean={productDataInfo?.formatedData?.ean}
              isMobile={isMobile}
              onClickProduct={() => {
                productClickTag({
                  product: productDetailData?.product,
                  site: 'Unimarc',
                  listIdVal: 'productos_similares',
                  listNameVal: 'PDP - Productos Similares'
                })
              }}
            />
          )}
          <CarouselMyUsual
            customItemToShow={2}
            customItemToSlide={2}
            ean={productDataInfo?.formatedData?.ean}
            isMobile={isMobile}
            onClickProduct={() => {
              productClickTag({
                product: productDetailData?.product,
                site: 'Unimarc',
                listIdVal: 'myusuals_pdp',
                listNameVal: 'My usuals - PDP'
              })
            }}
          />
        </Row>
        <Row
          alignItems='end'
          backgroundColor={getGlobalStyle('--color-base-white')}
          borderRadius='12px 12px 0 0'
          boxShadow='0px 0px 6px rgba(27, 33, 56, 0.15)'
          customClassName={styles.containerStickyButtonsMobile}
          gap={16}
          id='sticky-buttons'
        >
          <Row
            gap='16px'
            justifyContent='between'
          >
            {stock ? (
              <AddToCartAndQuantity
                {...dataAddToCart}
                isEditable={false}
                source='modal'
              />
            ) : (
              <Container justifyContent='center'>
                <Button
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  customClassName={hoverUnimarc}
                  fontSize='16px'
                  fontWeight={getGlobalStyle('--font-weight-medium')}
                  height='45px'
                  label='Ver similares'
                  minWidth={'100px'}
                  onClick={() => {
                    setIsOpenSimilarsModal(true)
                  }}
                  site='unimarc'
                  size='xs'
                  status='initial'
                  width='343px'
                />
              </Container>
            )}

            {couponButton && (
              <ShelfCouponButton
                couponStatus={couponStatus}
                isLoading={isLoadingCoupon}
                isMobile={isMobile}
                isModalPLP
                itemId={productDataInfo?.formatedData?.itemId}
                label={coupon?.couponLabel}
                location='PDP'
                onAdd={quantityButtonProps?.handleAdd}
                onAddFirst={handleOnClick}
                onClick={coupon?.onActiveCoupon}
                quantity={quantityButtonProps?.quantity}
              />
            )}
          </Row>
        </Row>
      </Container>
    )
  }

  if (!isMobile && productDetailError) {
    return (
      <>
        <Container
          isWrap
          maxWidth='max-content'
          padding='27px 0 0 24px'
          width='820px'
        >
          <ProductDetailError
            customImageSize={{ width: '246', height: '369' }}
            hideButton
            modalPLP
          />
        </Container>
        <Row
          alignItems='end'
          backgroundColor={getGlobalStyle('--color-base-white')}
          borderRadius='0px 0px 12px 12px'
          customClassName={styles.containerStickyButtons}
          gap={16}
        >
          <Container justifyContent='center'>
            <Button
              borderRadius={getGlobalStyle('--border-radius-lg')}
              label='Recargar'
              onClick={handleReloadModal}
              size='large'
              type='primary'
              width='410px'
            />
          </Container>
        </Row>
      </>
    )
  }

  return (
    <Container
      customClassName={styles['parentContainer']}
      id='productDetailModal__container'
      isWrap
      maxHeight='549px'
      maxWidth='max-content'
      ref={columnSearchRef}
    >
      <Container
        isWrap
        maxWidth='max-content'
        padding='27px 0 0 24px'
        width='820px'
      >
        <Spacer.Horizontal size={20} />
        <Row
          justifyContent='between'
          padding='0 24px 0 0'
        >
          {tooltipConditionRender && dataAddToCart?.quantity !== 0 && (
            <Container
              absoluteDefault='bottomRight'
              customClassName={classNames({
                [styles['shelf__tooltipPositionDesktopCarousel']]:
                  productDataInfo?.productImage?.length > 1,
                [styles['shelf__tooltipPositionDesktopUniquePicture']]:
                  productDataInfo?.productImage?.length === 1
              })}
              id='promoTooltipContainer'
              maxHeight='max-content'
              position='absolute'
              ref={tooltipContainer}
              width='fit-content'
              zIndex='3'
            >
              <ShelfPromoTooltip
                backgroundColor={colorBackground}
                borderRadius={getGlobalStyle('--border-radius-md')}
                fontSize='lg'
                heigth='64px'
                isMobile={isMobile}
                maxWidth='200px'
                subTitle={subtitle}
                textColor={isComplete ? 'black' : 'white'}
                textLineHeight='20.16px'
                title={titleMessage}
              />
            </Container>
          )}
          <Row
            maxWidth={productDataInfo?.productImageSize}
            minHeight={productDataInfo?.productImageSize}
            padding='0 30px'
            position='relative'
            tagName='article'
          >
            <ProductDetailImage
              alt={productDataInfo?.formatedData?.['name']}
              carouselContainerHeight='250px'
              carouselContainerWidth='250px'
              img={productDataInfo?.formatedData?.['images']?.slice(0, 4)}
              padding='0 0 10px 0'
              pictureHeight={productDataInfo?.productImageSize}
              pictureWidth={productDataInfo?.productImageSize}
              previewImgSize='50px'
              stock={stock}
              title={productDataInfo?.formatedData?.['name']}
            />
          </Row>
          <Column
            maxWidth='358px'
            width='100%'
          >
            <ProductDetailPLP {...productDetailData} />
          </Column>
        </Row>
        {/* description */}
        <Spacer size={24} />
        <Row
          id='productDetailSection'
          justifyContent='between'
          padding='0 24px 0 0'
          tagName='article'
        >
          <ProductDescription {...productDescriptionData} />
        </Row>
        {stock && (
          <ProductDetailCarousel
            categoryId={productDataInfo?.formatedData?.categorySlug}
            customItemToShow={4}
            customItemToSlide={4}
            ean={productDataInfo?.formatedData?.ean}
            isMobile={isMobile}
            onClickProduct={() => {
              productClickTag({
                product: productDetailData?.product,
                site: 'Unimarc',
                listIdVal: 'productos_similares',
                listNameVal: 'PDP - Productos Similares'
              })
            }}
          />
        )}
        <CarouselMyUsual
          customItemToShow={4}
          customItemToSlide={4}
          ean={productDataInfo?.formatedData?.ean}
          isMobile={isMobile}
          onClickProduct={() => {
            productClickTag({
              product: productDetailData?.product,
              site: 'Unimarc',
              listIdVal: 'myusuals_pdp',
              listNameVal: 'My usuals - PDP'
            })
          }}
        />
      </Container>
      <Row
        alignItems='end'
        backgroundColor={getGlobalStyle('--color-base-white')}
        borderRadius='0px 0px 12px 12px'
        boxShadow='0px 0px 6px rgba(27, 33, 56, 0.15)'
        customClassName={styles.containerStickyButtons}
        gap={16}
        zIndex='99999'
      >
        {stock ? (
          <AddToCartAndQuantity {...dataAddToCart} />
        ) : (
          <Container justifyContent='center'>
            <Button
              borderRadius={getGlobalStyle('--border-radius-xl')}
              customClassName={hoverUnimarc}
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={getGlobalStyle('--font-weight-medium')}
              height={isMobile ? '30px' : '40px'}
              label='Ver similares'
              minWidth={'100px'}
              onClick={() => {
                setIsOpenSimilarsModal(true)
              }}
              site='unimarc'
              size='xs'
              status='initial'
              width='380px'
            />
          </Container>
        )}
        {couponButton && (
          <ShelfCouponButton
            couponStatus={couponStatus}
            isLoading={isLoadingCoupon}
            isMobile={isMobile}
            isModalPLP
            itemId={productDataInfo?.formatedData?.itemId}
            label={coupon?.couponLabel}
            location='PDP'
            onAdd={quantityButtonProps?.handleAdd}
            onAddFirst={handleOnClick}
            onClick={coupon?.onActiveCoupon}
            quantity={quantityButtonProps?.quantity}
          />
        )}
      </Row>
    </Container>
  )
}
