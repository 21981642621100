export interface LevenshteinDistanceProps {
  str1: string
  str2: string
}

export const levenshteinDistance = ({
  str1 = '',
  str2 = ''
}: LevenshteinDistanceProps): number => {
  if (!str2.length) return str1.length
  if (!str1.length) return str2.length
  const arr = []
  for (let i = 0; i <= str1.length; i++) {
    arr[i] = [i]
    for (let j = 1; j <= str2.length; j++) {
      const ternary = str2[j - 1] === str1[i - 1] ? 0 : 1
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + ternary
            )
    }
  }
  return arr[str1.length][str2.length]
}
