export const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
export const lastnamePattern = /.{3,}/
export const lengthPattern = /.{8,}/
export const lowerCasePattern = /[a-z]/
export const namePattern = /.{3,}/
export const numberPattern = /\d/
export const phonePattern = /^\d{8}$/
export const rutPattern = /.{4,}/
export const specialCharacterPattern = /\W/
export const upperCasePattern = /[A-Z]/
