import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Icon, Row, Text, Title } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { Ingredients } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { ingredientList } from '../../helpers'
import styles from './Tabs.module.css'

interface DescriptionProps {
  description: string
  ingredients?: Array<Ingredients>
  isAlcohol: boolean
}
export const Description = ({
  description,
  ingredients,
  isAlcohol = false
}: DescriptionProps) => {
  const ingredientsData = ingredientList(ingredients)
  const descriptionTextRef = useRef(null)
  const ingredientsTextRef = useRef(null)
  const [descriptionClamp, setDescriptionClamp] = useState<number>(3)
  const [hasDescriptionClamp, setHasDescriptionClamp] = useState<boolean>(false)
  const [descriptionClamped, setDescriptionClamped] = useState<boolean>(false)
  const [ingredientsClamp, setIngredientsClamp] = useState<number>(3)
  const [hasIngredientsClamped, setHasIngredientsClamped] =
    useState<boolean>(false)
  const [ingredientsClamped, setIngredientsClamped] = useState<boolean>(false)

  useEffect(() => {
    if (
      descriptionTextRef?.current?.clientHeight !==
      descriptionTextRef?.current?.scrollHeight
    ) {
      setHasDescriptionClamp(true)
      setDescriptionClamp(0)
    }
    if (
      ingredientsTextRef?.current?.clientHeight !==
      ingredientsTextRef?.current?.scrollHeight
    ) {
      setHasIngredientsClamped(true)
      setIngredientsClamp(0)
    }
  }, [])

  const onResumeDescription = () => {
    setDescriptionClamp(!descriptionClamped ? 3 : 0)
    setDescriptionClamped(!descriptionClamped)
  }
  const onResumeIngredients = () => {
    setIngredientsClamp(!ingredientsClamped ? 3 : 0)
    setIngredientsClamped(!ingredientsClamped)
  }

  return (
    <Row
      isWrap
      tagName='section'
    >
      {description && (
        <Row
          alignItems='start'
          isWrap
          justifyContent='start'
          tagName='article'
        >
          <Row tagName='section'>
            <Row
              justifyContent='start'
              maxWidth='min-content'
            >
              <Icon
                color={getGlobalStyle('--color-icons-black')}
                customSize={24}
                name='Description'
              />
            </Row>

            <Row
              justifyContent='start'
              margin='0px 12px'
              width={100}
            >
              <Title
                color={getGlobalStyle('--color-text-black')}
                customFontSize='16px'
                fontWeight='bold'
                headingLevel='h5'
                text='Descripción'
              />
            </Row>
            {hasDescriptionClamp && (
              <Row
                justifyContent='start'
                margin='0 0 0 auto'
                maxWidth='min-content'
              >
                <Icon
                  color={getGlobalStyle('--color-icons-black')}
                  customSize={24}
                  name={
                    descriptionClamped ? 'DropDownCloseV2' : 'DropDownOpenV2'
                  }
                  onClick={onResumeDescription}
                />
              </Row>
            )}
          </Row>

          <Row
            customClassName={classNames(styles.description, {
              [styles.description__open]: !descriptionClamped,
              [styles.description__close]: descriptionClamped
            })}
            isWrap
            margin='24px 0'
            tagName='section'
          >
            <Text
              color='gray'
              fontSize='md'
              fontWeight='regular'
              lineClamp={descriptionClamp}
              ref={descriptionTextRef}
              type='p'
            >
              {description}
            </Text>
          </Row>
        </Row>
      )}
      {!isAlcohol && ingredientsData && (
        <Row
          alignItems='start'
          isWrap
          justifyContent='start'
          tagName='article'
        >
          <Row tagName='section'>
            <Row
              justifyContent='start'
              maxWidth='min-content'
            >
              <Icon
                color={getGlobalStyle('--color-icons-black')}
                customSize={24}
                name='Ingredients'
              />
            </Row>

            <Row
              justifyContent='start'
              margin='0px 12px'
              width={100}
            >
              <Title
                color={getGlobalStyle('--color-text-black')}
                customFontSize='16px'
                fontWeight='bold'
                headingLevel='h5'
                text='Ingredientes'
              />
            </Row>

            {hasIngredientsClamped && (
              <Row
                justifyContent='start'
                margin='0 0 0 auto'
                maxWidth='min-content'
              >
                <Icon
                  color={getGlobalStyle('--color-icons-black')}
                  customSize={24}
                  name={
                    ingredientsClamped ? 'DropDownCloseV2' : 'DropDownOpenV2'
                  }
                  onClick={onResumeIngredients}
                />
              </Row>
            )}
          </Row>

          <Row
            isWrap
            margin='24px 0'
            tagName='section'
          >
            <Text
              color='gray'
              fontSize='md'
              fontWeight='regular'
              lineClamp={ingredientsClamp}
              ref={ingredientsTextRef}
              type='p'
            >
              {ingredientsData}
            </Text>
          </Row>
        </Row>
      )}
    </Row>
  )
}
