import React, { useState } from 'react'
import {
  AddToCartProps,
  Button,
  Column,
  Container,
  pageTypeProp,
  Row,
  TooltipProps
} from '@smu-chile/pkg-unimarc-components'
import { hideUsedButton } from '@smu-chile/pkg-unimarc-components/stories/organisms/Shelf/v2/helpers'
import { REACT_QUERY_GENERAL } from '../../shared/constants/reactQuery'
import { getDataPromotion, promotionsOfShelves } from '../../shared/helpers'
import { changeProductData } from '../ProductDetailPage/helpers'
import {
  productClickTag,
  useContentful,
  useEvents,
  useSearchesBySlugV2,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { CouponModalProps } from '../Shelves'
import { SimilarsCarousel } from './SimilarsCarousel'
import { ModalBackBone } from './ModalBackBone/ModalBackBone'
import { SimilarsError } from './SimilarsError'
import styles from './SimilarsModal.module.css'
import { CAMPAING_APP_CHIPS_UNIMARC } from 'shared/utils/constanst'
import { useLocalAddress } from 'shared/utils/hooks/useLocalAddress'

interface SimilarsModalProps {
  coupon?: CouponModalProps
  productSlug: string
  isMobile?: boolean
  setIsOpenSimilarsModal?: (state?: boolean) => void
}

interface AddToCarButtonStyleGetType {
  couponButton?: boolean
  isMobile?: boolean
}

interface IaddToCarButtonStyleGetType {
  maxWidth: string
  maxHeight: string
  sizeCircle: number
}
const addToCarButtonStyleGetProps = ({
  couponButton,
  isMobile
}: AddToCarButtonStyleGetType): IaddToCarButtonStyleGetType => {
  if (isMobile) {
    return {
      maxWidth: couponButton ? '163px' : '90%',
      maxHeight: '45px',
      sizeCircle: 25
    }
  }
  return {
    maxWidth: couponButton ? '360px' : '400px',
    maxHeight: '45px',
    sizeCircle: 30
  }
}

export const SimilarsModal = ({
  coupon,
  productSlug,
  isMobile = false,
  setIsOpenSimilarsModal
}: SimilarsModalProps) => {
  const { hasLocalAddress } = useLocalAddress()
  const [isOrderFormLoading, setIsOrderFormLoading] = useState<boolean>(false)
  const formattedSlug = productSlug?.replace(/\/p+$/, '').substring(1)
  const productDetailInfo = useSearchesBySlugV2({
    slug: formattedSlug,
    reactQuery: {
      queryKey: ['searchesBySlugV2', formattedSlug],
      ...REACT_QUERY_GENERAL
    }
  })

  const [showMoreCertificates, setShowMoreCertificates] =
    useState<boolean>(false)
  const { data: formattedData } = changeProductData({
    ...productDetailInfo?.data?.products?.[0]
  })
  const resultPromotions = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE,
      'sys.id[in]': CAMPAING_APP_CHIPS_UNIMARC[0]
    },
    reactQuery: REACT_QUERY_GENERAL
  })
  const showUsedButton = hideUsedButton(coupon?.couponStatus, true)
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })
  const promotion = getDataPromotion(
    dataPromotions,
    formattedData?.promotion?.['name']
  )

  const handleReloadModal = () => {
    productDetailInfo.refetch()
  }

  const handleCertificates = () => {
    setShowMoreCertificates((current) => {
      return !current
    })
  }

  const {
    shelvesData,
    editTempOrderForm,
    handleOnClick,
    handleAdd,
    handleChange,
    handleRemove
  } = useUpdateFromShelf({
    isOrderFormLoading: isOrderFormLoading,
    isSearch: true,
    products: [formattedData],
    hasLocalAddress
  })

  const {
    stock,
    showQuantity,
    buttonStatus: statusButton,
    quantityButtonProps,
    tooltipProps: tooltipPropsData,
    unitMultiplier
  } = shelvesData[0]
  const tooltipProps = {
    ...tooltipPropsData,
    bottom: '10px',
    position: 'relative'
  } as TooltipProps

  const productDetailError = productDetailInfo?.isError

  const couponButton = stock && coupon?.couponLabel && showUsedButton

  const addToCarButtonStyleProps = addToCarButtonStyleGetProps({
    couponButton,
    isMobile
  })

  const dataAddToCart: AddToCartProps = {
    disabled: false,
    itemId: formattedData?.itemId,
    quantity: stock ? showQuantity : 0,
    fullWidth: !isMobile,
    source: 'modal',
    statusButton,
    tooltipProps,
    quantityButtonProps: {
      maxQuantity: Math.floor(
        quantityButtonProps?.maxQuantity * unitMultiplier
      ),
      quantity: Math.floor(quantityButtonProps?.quantity * unitMultiplier),
      handleAdd,
      handleChange,
      handleRemove
    },
    quantityButtonStyles: { ...addToCarButtonStyleProps },
    handleOnClick
  }

  const productDetailData = {
    pageType: 'PDP' as pageTypeProp,
    product: formattedData,
    promotion: promotion,
    propsAddToCart: dataAddToCart,
    stock: stock,
    showMoreCertificates,
    isLoading: productDetailInfo?.isLoading,
    handleCertificates
  }

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  if (
    productDetailInfo?.isLoading ||
    productDetailInfo?.isFetching ||
    productDetailInfo.data?.['data']?.products?.length === 0
  ) {
    return <ModalBackBone isMobile={isMobile} />
  }

  if (isMobile && productDetailError) {
    return (
      <>
        <Column customHeight='96%'>
          <Row customHeight='100%'>
            <SimilarsError
              customImageSize={{ width: '246', height: '369' }}
              hideButton
              modalPLP
            />
          </Row>
        </Column>
        <Row
          backgroundColor={getGlobalStyle('--color-base-white')}
          borderRadius='12px 12px 0 0'
          boxShadow='0px 0px 6px rgba(27, 33, 56, 0.15)'
          customClassName={styles.containerStickyButtonsMobile}
          justifyContent='center'
          padding='16px 10px'
        >
          <Button
            background={getGlobalStyle('--color-base-black')}
            borderRadius={getGlobalStyle('--border-radius-lg')}
            label='Recargar'
            onClick={handleReloadModal}
            size='large'
            width='100%'
          />
        </Row>
      </>
    )
  }

  if (isMobile) {
    return (
      <Container
        customHeight='100vh'
        isWrap
        maxHeight='100vh'
      >
        <SimilarsCarousel
          categoryId={formattedData?.categorySlug}
          customItemToShow={2}
          customItemToSlide={2}
          ean={formattedData?.ean}
          isMobile={isMobile}
          onClickProduct={() => {
            productClickTag({
              product: productDetailData?.product,
              site: 'Unimarc',
              listIdVal: 'productos_similares',
              listNameVal: 'PDP - Productos Similares'
            })
          }}
          setIsOpenSimilarsModal={setIsOpenSimilarsModal}
        />
      </Container>
    )
  }

  if (!isMobile && productDetailError) {
    return (
      <>
        <Container
          isWrap
          maxWidth='max-content'
          padding='27px 0 0 24px'
          width='820px'
        >
          <SimilarsError
            customImageSize={{ width: '246', height: '369' }}
            hideButton
            modalPLP
          />
        </Container>
        <Row
          alignItems='end'
          backgroundColor={getGlobalStyle('--color-base-white')}
          borderRadius='0px 0px 12px 12px'
          boxShadow='0px 0px 6px rgba(27, 33, 56, 0.15)'
          customClassName={styles.containerStickyButtons}
          gap={16}
        >
          <Container justifyContent='center'>
            <Button
              background={getGlobalStyle('--color-base-black')}
              borderRadius={getGlobalStyle('--border-radius-lg')}
              label='Recargar'
              onClick={handleReloadModal}
              size='large'
              width='410px'
            />
          </Container>
        </Row>
      </>
    )
  }

  return (
    <Container
      isWrap
      maxWidth='max-content'
      width='820px'
    >
      <SimilarsCarousel
        categoryId={formattedData?.categorySlug}
        customItemToShow={4}
        customItemToSlide={4}
        ean={formattedData?.ean}
        isMobile={isMobile}
        onClickProduct={() => {
          productClickTag({
            product: productDetailData?.product,
            site: 'Unimarc',
            listIdVal: 'productos_similares',
            listNameVal: 'PDP - Productos Similares'
          })
        }}
        setIsOpenSimilarsModal={setIsOpenSimilarsModal}
      />
    </Container>
  )
}
