import { setCookie, trigger } from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useState } from 'react'

interface LocalAddress {
  data?: {
    city?: string
    country?: string
    neighborhood?: string
    postalCode?: string
    state?: string
    street?: string
    complement?: string
    geoCoordinate?: number[]
    number?: string
    placeId?: string
    receiverName?: string
    reference?: string
    userId?: string
    addressType?: string
  }
  responseData?: {
    salesChannel?: string
    reference?: string
    number?: string
    geoCoordinates?: number[]
    complement?: string
  }
  saveAddresses?: LocalOrderFormAddress[]
}

interface LocalOrderFormAddress {
  addressType?: string
  receiverName?: string
  addressId?: string
  isDisposable?: boolean
  postalCode?: string
  city?: string
  state?: string
  country?: string
  street?: string
  number?: string
  neighborhood?: string
  complement?: string
  reference?: string
  geoCoordinates?: number[]
}

interface LocalOrderForm {
  data?: {
    userProfileId?: string
    clientProfileData?: {
      firstName?: string
      lastName?: string
    }
    selectedAddresses?: LocalOrderFormAddress
    availableAddresses?: LocalOrderFormAddress[]
  }
}

export interface LocalOrderFormQuery {
  data?: LocalOrderForm
}

/**
 * This hook handle all sessionStorage address and local orderfrom
 * @returns {LocalAddress} Return a LocalAddress object
 * @returns {LocalOrderForm} Return a LocalOrderForm object
 * @returns {saveLocalAddress} Return a function that process and save local address into sessionStorage
 * @returns {clearLocalAddress} Return a function that delete data from sessionStorage
 */
export const useLocalAddress = () => {
  const [hasLocalAddress, setHasLocalAddress] = useState(false)
  const [localAddress, setLocalAddress] = useState<LocalAddress>(null)
  const [localOrderForm, setLocalOrderform] = useState<LocalOrderForm>({
    data: {
      userProfileId: 'guest-user',
      clientProfileData: { firstName: 'guest', lastName: 'user' }
    }
  })
  const [localQueryOrderForm, setLocalOrderformQuery] =
    useState<LocalOrderFormQuery>({})

  const transformAddress = (address) => {
    const geoCoordinates = address?.responseData?.geoCoordinates
      ? address.responseData.geoCoordinates
      : address.data.geoCoordinates
    return {
      ...address?.data?.selectedAddresses,
      addressId: address?.addressId
        ? address.addressId
        : `local-${address.data.postalCode}`,
      geoCoordinates: geoCoordinates,
      reference: address.responseData?.reference,
      receiverName: address.data.receiverName,
      postalCode: address.data.postalCode,
      city: address.data.city,
      state: address.data.state.toUpperCase(),
      country: address.data.country,
      street: address.data.street,
      number: address.data.number,
      neighborhood: address.data.neighborhood,
      complement: address.data?.complement,
      addressName: `${address.data.street} ${address.data.number}`,
      addressType: address.data.addressType,
      geoCoordinate: geoCoordinates,
      id: `local-${address.data.postalCode}`
    }
  }

  const saveAddresses = (addressObj) => {
    if (!localAddress?.saveAddresses) return [transformAddress(addressObj)]

    return [...localAddress.saveAddresses, transformAddress(addressObj)].filter(
      (value, index, self) => {
        return (
          self.findIndex((v) => {
            return v.id === value.id
          }) === index
        )
      }
    )
  }

  const saveLocalAddress = (addressObj, router) => {
    const address = {
      ...addressObj,
      saveAddresses: saveAddresses(addressObj)
    }
    setHasLocalAddress(true)
    setLocalAddress(address)
    sessionStorage.setItem('localAddress', JSON.stringify(address))
    setCookie({
      cookieName: 'sc',
      cookieValue:
        addressObj?.data?.addressType === 'pickup-in-point'
          ? addressObj.data.addressType
          : addressObj.responseData.salesChannel
    })
    router.reload()
  }

  const clearLocalAddress = () => {
    sessionStorage.removeItem('localAddress')
    setHasLocalAddress(false)
    setLocalOrderform({})
    setLocalOrderformQuery({})
  }

  useEffect(() => {
    if (window && sessionStorage.getItem('localAddress') && !hasLocalAddress) {
      const sessionLocalAddress = JSON.parse(
        sessionStorage.getItem('localAddress')
      )
      setHasLocalAddress(true)
      setLocalAddress(sessionLocalAddress)
      setCookie({
        cookieName: 'sc',
        cookieValue:
          sessionLocalAddress?.data?.addressType === 'pickup-in-point'
            ? sessionLocalAddress.data.addressType
            : sessionLocalAddress.responseData.salesChannel
      })
    }
  }, [])

  useEffect(() => {
    if (localAddress) {
      const selectedAddress = transformAddress(localAddress)
      setLocalOrderform({
        data: {
          ...localOrderForm.data,
          selectedAddresses: { ...selectedAddress },
          availableAddresses: localAddress?.saveAddresses.filter((address) => {
            return address.addressType === 'residential'
          })
        }
      })
    }
  }, [localAddress])

  useEffect(() => {
    setLocalOrderformQuery({
      data: localOrderForm
    })
  }, [localOrderForm])

  useEffect(() => {
    trigger({
      eventType: 'localAddressStoreLocator',
      data: {
        hasLocalAddress,
        localQueryOrderForm,
        localOrderForm
      }
    })
  }, [hasLocalAddress, localAddress, localOrderForm, localQueryOrderForm])

  return {
    hasLocalAddress,
    localAddress,
    localOrderForm,
    saveLocalAddress,
    clearLocalAddress
  }
}
