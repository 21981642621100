import { Carousel, Container, Picture } from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { ClubFeatureProps } from './ClubFeature'
import styles from './ClubFeatureCarousel.module.css'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

export type ClubFeatureCarouselProps = ClubFeatureProps & {
  isInfinite?: boolean
  showArrows?: boolean
  showDots?: boolean
}

export const ClubFeatureCarousel = ({
  imageBanner,
  isInfinite,
  showArrows,
  showDots
}: ClubFeatureCarouselProps) => {
  const hasTwoOrMore =
    isValidArrayWithData(imageBanner) && imageBanner.length > 1

  const pictureProps = (item: unknown) => {
    return {
      key: item?.['sys']?.['id'],
      src: item?.['image']?.['fields']?.['file']?.['url']
    }
  }

  return (
    <Container id={getBemId('clubFeatureCarousel', `container`)}>
      <Carousel
        arrows={!!showArrows && hasTwoOrMore}
        autoPlay={false}
        containerClass={styles['club-carousel--container']}
        infinite={!!isInfinite && hasTwoOrMore}
        itemClass={styles['club-carousel--item']}
        partialVisbile={false}
        shouldResetAutoplay={!!isInfinite && hasTwoOrMore}
        showDots={showDots}
        ssr
      >
        {isValidArrayWithData(imageBanner) &&
          imageBanner.map((banner, index) => {
            const { key, src } = pictureProps(banner)
            return (
              <Picture
                height='auto'
                id={getBemId('clubFeatureCarousel', `bannerPicture${index}`)}
                key={key}
                lineHeight={0}
                objectFit='contain'
                src={src}
                width='100%'
              />
            )
          })}
      </Carousel>
    </Container>
  )
}
