import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { MembershipHeaderBackBone } from '@smu-chile/pkg-unimarc-components/stories/organisms/MembershipHeader/MembershipHeaderBackBone'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export const MembershipHeaderBackbone = () => {
  const { isMobile } = useMobile()

  return (
    <Column alignItems='center'>
      <BigScreen>
        <>
          <Container
            padding={isMobile ? '0 16px' : '30px 0 0'}
            width='calc(100vw - 46px)'
          >
            <MembershipHeaderBackBone
              buttonsAlign='center'
              height={255}
              profileAlign='left'
            />
          </Container>
          <Column
            margin={isMobile ? '' : '0 0 60px 0'}
            maxWidth={getGlobalStyle('--width-max-desktop')}
          >
            <Spacer.Horizontal size={32} />
            <Column>
              <Container
                margin='30px 0'
                maxHeight='98px'
                width='100%'
              >
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  height={98}
                  width={100}
                />
              </Container>
              <Row gap='12px'>
                <Row
                  gap='12px'
                  margin='0 0 30px 0'
                >
                  <Container
                    maxHeight='80px'
                    width='17px'
                  >
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={17}
                    />
                  </Container>
                  <Container
                    maxHeight='80px'
                    width='121px'
                  >
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={17}
                    />
                  </Container>
                  <Container
                    maxHeight='80px'
                    width='17px'
                  >
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={17}
                    />
                  </Container>
                </Row>
                <Row justifyContent='end'>
                  <Container
                    maxHeight='80px'
                    width='74px'
                  >
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={17}
                    />
                  </Container>
                </Row>
              </Row>
              <Row gap='40px'>
                <Container
                  maxHeight='158px'
                  width='260px'
                >
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={158}
                  />
                </Container>
                <Container
                  maxHeight='158px'
                  width='260px'
                >
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={158}
                  />
                </Container>
                <Container
                  maxHeight='158px'
                  width='260px'
                >
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={158}
                  />
                </Container>
                <Container
                  maxHeight='158px'
                  width='260px'
                >
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={158}
                  />
                </Container>
              </Row>
              <Row
                gap='30px'
                margin='60px 0 0'
              >
                <Container
                  maxHeight='350px'
                  width='45%'
                >
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={350}
                    width={100}
                  />
                </Container>
                <Column gap='30px'>
                  <Container
                    maxHeight='167px'
                    width='100%'
                  >
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={160}
                      width={100}
                    />
                  </Container>
                  <Container
                    maxHeight='167px'
                    width='100%'
                  >
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={160}
                      width={100}
                    />
                  </Container>
                </Column>
              </Row>
            </Column>
          </Column>
        </>
      </BigScreen>
      <SmallScreen>
        <Column
          margin='30px 0'
          maxWidth={getGlobalStyle('--width-max-desktop')}
          padding={isMobile ? '0 16px' : '30px 0 0'}
        >
          <MembershipHeaderBackBone
            buttonsAlign='center'
            height={344}
            profileAlign='center'
          />
          <Container
            margin='60px 0'
            maxHeight='98px'
            width='100%'
          >
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={98}
              width={100}
            />
          </Container>
          <Row gap='12px'>
            <Row
              gap='12px'
              margin='0 0 30px 0'
            >
              <Container
                maxHeight='80px'
                width='17px'
              >
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  height={17}
                />
              </Container>
              <Container
                maxHeight='80px'
                width='121px'
              >
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  height={17}
                />
              </Container>
              <Container
                maxHeight='80px'
                width='17px'
              >
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  height={17}
                />
              </Container>
            </Row>
            <Row justifyContent='end'>
              <Container
                maxHeight='80px'
                width='74px'
              >
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  height={17}
                />
              </Container>
            </Row>
          </Row>
          <Row gap='30px'>
            <Container
              maxHeight='158px'
              width='260px'
            >
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={158}
              />
            </Container>
            <Container
              maxHeight='158px'
              width='260px'
            >
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={158}
              />
            </Container>
          </Row>
        </Column>
      </SmallScreen>
    </Column>
  )
}
