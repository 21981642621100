import {
  Column,
  Icon,
  Row,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import React from 'react'
import { degreesCleaner } from '../../../../shared/helpers'

interface AlcoholProps {
  degrees: string
  container: string
  volume: string
}
export const Alcohol = ({ degrees, container, volume }: AlcoholProps) => {
  const degreesCleaned = degreesCleaner(degrees)
  const formattedContainer =
    container.charAt(0) + container.slice(1, container.length).toLowerCase()
  return (
    <Column
      alignItems='start'
      justifyContent='start'
      tagName='article'
    >
      <Row tagName='section'>
        <Row
          justifyContent='start'
          maxWidth='min-content'
          tagName='article'
        >
          <Icon
            color={getGlobalStyle('--color-icons-black')}
            customSize={24}
            name='Alcohol'
          />
        </Row>

        <Row
          justifyContent='start'
          margin='0px 12px'
          tagName='article'
        >
          <Title
            color={getGlobalStyle('--color-text-black')}
            customFontSize='14px'
            fontWeight='semibold'
            headingLevel='h5'
            text='Contenido alcohólico'
          />
        </Row>
      </Row>

      <Row
        isWrap
        margin='24px 0'
        tagName='section'
      >
        <Text
          color='gray'
          fontSize='md'
          fontWeight='regular'
          type='p'
        >
          {`${volume} - ${formattedContainer} - ${degreesCleaned} de alcohol.`}
        </Text>
      </Row>
    </Column>
  )
}
