import {
  ContentfulResponse,
  getContentfulData,
  IContentfulBasicEntry,
  IContentfulResponse,
  isValidArrayWithData,
  QueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Entry,
  IOptiornsEntriesRequest
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IContentful'
import { SeoInfoProps } from 'pages/static/[...staticPage]'
import { ClubUnimarcStaticBanner } from 'shared/interfaces/ClubUnimarc'

export interface OptionsProps {
  options: IOptiornsEntriesRequest
}

export interface ContentComponents {
  contentOptions: OptionsProps
}

export const getContentFulComponents = async ({
  contentOptions
}: ContentComponents) => {
  const queryClient = new QueryClient()
  const contentFulKey = `contentful_${contentOptions.options['content_type']}`
  await queryClient.prefetchQuery(contentFulKey, () => {
    return getContentfulData(contentOptions)
  })
  const data = queryClient.getQueryData(contentFulKey) as IContentfulResponse

  if (data?.items?.length === 0) {
    return {
      response: {},
      queryClient,
      seoInfo: {
        pageTitle: '',
        description: '',
        robotTag: ''
      }
    }
  }

  const response = {}
  const populatedData = new ContentfulResponse(data)
    .populateEntries()
    .getResponse()

  const components =
    (populatedData?.items?.[0]?.fields
      ?.components as IContentfulBasicEntry[]) || []

  for (const item of components) {
    if (!item.sys?.contentType?.sys?.id || !item?.sys?.id) continue
    response[`${item?.sys?.contentType?.sys?.id}_${item?.sys?.id}`] =
      item.fields
  }

  const seoInfo: SeoInfoProps = {
    pageTitle: (data?.['items'][0]?.fields?.title as string) || '',
    description: (data?.['items'][0]?.fields?.description as string) || '',
    robotTag: (data?.['items'][0]?.fields?.robot as string) || ''
  }

  const footerType = (data?.['items']?.[0]?.footerType as string) || null

  return {
    response: response ?? {},
    queryClient,
    seoInfo,
    footerType
  }
}

const checkContentfulString = (param: string) => {
  if (!param) return ''
  return param
}

export const getContentfulBlocks = async ({ contentOptions }) => {
  let error = false
  const queryClient = new QueryClient()
  await queryClient.prefetchQuery(
    `contenful_${contentOptions.options['content_type']}`,
    () => {
      return getContentfulData(contentOptions)
    }
  )

  const data = queryClient.getQueryData(
    `contenful_${contentOptions.options['content_type']}`
  ) as IContentfulResponse
  const populatedData: unknown = new ContentfulResponse(data)
    .populateEntries()
    .getResponse()
  const items =
    isValidArrayWithData(populatedData['items']) &&
    populatedData['items'].map((item) => {
      const containers = {}
      const containersDesktop = {}

      if (
        isValidArrayWithData(item?.components) &&
        isValidArrayWithData(item?.componentsDesktop)
      ) {
        item.components.forEach((contentType) => {
          const blockType = contentType.sys.contentType.sys.id
          const blockId = contentType.sys.id
          containers[`${blockType}`] = containers[`${blockType}`]
            ? [...containers[`${blockType}`]]
            : []
          containers[`${blockType}`].push({
            [`${blockType}_${blockId}`]: contentType.fields
          })
        })
        item.componentsDesktop.forEach((contentType) => {
          const blockType = contentType.sys.contentType.sys.id
          const blockId = contentType.sys.id
          containersDesktop[`${blockType}`] = containersDesktop[`${blockType}`]
            ? [...containersDesktop[`${blockType}`]]
            : []
          containersDesktop[`${blockType}`].push({
            [`${blockType}_${blockId}`]: contentType.fields
          })
        })
      }
      const loginModal = mapLoginModal(item)
      const welcomeModal = mapWelcomeModal(item)

      const profileBanner = {
        backgroundColor: checkContentfulString(item?.fields?.backgroundColor),
        backgroundImage: checkContentfulString(
          item?.fields?.imagePattern?.fields?.file?.url
        ),
        backgroundImageDesktop: checkContentfulString(
          item?.fields?.imagePatternDesktop?.fields?.file?.url
        ),
        brandIconLabel: checkContentfulString(
          item?.fields?.circleImage?.fields?.title
        ),
        brandIconUrl: checkContentfulString(
          item?.fields?.circleImage?.fields?.file?.url
        )
      }

      const seoInfo: SeoInfoProps = {
        pageTitle: checkContentfulString(item?.fields?.titleWeb),
        description: checkContentfulString(item?.fields?.description),
        previewImage: checkContentfulString(
          item?.fields?.thumbnailWeb?.fields?.file?.url
        )
      }

      return {
        viewId: item.name,
        blocks: item?.components ?? [],
        blocksDesktop: item?.componentsDesktop ?? [],
        containers,
        containersDesktop,
        loginModal,
        profileBanner,
        seoInfo,
        welcomeModal
      }
    })

  data?.['errors']?.map((cfError) => {
    if (cfError?.['sys']?.id !== 'notResolvable') error = true
  })
  return { data: { items }, statusCode: error ? 500 : null }
}

export const mapLoginModal = (entry?: Entry) => {
  return {
    brandClubLoginLogo:
      entry?.fields?.brandClubLoginLogo?.fields?.file?.url ?? null,
    brandClubLoginPicture:
      entry?.fields?.brandClubLoginPicture?.fields?.file?.url ?? null
  }
}

export const mapWelcomeModal = (entry?: Entry) => {
  return {
    brandClubLogo: entry?.fields?.brandClubLogo?.fields?.file?.url ?? null
  }
}

export const getCUBannerUrl = (
  banners: ClubUnimarcStaticBanner,
  isMobile: boolean
) => {
  const search = isMobile ? 'mobile' : 'desktop'

  const bannerId = banners?.includes?.Entry?.filter((banner) => {
    return banner?.fields?.label?.includes(search)
  }).shift()?.fields?.image?.sys?.id

  const bannerUrl = banners?.includes?.Asset.filter((banner) => {
    return banner?.sys?.id === bannerId
  }).shift()?.fields?.file?.url

  return bannerUrl
}
