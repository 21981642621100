import {
  patchAlliance,
  sleep,
  triggerLoginEvent
} from '@smu-chile/pkg-unimarc-hooks'
import { useCallback } from 'react'

export const useHandleActivateAlliance = (alliances, userLevel: string) => {
  return useCallback(
    async (
      isLoggedIn,
      alliances,
      selectedAlliance,
      setAlliances,
      setSelectedAlliance,
      setShowAnimation,
      setActivationError
    ) => {
      setActivationError(false)
      if (!isLoggedIn) {
        triggerLoginEvent({
          eventType: 'loginModal',
          data: { show: true, ref: 'activacion alianza' }
        })
        return
      }

      setSelectedAlliance({
        ...selectedAlliance,
        status: 'loading'
      })

      const activation = await patchAlliance(selectedAlliance.refid, userLevel)

      if (!activation || activation.status >= 400) {
        setActivationError(true)
        return
      }

      if (activation.data.data.status === 'active') {
        const updatedAlliances = alliances.map((a) => {
          if (activation.data.data.refIf === a.refid) {
            return {
              ...a,
              status: a.status,
              target: a.target
            }
          }
          return a
        })
        setAlliances(updatedAlliances)
        setSelectedAlliance({
          ...selectedAlliance,
          status: activation.data.data.status,
          target: activation.data.data.target
        })

        setShowAnimation(true)
        await sleep(1200)
        setShowAnimation(false)
        selectedAlliance.referenceType === 'DirectActivation' &&
          window.open(activation.data.data.target, '_blank')
      }
    },
    [alliances]
  )
}
