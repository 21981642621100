import { GeocodeResult } from 'use-places-autocomplete'

/**
 * Represents a formatted address object.
 */
const formated = {
  city: '',
  country: '',
  neighborhood: '',
  number: '',
  place_id: '',
  postalCode: '',
  state: '',
  street: ''
}

/**
 * Checks if a given filter exists in the types array.
 * @param types - The array of types to search in.
 * @param filter - The filter to look for in the types array.
 * @returns True if the filter is found, false otherwise.
 */
const findTypes = (types: string[], filter: string): boolean => {
  return types.indexOf(filter) > -1
}

/**
 * Formats the given address data into a standardized format.
 * @param addressData - The address data to format.
 * @returns The formatted address object.
 */
const addressFormat = (addressData: GeocodeResult[]): typeof formated => {
  const findPlaceId = addressData.find((addressDataItem) => {
    return addressDataItem.place_id
  })

  if (findPlaceId) {
    formated.place_id = findPlaceId.place_id
  }

  addressData?.map((addresDataItem) => {
    addresDataItem?.address_components?.map((addressParts) => {
      if (findTypes(addressParts.types, 'street_number') && !formated.number) {
        formated.number = addressParts.long_name
      }

      if (findTypes(addressParts.types, 'route') && !formated.street) {
        formated.street = addressParts.short_name
      }

      if (
        (findTypes(addressParts.types, 'locality') ||
          findTypes(addressParts.types, 'administrative_area_level_3')) &&
        !formated.neighborhood
      ) {
        formated.neighborhood = addressParts.long_name
      }

      if (
        findTypes(addressParts.types, 'administrative_area_level_1') &&
        !formated.state
      ) {
        formated.state = addressParts.long_name
      }

      if (
        findTypes(addressParts.types, 'administrative_area_level_2') &&
        !formated.city
      ) {
        formated.city = addressParts.long_name
      }

      if (findTypes(addressParts.types, 'country') && !formated.country) {
        formated.country = 'CHL'
      }

      if (
        findTypes(addressParts.types, 'postal_code') &&
        !formated.postalCode
      ) {
        formated.postalCode = addressParts.long_name
      }
    })
  })

  return formated
}

export { addressFormat }
