import { Row, Spacer, Title } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const PagesContainerDescriptionTitle = ({
  title
}: {
  title?: string
}) => {
  if (!title) return null

  return (
    <>
      <Row maxWidth={getGlobalStyle('--width-max-desktop')}>
        <Title
          color={getGlobalStyle('--color-neutral-gray-dark')}
          customFontSize={getGlobalStyle('--font-size-lg')}
          fontWeight='semibold'
          headingLevel='h2'
        >
          {title}
        </Title>
      </Row>
      <Spacer.Horizontal size={18} />
      <Spacer.Horizontal size={2} />
    </>
  )
}
