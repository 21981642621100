import React from 'react'
import { DetailProps } from '../../ProductDetailPage/ProductDetail'
import { getGeneralProps } from '../../ProductDetailPage/helpers'
import { isValidArrayWithData, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { normalizeString } from '../../../shared/helpers'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Chip,
  Container,
  Icon,
  Link as LinkComponent,
  ListPrice,
  Picture,
  ProductCertificates,
  Row,
  ShelfLabel,
  Spacer,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import Link from 'next/link'
import { ProductDetailFormat } from '../../ProductDetailPage/ProductDetail/ProductDetailFormat'
import { statusPromotionsV2 } from '@smu-chile/pkg-unimarc-components/helpers/promotionsOfShelvesV2'
import styles from '../../ProductDetailPage/ProductDetail/ProductDetail.module.css'

export const ProductDetailPLP = ({
  hasAlcohol,
  certificates,
  isLoading,
  pageType,
  product,
  promotion,
  showMoreCertificates,
  stock,
  warnings,
  handleCertificates
}: DetailProps) => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const {
    isPaymentsMethods,
    isPromotion,
    offer,
    ppum,
    price,
    pricePaymentsMethods,
    pricePromotion
  } = statusPromotionsV2(product)

  const { underlineInOffer, validateListPrice, validateFormat } =
    getGeneralProps(product, isMobile, isPromotion)

  const ListPriceProps = {
    isMxNPromotion: product?.priceDetail?.promotionType === 'mxn',
    isPaymentsMethods,
    isPromotion,
    listPrice: product?.priceDetail?.listPrice,
    membershipData: product?.priceDetail?.membership,
    offer,
    ppum,
    ppumListPrice: product?.priceDetail?.ppumListPrice,
    price,
    priceDetail: product?.priceDetail,
    pricePaymentsMethods,
    pricePromotion,
    saving: product?.promotion?.['saving'],
    title: product?.['item']?.name || product?.name,
    typePromotion4: product?.priceDetail?.promotionType === 'mx$',
    typePromotion5: product?.priceDetail?.promotionType === 'm%n',
    underlineInOffer: underlineInOffer,
    validateListPrice
  }
  const isMembership = isValidArrayWithData(ListPriceProps?.membershipData)
  const handleClickDescription = () => {
    const idDescription = document?.getElementById('productDetailSection')
    idDescription.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <Container
      gap='8px'
      isWrap
    >
      {/* Cucardas */}
      <ShelfLabel
        altText={`${product?.['descriptionShort']} ${product?.brand} ${validateFormat}`}
        chip={{
          font: 18,
          padding: '0',
          borderRadius: getGlobalStyle('--border-radius-lg')
        }}
        isMembership={isMembership}
        isMobile={isMobile}
        pageType={pageType}
        place='PDP'
        position='initial'
        promotion={promotion}
        promotionContainerPadding='0px 0px 10px 0px'
        provider={product?.['provider']}
      />
      {!stock && (
        <Row
          backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
          borderRadius='20px'
          justifyContent='center'
          padding='4px'
          width='fit-content'
        >
          <Text
            color='grayNeutral'
            fontSize='md'
            fontWeight='medium'
          >
            Pronto disponible
          </Text>
        </Row>
      )}
      {promotion && stock && (
        <Container
          absoluteDefault='topLeft'
          justifyContent='start'
        >
          {!promotion?.image ? (
            <Chip
              {...promotion}
              borderRadius={getGlobalStyle('--border-radius-lg')}
              fontSize={16}
              isMembership={isMembership}
              isMobile={isMobile}
              place='PDP'
            />
          ) : (
            <Picture
              alt={`${product?.['descriptionShort']} ${product?.brand} ${product?.['netContent']}`}
              border='none'
              borderRadius='none'
              height='25px'
              src={promotion?.image}
              width='100%'
            />
          )}
        </Container>
      )}
      {/* brand */}
      <Row>
        <LinkComponent
          href={`/brand/${normalizeString(product?.brand?.toString())}`}
          underline='none'
          wrapper={Link}
        >
          <Text
            clickable='pointer'
            color='black'
            customClassName={styles.textBrand}
            fontSize='lg'
            fontWeight='semibold'
            type='p'
          >
            {product?.brand?.toLowerCase()}
          </Text>
        </LinkComponent>
      </Row>

      {/* name */}
      <Row>
        <Title
          customFontSize={getGlobalStyle('--font-size-titles-md')}
          fontWeight='regular'
        >
          {product?.name}
        </Title>
      </Row>

      {/* format */}
      <Row>
        <ProductDetailFormat
          container={product?.['container']}
          degrees={product?.['degrees']}
          validatedFormat={validateFormat}
          volume={product?.['volume']}
        />
      </Row>

      {/* sku */}
      <Row>
        <Text
          color='gray'
          fontSize='xs'
          fontWeight='regular'
          type='p'
        >
          Sku: {product?.refId || product?.sku}
        </Text>
      </Row>

      {/* product with stock */}
      {stock && (
        <ListPrice
          {...ListPriceProps}
          place='PDP'
        />
      )}

      {/* Go to detailed description button */}
      <Spacer.Horizontal size={18} />
      <Row
        border='0.3px solid #DEDEDE'
        borderRadius='8px'
        clickable='pointer'
        justifyContent='between'
        maxHeight='min-content'
        onClick={handleClickDescription}
        padding='12px'
      >
        <Text
          clickable='pointer'
          fontSize='lg'
          fontWeight='medium'
        >
          Información del producto
        </Text>
        <Icon
          clickable='pointer'
          color={getGlobalStyle('--color-base-black')}
          customSize={16}
          name='ArrowRightSideNav'
          {...{ style: { marginTop: '3px' } }}
        />
      </Row>
      <Spacer.Horizontal size={16} />
      {/* Product certificates and warnings */}
      {isValidArrayWithData(certificates) && (
        <Row>
          <ProductCertificates
            certificates={certificates}
            hasAlcohol={hasAlcohol}
            isLoading={isLoading}
            isOpen={showMoreCertificates}
            onClickShowLessMore={handleCertificates}
            warnings={warnings}
          />
        </Row>
      )}
    </Container>
  )
}
