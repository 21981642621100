import { ReactChild } from 'react'
import { Column } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './CouponDetailHeaderCenter.module.css'

export const CouponChannelColorMap: Record<string, string> = {
  all: '',
  'Solo App': getGlobalStyle('--color-campaigns-orange'),
  'Solo Tienda': getGlobalStyle('--color-campaigns-blue')
}

export interface CouponDetailHeaderCenterProps {
  children: ReactChild[]
}

export const CouponDetailHeaderCenter = ({
  children
}: CouponDetailHeaderCenterProps) => {
  return (
    <>
      <SmallScreen>
        <Column
          customClassName={styles.dashed}
          padding='0 8px 0 0'
        >
          {children}
        </Column>
      </SmallScreen>
      <BigScreen>
        <Column padding='0 24px'>{children}</Column>
      </BigScreen>
    </>
  )
}
