import { ReactChild } from 'react'
import classNames from 'classnames'
import { Column, Container, Row } from '@smu-chile/pkg-unimarc-components'
import styles from './CouponDetailStamp.module.css'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface CouponDetailStampProps {
  children: ReactChild
  shadowless?: boolean
  stampSize?: number
}

export const CouponDetailStampCircle = ({
  margin,
  size = 16
}: {
  margin?: string
  size?: number
}) => {
  return (
    <div
      style={{
        border: `${size * 0.75}px solid ${getGlobalStyle(
          '--color-base-white'
        )}`,
        borderRadius: '100%',
        margin,
        padding: size * 0.5
      }}
    />
  )
}

export const CouponDetailStampCorner = ({ size = 16 }: { size?: number }) => {
  return (
    <div
      style={{
        backgroundColor: getGlobalStyle('--color-base-white'),
        height: `calc(50% - ${size * 0.5}px)`,
        width: '100%'
      }}
    />
  )
}

export const CouponDetailStamp = ({
  children,
  shadowless,
  stampSize = 16
}: CouponDetailStampProps) => {
  const classRowMap = {
    [styles.wrap]: true,
    [styles.shadowless]: shadowless
  }

  classNames(classRowMap)

  return (
    <Container isWrap>
      <Row
        borderRadius='8px'
        className={classNames(classRowMap)}
      >
        <Column
          borderRadius='8px 0 0 8px'
          maxWidth={`${stampSize}px`}
          overflow='hidden'
        >
          <CouponDetailStampCorner size={stampSize} />

          <CouponDetailStampCircle
            margin={`-${stampSize * 0.75}px 0 ${stampSize * -0.5}px -${
              stampSize * 1.25
            }px`}
            size={stampSize}
          />

          <CouponDetailStampCorner size={stampSize} />
        </Column>

        <Column
          backgroundColor={getGlobalStyle('--color-base-white')}
          padding={`${stampSize * 0.5}px 0`}
        >
          {children}
        </Column>

        <Column
          borderRadius='0 8px 8px 0'
          maxWidth={`${stampSize}px`}
          overflow='hidden'
        >
          <CouponDetailStampCorner size={stampSize} />

          <CouponDetailStampCircle
            margin={`-${stampSize * 0.75}px -${stampSize * 0.25}px`}
            size={stampSize}
          />

          <CouponDetailStampCorner size={stampSize} />
        </Column>
      </Row>
    </Container>
  )
}
