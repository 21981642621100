import Link from 'next/link'

export const aislesProps = ({
  categoriesDataImage,
  categoriesBannerImage,
  isMobile,
  offersMobile,
  resultAisles
}) => {
  const handleCategoryUrl = ({ url = '' }) => {
    if (
      window.location.origin === process.env.NEXT_PUBLIC_HOMEURL &&
      /\.com\.br(.+)/.test(url)
    ) {
      return `/category` + /\.com\.br(.+)/.exec(url)[1]
    }
    // get URL of vtex and clean it up with the new structure
    if (/\.com\.br(.+)/.test(url)) {
      return (
        `${process.env.NEXT_PUBLIC_HOMEURL}/category` +
        /\.com\.br(.+)/.exec(url)[1]
      )
    }
    return url
  }
  const dataAisles =
    (Array.isArray(resultAisles?.data?.data) &&
      resultAisles?.data?.data.map((item) => {
        return {
          ...item,
          img:
            Array.isArray(categoriesDataImage) &&
            categoriesDataImage.find((category) => {
              return category?.idCategory == item?.id
            })?.categoryImage?.file?.url,
          desktopImage:
            Array.isArray(categoriesBannerImage) &&
            categoriesBannerImage.find((category) => {
              return category?.idCategory == item?.id
            })?.desktopImage?.file?.url
        }
      })) ||
    []
  // implement offers to first object
  if (isMobile) dataAisles.unshift({ name: 'Ofertas', ...offersMobile })

  return {
    data: dataAisles,
    linkWrapper: Link,
    getCategoryUrl: handleCategoryUrl
  }
}
