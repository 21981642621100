import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { TTokens } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ITokens'
import ErrorBoundary from 'components/ErrorBoundary'
import { Layout } from 'components/Layout'
import { ReactChild } from 'react'

interface LayoutAndErrorBundaryProps {
  children: ReactChild
  backgroundColor?: TTokens
  hideHeader?: boolean
  hideFooter?: boolean
}

export const LayoutAndErrorBundary = ({
  children,
  backgroundColor = '--color-background-white',
  hideHeader,
  hideFooter
}: LayoutAndErrorBundaryProps): React.ReactElement => {
  return (
    <Layout
      backgroundColor={getGlobalStyle(backgroundColor)}
      hideFooter={hideFooter}
      hideHeader={hideHeader}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Layout>
  )
}
