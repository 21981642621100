import { Tabs } from '@smu-chile/pkg-unimarc-components/stories/molecules/Tabs/Tabs'
import { DetailProps, ProductDetail } from '../ProductDetail'
import { DescriptionProps } from '../ProductDescription'
import React from 'react'
import { Description } from './Tabs/Description'
import { NutritionalInfo } from './Tabs/NutritionalInfo'
import { Certificates } from './Tabs/Certificates'
import { Alcohol } from './Tabs/Alcohol'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'

const generateProductTitles = (
  product,
  certificates,
  nutritionalTablesSets,
  warnings
) => {
  const arrayTitles = ['Producto']

  if (product?.description) arrayTitles.push('Descripción')

  if (product?.['degrees'] && product?.['container']) {
    arrayTitles.push('Contenido alcohólico')
  }

  if (isValidArrayWithData(certificates)) {
    arrayTitles.push('Estilos de alimentación')
  }
  if (
    isValidArrayWithData(warnings) ||
    Object.keys(nutritionalTablesSets)?.length > 0
  ) {
    arrayTitles.push('Información nutricional')
  }

  return arrayTitles
}

interface ProductTabsDetailProps extends DetailProps, DescriptionProps {
  isMobile?: boolean
}
export const ProductTabsDetail = ({
  product,
  promotion,
  propsAddToCart,
  stock,
  certificates,
  warnings,
  showMoreCertificates,
  isLoading,
  description,
  ingredients,
  nutritionalTablesSets,
  isMobile,
  hasAlcohol,
  handleCertificates
}: ProductTabsDetailProps) => {
  const titles = generateProductTitles(
    product,
    certificates,
    nutritionalTablesSets,
    warnings
  )
  const isAlcohol = product?.['degrees'] && product?.['container']
  const tabs = titles.reduce((result, title) => {
    if (title === 'Producto') {
      result?.push(
        <ProductDetail
          key='Producto'
          product={product}
          promotion={promotion}
          propsAddToCart={propsAddToCart}
          stock={stock}
        />
      )
    }

    if (title === 'Descripción') {
      result?.push(
        <Description
          description={description}
          ingredients={ingredients}
          isAlcohol={isAlcohol}
        />
      )
    }

    if (title === 'Información nutricional') {
      result?.push(
        <NutritionalInfo
          hasAlcohol={hasAlcohol}
          isMobile={isMobile}
          nutritionalTablesSets={nutritionalTablesSets}
          warnings={warnings}
        />
      )
    }

    if (title === 'Estilos de alimentación') {
      result?.push(
        <Certificates
          certificates={certificates}
          handleCertificates={handleCertificates}
          isLoading={isLoading}
          isMobile={isMobile}
          showMoreCertificates={!showMoreCertificates}
        />
      )
    }

    if (title === 'Contenido alcohólico') {
      result?.push(
        <Alcohol
          container={product?.['container']}
          degrees={product?.['degrees']}
          volume={product?.['volume']}
        />
      )
    }
    return result
  }, [])

  return (
    <Tabs
      customPadding='24px 10px 24px 0'
      tabs={tabs}
      titles={titles}
    />
  )
}
