import {
  useGetNutritionalDataByEan,
  getOldStructureDataFromNewNutritionalData,
  useProductDetailByEan,
  isObjectEmpty
} from '@smu-chile/pkg-unimarc-hooks'
import {
  IProductDetailByEanResponse,
  ProductDetailData
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'

export type TUseProductNutrionalDataProps = {
  productDataByEan: IProductDetailByEanResponse
  getOldDataFromNewData: ProductDetailData
  isLoading: boolean
}
/**
 * @name useProductNutrionalData
 * @function useProductNutrionalData
 * @param {ean: string, isNonFoodType: boolean, isValidEan: boolean} props - each of the options that the custom hook accepts
 * @description get product data and proccess it from useGetNutritionalDataByEan & useProductDetailByEan
 * */

export const useProductNutrionalData = (
  ean: string,
  isNonFoodType: boolean,
  isValidEan: boolean
) => {
  const { isLoading, data } = useGetNutritionalDataByEan({
    ean,
    reactQuery: {
      queryKey: ean,
      enabled: isValidEan && !isNonFoodType
    }
  })
  const getOldDataFromNewData = getOldStructureDataFromNewNutritionalData(data)
  const productDataByEan = useProductDetailByEan({
    reactQuery: {
      queryKey: ean,
      enabled:
        isValidEan && !isNonFoodType && !isObjectEmpty(getOldDataFromNewData)
    },
    ean
  })

  return {
    getOldDataFromNewData,
    hasAlcohol: data?.hasAlcohol,
    isLoading,
    productDataByEan
  }
}
