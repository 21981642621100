import {
  Backbone,
  Row,
  Column,
  Container,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const HeaderMiniCartLoader = (): React.ReactElement => {
  return (
    <Container isWrap>
      <Row>
        <Column
          justifyContent='center'
          maxWidth='max-content'
          padding='0px 4px 0 0'
        >
          <Text
            fontSize='2xl'
            fontWeight='semibold'
          >
            Mi carro
          </Text>
        </Column>
        <Column maxWidth='max-content'>
          <Row>
            <Column
              justifyContent='center'
              minHeight='30px'
              width='123px'
            >
              <Backbone
                backgroundColor={getGlobalStyle('--color-neutral-gray')}
                borderRadius={getGlobalStyle('--border-radius-xs')}
                height={23}
                width={100}
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  )
}
