import { datalayerSimpleEvent } from '@smu-chile/pkg-unimarc-hooks'
import { IAuthRegisterErrorData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces'

const avoidCodes = ['SSO_31', 'SSO_33', 'SSO_36']

const defaultErrorMessage =
  'Tuvimos un error al cargar tu información. Intenta de nuevo.'

const errorMessages = {
  '400_SSO_30_': defaultErrorMessage,
  '409_SSO_31_rut': null,
  '409_SSO_33_email': null,
  '409_SSO_36_phone': null,
  '409_SSO_37_phone': null,
  '404_SSO_61_': 'Registro expirado. Solicita un nuevo código.',
  '410_SSO_62_': 'El código ingresado ha expirado. Solicita uno nuevo.',
  '400_SSO_63_': 'El código ingresado es incorrecto. Intenta de nuevo.',
  '429_SSO_60_retryAfter_minutes': 'Has superado el número de intentos.'
}
const errorsTag = {
  event: 'register',
  eventAction: 'notification',
  eventCategory: 'code'
}

export const getErrorMessage = (error: IAuthRegisterErrorData) => {
  const code = error.error?.code
  const statusCode = error.error?.statusCode

  let meta = ''
  let retryAfter = 0

  if (avoidCodes.includes(code)) {
    return null
  }

  if (error.error?.meta?.conflict) {
    meta = `conflict_${error.error?.meta?.conflict}`
  }

  if (error.error?.meta?.retryAfter) {
    meta = `retryAfter_minutes`
    retryAfter = error.error?.meta?.retryAfter
  }

  let safeErrorMessage = errorMessages[`${statusCode}_${code}_${meta}`]

  if (safeErrorMessage === errorMessages['400_SSO_63_']) {
    datalayerSimpleEvent({
      ...errorsTag,
      eventLabel: 'otp_incorrect'
    })
  }

  if (
    statusCode === 429 &&
    code === 'SSO_60' &&
    meta === 'retryAfter_minutes'
  ) {
    safeErrorMessage = `${safeErrorMessage} Vuelve a intentarlo en ${
      retryAfter < 60
        ? `${retryAfter % 60} segundo${getErrorRetryAfterTextSuffix(
            retryAfter % 60
          )}`
        : `${(retryAfter / 60).toFixed(0)} minuto${getErrorRetryAfterTextSuffix(
            retryAfter / 60
          )}`
    }.`
    datalayerSimpleEvent({
      ...errorsTag,
      eventLabel: 'limit_attempts'
    })
  }

  if (!safeErrorMessage) {
    datalayerSimpleEvent({
      ...errorsTag,
      eventLabel: 'data_load_error'
    })
  }

  return safeErrorMessage || defaultErrorMessage
}

export const getErrorRetryAfterTextSuffix = (retryAfter: number) => {
  return retryAfter > 1 ? 's' : ''
}
