import React from 'react'
import {
  Column,
  Divider,
  NutritionalFactTable,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  NutritionalTable,
  Warnings
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { ProductWarnings } from '@smu-chile/pkg-unimarc-components/stories/organisms/ProductWarnings/ProductWarnings'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface NutritionalInfoProps {
  hasAlcohol?: boolean
  nutritionalTablesSets?: NutritionalTable
  warnings?: Array<Warnings>
  isMobile?: boolean
}

export const NutritionalInfo = ({
  hasAlcohol,
  nutritionalTablesSets,
  warnings,
  isMobile
}: NutritionalInfoProps) => {
  const nutritionalFactProps = {
    name: nutritionalTablesSets?.name,
    basicUnit: nutritionalTablesSets?.basicUnit,
    portionText: nutritionalTablesSets?.portionText,
    portionValue: nutritionalTablesSets?.portionValue,
    portionUnit: nutritionalTablesSets?.portionUnit,
    numPortions: nutritionalTablesSets?.numPortions,
    nutritionalInfo: nutritionalTablesSets?.nutritionalInfo,
    showCollapsed: true
  }

  return (
    <Column
      maxWidth='358px'
      width='100%'
    >
      {Array.isArray(warnings) && warnings.length > 0 && (
        <Row>
          <ProductWarnings
            hasAlcohol={hasAlcohol}
            isMobile={isMobile}
            warnings={warnings}
          />
        </Row>
      )}
      <Divider
        borderHeight='3xs'
        color={getGlobalStyle('--color-neutral-gray-divider')}
        verticalMargin={11}
      />
      {Object.keys(nutritionalTablesSets)?.length > 0 && (
        <NutritionalFactTable {...nutritionalFactProps} />
      )}
    </Column>
  )
}
