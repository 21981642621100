import React, { useMemo } from 'react'
import { Context } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface ResponsiveContextProps {
  children: React.ReactElement
  isMobile: boolean
}

export const ResponsiveContext = ({
  isMobile,
  children
}: ResponsiveContextProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const width = isMobile ? innerWidth : 1280

  const contextValue = useMemo(() => {
    return { width }
  }, [width])

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}
