import { datalayerSimpleEvent } from '@smu-chile/pkg-unimarc-hooks'
import { IAuthRegisterErrorData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces'

const defaultErrorMessage = null

const errorMessages = {
  SSO_31: 'Este RUT ya se encuentra registrado.'
}

export const getRutErrorMessage = (error: IAuthRegisterErrorData) => {
  const code = error.error?.code

  const safeErrorMessage = errorMessages[`${code}`]

  safeErrorMessage &&
    datalayerSimpleEvent({
      event: 'register',
      eventAction: 'notification',
      eventCategory: 'validations',
      eventLabel: 'rut_in_use'
    })

  return safeErrorMessage ?? defaultErrorMessage
}
