import {
  Column,
  Container,
  Horizontal,
  Row,
  Title
} from '@smu-chile/pkg-unimarc-components'
import {
  useContentful,
  useMobile,
  useProductsSearch
} from '@smu-chile/pkg-unimarc-hooks'
import { Shelves } from 'components'
import { useEffect, useState } from 'react'
import { promotionsOfShelves } from 'shared/helpers'
import { paginationCal } from 'shared/helpers/paginationCal'
import { CAMPAING_APP_CHIPS_UNIMARC } from 'shared/utils/constanst'
import { LiveShopingBackbone } from './LiveShopingBackbone'

export const LiveShoping = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { innerWidth } = useMobile()
  const resultPromotions = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE,
      'sys.id[in]': CAMPAING_APP_CHIPS_UNIMARC[0]
    }
  })
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })
  const { offset, end } = paginationCal({ page: 0, limit: 50 })
  const contentFields = useContentful({
    options: {
      content_type: 'home',
      'sys.id[in]': '6y30mcmwu6EsniC2vkCF3N'
    }
  }).data?.['includes']?.Entry[0]?.fields
  const formattedOffset = Math.abs(offset).toString()
  const formattedEnd = Math.abs(end).toString()
  const formattedClusterId = contentFields?.idCollection
    .match(/\d+/)[0]
    .toString()
  const { liveTitle, liveSubtitle } = {
    liveTitle: contentFields?.title,
    liveSubtitle: contentFields?.subTitle
  }

  const productsByCategory = useProductsSearch({
    clusterId: formattedClusterId,
    from: formattedEnd,
    to: formattedOffset
  })

  const src = contentFields?.html
    ?.split('src="')[1]
    ?.split('%22%3E')[0]
    ?.split('"></script>')[0]
  const isMobile = innerWidth < 1280
  const columnMaxWidth = isMobile ? '92%' : '1120px'
  const products = productsByCategory?.data?.availableProducts

  const callNizzaStream = () => {
    const script = document.createElement('script')
    script.src = src
    script.id = 'nizza-player-script'
    script.type = 'module'

    document.body.appendChild(script)
  }

  useEffect(() => {
    if (!products) return

    setIsLoading(false)
    callNizzaStream()
  }, [products])

  if (isLoading) {
    return (
      <Container>
        <LiveShopingBackbone />
      </Container>
    )
  }

  return (
    <Container>
      <Column alignItems='center'>
        <Column maxWidth={columnMaxWidth}>
          <Horizontal size={24} />
          <Row
            alignItems='center'
            minHeight='440px'
          >
            <div id='nizza-player'></div>
          </Row>
          <Row padding={'0px 0px 12px 0px'}>
            <Column>
              <Row>
                <Title
                  fontWeight='semibold'
                  headingLevel={isMobile ? 'h5' : 'h3'}
                  text={liveTitle}
                />
              </Row>
              <Row>
                <Title
                  fontWeight='regular'
                  headingLevel={isMobile ? 'h6' : 'h4'}
                  text={liveSubtitle}
                />
              </Row>
            </Column>
          </Row>
        </Column>
        <Row maxWidth={columnMaxWidth}>
          <Shelves
            dataPromotions={dataPromotions}
            isMobile={isMobile}
            orientation='horizontal'
            products={products}
          />
        </Row>
      </Column>
    </Container>
  )
}
