import { ReactChild } from 'react'
import { Container, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface CouponDetailPageProps {
  backToText?: string
  children?: ReactChild
  onToggle?: () => void
  open?: boolean
}

export const CouponDetailPage = ({ children, open }: CouponDetailPageProps) => {
  if (!open) {
    return null
  }

  return (
    <Container isWrap>
      <Row
        backgroundColor={getGlobalStyle('--color-background-gray-light')}
        gap={'12px'}
        padding='16px'
      >
        {children}
      </Row>
    </Container>
  )
}
