import { useEffect } from 'react'
import { NextRouter } from 'next/router'
import { sleep } from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

/**
 * Custom hook to dynamically add a bottom border to the main header
 * based on the current URL.
 *
 * @param {NextRouter} router - The Next.js router object to detect the current route.
 *
 * @example
 * const router = useRouter();
 * useBorderBottomByPage(router);
 */

export const useBorderBottomByPage = (router: NextRouter) => {
  useEffect(() => {
    const updateHeaderClass = async () => {
      await sleep(1000)

      const header = document.querySelector(
        '[class*=main-header-mobile]'
      ) as HTMLElement

      if (!header) return

      const shouldAddClass = ['/category', '/search', '/ofertas'].some(
        (path) => {
          return router.asPath.includes(path)
        }
      )

      if (shouldAddClass) {
        header.style.borderBottom = `0.3px solid ${getGlobalStyle(
          '--color-neutral-gray-divider'
        )}`
      } else {
        header.style.borderBottom = 'none'
      }
    }

    updateHeaderClass()

    router.events.on('routeChangeComplete', updateHeaderClass)

    return () => {
      router.events.off('routeChangeComplete', updateHeaderClass)
    }
  }, [router])
}
