import { useEffect, MutableRefObject } from 'react'

export interface UseOutsideProps {
  onClick: () => void
  ref: MutableRefObject<unknown>
}

export function useOutside({ onClick, ref }: UseOutsideProps) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current?.['contains'](event.target)) {
        onClick()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
