import {
  Column,
  Container,
  Row,
  Text,
  Button,
  Spacer,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface ImageSizesProps {
  width: string
  height: string
}
interface SimilarsErrorProps {
  hideButton?: boolean
  handleReload?: () => void
  customImageSize?: ImageSizesProps
  modalPLP?: boolean
}

const getPropsByPortView = ({
  isMobile = false,
  modalPLP = false,
  customImageSize
}) => {
  if (isMobile)
    return {
      containerMinHeight: '100%',
      containerPadding: '10px 0 0 0',
      columnPadding: '0',
      buttonWidth: '295px',
      imageWidth: customImageSize?.width ?? '200px',
      imageHeight: customImageSize?.height ?? '250px'
    }

  return {
    containerMinHeight: '505px',
    containerPadding: modalPLP ? '0' : '68px 0 0 0',
    columnPadding: '42px 0 0 0',
    buttonWidth: '410px',
    imageHeight: customImageSize?.height ?? '369px',
    imageWidth: customImageSize?.width ?? '320px'
  }
}
export const SimilarsError = ({
  hideButton = false,
  handleReload,
  customImageSize,
  modalPLP = false
}: SimilarsErrorProps) => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const handleDetailErrorFunction = () => {
    window.location.reload()
  }
  const onReload = handleReload ?? handleDetailErrorFunction
  const portViewSizes = getPropsByPortView({
    isMobile,
    modalPLP,
    customImageSize
  })
  const modalHeight = () => {
    if (modalPLP && !isMobile) {
      return '550px'
    }
    return 'max-content'
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-background-white')}
      justifyContent='center'
      minHeight={modalHeight()}
    >
      <Container
        backgroundColor={getGlobalStyle('--color-background-white')}
        customWidth='90'
        gap='45px'
        justifyContent='center'
        maxWidth='1220px'
        minHeight={portViewSizes.containerMinHeight}
        padding={portViewSizes.containerPadding}
      >
        <Column
          alignItems='end'
          maxWidth='410px'
          padding={portViewSizes.columnPadding}
        >
          {isMobile && (
            <Column alignItems='center'>
              <Picture
                height='250px'
                src='https://unimarcdev.vtexassets.com/arquivos/errorv2.jpg'
                width='200px'
              />
              <Spacer.Horizontal size={8} />
            </Column>
          )}
          <Row>
            <Text
              fontSize='5xl'
              fontWeight='semibold'
              textAlign='center'
            >
              No eres tú, somos nosotros...
            </Text>
          </Row>
          <Spacer.Horizontal size={16} />
          <Row
            alignSelf='center'
            maxWidth='max-content'
          >
            <Text
              customColor={getGlobalStyle('--color-neutral-gray-dark')}
              fontSize='lg'
              textAlign='center'
            >
              Estamos haciendo lo posible para mostrártelo.
            </Text>
          </Row>
          <Spacer.Horizontal size={isMobile ? 8 : 64} />
          <Row
            alignSelf='center'
            maxWidth='max-content'
          >
            <Text
              customColor={getGlobalStyle('--color-neutral-gray-dark')}
              fontSize='md'
              textAlign='center'
            >
              Recarga la página y sigue comprando.
            </Text>
          </Row>
          <Spacer.Horizontal size={18} />
          <Row
            alignSelf='center'
            maxWidth='321px'
          >
            {!hideButton && (
              <Button
                background={getGlobalStyle('--color-base-black')}
                borderRadius={getGlobalStyle('--border-radius-lg')}
                label='Recargar'
                onClick={onReload}
                size='large'
                width={portViewSizes.buttonWidth}
              />
            )}
          </Row>
        </Column>
        {!isMobile && (
          <Column width='min-content'>
            <Picture
              height='369px'
              src='https://unimarcdev.vtexassets.com/arquivos/error-v2.jpg'
              width='320px'
            />
          </Column>
        )}
      </Container>
    </Container>
  )
}
