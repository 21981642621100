export const ERROR_400_IMG = {
  0: '6jS1S1EgPDysU79DKeRvWS'
}

export const ERROR_404_IMG = {
  0: '1mCxqNwobrC0dofjXgB8iW'
}

export const ERROR_500_IMG = {
  0: '7crW4vvsA502bswpmHoHo6'
}

export const ERROR_500_IMG_V2 = {
  0: '7crW4vvsA502bswpmHoHo6'
}

export const ERROR_500_IMG_BREAD = {
  0: '3Lwr0nMBxQuGHfYDaBUdzE'
}
