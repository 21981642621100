export const offerBannerValidator = (content: object): boolean => {
  return !(
    !content ||
    !content?.['endDate'] ||
    !content?.['image']?.['reference'] ||
    !content?.['image']?.['imageDesktop']?.['fields']?.['file']?.['url'] ||
    !content?.['image']?.['imageMobile']?.['fields']?.['file']?.['url'] ||
    !content?.['startDate']
  )
}
