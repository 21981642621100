import { Fragment } from 'react'
import {
  Column,
  Divider,
  Horizontal,
  MultipleSelector,
  MultipleSelectorOptions,
  Row,
  Vertical
} from '@smu-chile/pkg-unimarc-components'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'

export interface FilterSelectorProps {
  filters: Omit<MultipleSelectorOptions, 'onConfirmation'>[]
  handleQuery?: MultipleSelectorOptions['onConfirmation']
}

export const FilterSelector = ({
  filters,
  handleQuery
}: FilterSelectorProps) => {
  return (
    <BigScreen>
      <Column>
        <Divider verticalMargin={16} />
        <Row>
          {filters?.length > 0 &&
            filters
              .filter((filterValue) => {
                return filterValue.options.length > 0
              })
              .map((filterProps, index) => {
                return (
                  <Fragment key={filterProps.title}>
                    {index > 0 && <Vertical size={48} />}
                    <MultipleSelector
                      onConfirmation={handleQuery}
                      onlyOneChecked
                      {...filterProps}
                    />
                  </Fragment>
                )
              })}
        </Row>
        <Horizontal size={12} />
      </Column>
    </BigScreen>
  )
}
