export const normalizeString = (str: string) => {
  return str
    ?.normalize('NFD')
    ?.replace(/\?(.*)/gm, '')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/,/g, '')
    ?.replace(/\s/g, '-')
    .toLowerCase()
}

export const removeCharacter = (str: string, charToReplace: string) => {
  if (!str || !charToReplace) return str

  const regex = new RegExp(`\\${charToReplace}`, 'g')

  return str.replace(regex, ' ')
}
