import React, { useMemo } from 'react'
import {
  Collapsable,
  Column,
  RenderStringHtml,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IContentfulBasicEntry, ItemFields } from '@smu-chile/pkg-unimarc-hooks'
import { PlpDescriptionTitle } from './PlpDescriptionTitle'

interface PlpDescriptionProps {
  items: IContentfulBasicEntry[]
  isMobile?: boolean
}

export const PlpDescription = ({
  items,
  isMobile = false
}: PlpDescriptionProps): React.ReactElement => {
  const backgroundColor = isMobile ? 'light' : 'white'
  const plpDescriptions = useMemo(() => {
    return items.reverse()
  }, [items])

  const getCollapsableData = ({
    collapsableData
  }: {
    collapsableData: ItemFields
  }) => {
    const text = collapsableData?.html ? (
      <RenderStringHtml htmlString={collapsableData?.html} />
    ) : (
      collapsableData?.text?.content?.[0].content?.[0]?.value
    )

    return {
      text: text,
      title: collapsableData?.title
    }
  }

  return (
    <>
      <Column
        alignItems='center'
        backgroundColor={getGlobalStyle(
          `--color-neutral-gray-${backgroundColor}`
        )}
        justifyContent='center'
        padding='12px'
      >
        <Spacer.Horizontal size={18} />
        <Spacer.Horizontal size={2} />
        {plpDescriptions.map((plpDescription: ItemFields) => {
          if (!plpDescription?.title) return null

          return (
            <React.Fragment key={plpDescription?.title}>
              <PlpDescriptionTitle
                key={plpDescription.title}
                title={plpDescription.title}
              />
              {plpDescription?.components?.map(
                (collapsableData: ItemFields) => {
                  const { text, title } = getCollapsableData({
                    collapsableData
                  })
                  if (!text || !title) return null

                  return (
                    <Collapsable
                      key={title}
                      text={text}
                      title={title}
                    />
                  )
                }
              )}
            </React.Fragment>
          )
        })}
      </Column>
    </>
  )
}
