import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  DeliverySelectorType,
  ButtonStatus,
  TitleProps
} from '@smu-chile/pkg-unimarc-components'
import {
  cleanUrlParamsLogin,
  Cookies,
  eventClick,
  getCurrentDomain,
  getStoresAddress,
  isValidArrayWithData,
  postCoordsValidate,
  useEvents,
  useMobile,
  useOrderForm,
  useTrigerEvent,
  useUpdateAddress,
  removeRepeatedValues,
  useSession
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ModalConfirmChangeAddress } from 'components/ModalConfirmChangeAddress'
import { useLocalAddress } from 'shared/utils/hooks/useLocalAddress'

interface StoreAddress {
  addressId: string
  addressType: string
  city: string
  country: string
  formatedAddress: string
  geoCoordinates: Array<number>
  id: string
  name: string
  neighborhood: string
  number: string
  region: string
  state: string
  street: string
}

enum AddressType {
  Delivery = 'residential',
  Store = 'pickup-in-point'
}

const removeItem = (arr: string[], region: string): string[] => {
  return arr.filter((currentRegion) => {
    return currentRegion !== region
  })
}

export interface DeliverySelectorTypeComponentProps {
  confirmAddressModalImage: string
}

export const DeliverySelectorTypeComponent = ({
  confirmAddressModalImage
}: DeliverySelectorTypeComponentProps): React.ReactElement => {
  const router = useRouter()
  const { isLoggedIn } = useSession()
  const { isMobile } = useMobile()
  const [openCAC, setopenCAC] = useState(false)
  const [cacSelectedMethod, setcacSelectedMethod] = useState('')
  const [comuna, setComuna] = useState('')
  const [comunaList, setComunaList] = useState([])
  const [deliveryMethod, setDeliveryMethod] = useState(false)
  const [storeSelectorButtonStatus, setStoreSelectorButtonStatus] =
    useState<ButtonStatus>('disabled')
  const [CACSelectorButtonStatus, setCACSelectorButtonStatus] =
    useState<ButtonStatus>('disabled')
  const [openStoreSelector, setStoreSelector] = useState(false)
  const [regionList, setRegionList] = useState([])
  const [store, setStore] = useState([])
  const [storeList, setStoreList] = useState([])
  const [storeMethod, setStoreMethod] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [failModalColorTitle, setFailModalColorTitle] = useState('')
  const [failModalButtonText, setFailModalButtonText] = useState('')
  const [failModalContent, setFailModalContent] = useState('')
  const [failModalTitle, setFailModalTitle] = useState('')
  const [titlePosition, setTitlePosition] =
    useState<TitleProps['textAlign']>('center')
  const [contentPosition, setContentPosition] =
    useState<TitleProps['textAlign']>('center')
  const [windowParams, setWindowParams] = useState('')
  const [dontAllowCloseSL, setDontAllowCloseSL] = useState(false)
  const [hasProducts, setHasProducts] = useState(false)
  const [openModalConfirmChangeAddress, setOpenModalConfirmChangeAddress] =
    useState(false)

  const [storeSelected, setStoreSelected] = useState<StoreAddress>(null)
  const {
    handleAddressObject,
    data: updateData,
    isSuccess
  } = useUpdateAddress(router)
  const { trigger } = useTrigerEvent()
  const { data: orderFormData } = useOrderForm()

  // localAddress
  const { localOrderForm, saveLocalAddress } = useLocalAddress()

  useEvents({
    eventType: 'deliverySelector',
    callBack: ({ detail: { show, toStore, dontAllowCloseSL } }) => {
      const showWelcome = localStorage.getItem('SSO/showWelcome')
      const addressSelected = isLoggedIn
        ? orderFormData?.data?.selectedAddresses || ''
        : localOrderForm.data.selectedAddresses
      if (addressSelected && !toStore) {
        const isPickup = addressSelected?.addressType == 'pickup-in-point'
        if (isPickup) {
          setStoreSelectorButtonStatus('initial')
          cacStore(addressSelected?.addressId ?? '')
        } else {
          cacDelivery()
          setopenCAC(false)
        }
      } else {
        setStoreMethod(false)
        setDeliveryMethod(false)
        setcacSelectedMethod('')
        if (showWelcome === '"true"') {
          setopenCAC(false)
        } else {
          setopenCAC(show)
        }
        setCACSelectorButtonStatus('disabled')
        if (toStore !== undefined) {
          cacStore()
        }
      }
      setcacSelectedMethod('')
      setHasProducts(isValidArrayWithData(orderFormData?.data?.items))
      // if the value is truthy (!!) will convert it to "true" and if is falsy will convert it to "false"
      setDontAllowCloseSL(!!dontAllowCloseSL)
    }
  })

  // errors
  const [storesAddressError, setStoresAddressError] = useState(false)
  const [coordsValidateError, setCoordsValidateError] = useState(false)
  const [addressObjectError, setAddressObjectError] = useState(false)

  // store selector functions

  const findSelectedStore = storeList.findIndex((storeSelect) => {
    return storeSelect.selected === true
  })

  const storeSelector = (data, index) => {
    if (!data && !index) {
      setStoreSelected(null)
      setStoreSelectorButtonStatus('disabled')
      return
    }

    if (findSelectedStore !== -1) {
      storeList[findSelectedStore].selected = false
      storeList[index].selected = true
      setStoreList([...storeList])
    } else {
      if (storeList[index]) {
        storeList[index].selected = true
        setStoreList([...storeList])
      }
    }
    setStoreSelected(data)
    setStoreSelectorButtonStatus('initial')
  }

  /// save store
  const storeSelectorButton = async () => {
    const { data } = orderFormData || localOrderForm
    // show modal to confirm change address just if address selected is different
    if (
      hasProducts &&
      data?.selectedAddresses?.addressId !== storeSelected?.addressId
    ) {
      setHasProducts(false)
      setopenCAC(false)
      closeStoreSelector()
      return setOpenModalConfirmChangeAddress(true)
    }
    if (storeSelected) {
      const eventLabel = `Unimarc ${storeSelected.neighborhood}`.toLowerCase()
      eventClick({
        eventCategory: 'seleccion de metodo de entrega',
        eventLabel
      })
      setStoreSelectorButtonStatus('loading')

      const response = await postCoordsValidate({
        data: storeSelected.geoCoordinates
      })
      if (!response?.data?.error) {
        if (isLoggedIn) {
          handleAddressObject({
            ...storeSelected,
            salesChannel: response.data.sc,
            receiverName: storeSelected.name
          })
        } else {
          saveLocalAddress(
            {
              data: {
                ...storeSelected,
                salesChannel: response.data.sc,
                receiverName: storeSelected.name,
                addressType: 'pickup-in-point'
              }
            },
            router
          )
        }
      } else {
        setStoreSelectorButtonStatus('disabled')
        setCoordsValidateError(true)
      }
    }
    return false
  }

  useEffect(() => {
    if (isSuccess) {
      setOpenModalConfirmChangeAddress(false)
      const domain = getCurrentDomain()
      Cookies.set('showAddressChangedTooltip', 'true', {
        domain,
        path: '/'
      })
    }
  }, [isSuccess])

  useEffect(() => {
    if (updateData) {
      if (updateData?.data?.error) {
        setStoreSelectorButtonStatus('disabled')
        setAddressObjectError(true)
      } else {
        setStoreSelector(false)
        trigger({ eventType: 'deliverySelector', data: { show: false } })
        setStoreSelectorButtonStatus('disabled')
      }
    }
  }, [updateData])

  const deleteSelectedStore = () => {
    if (
      findSelectedStore !== -1 &&
      storeList[findSelectedStore].selected === true
    ) {
      storeList[findSelectedStore].selected = false
      setStoreList([...storeList])
    }
  }

  const regionSelector = (e) => {
    if (e.target.value === 'Seleccionar región') {
      setStoreList(store)
    } else {
      deleteSelectedStore()
      const filterStore = store.filter((elem) => {
        return elem.region === e.target.value
      })
      const comunaList = filterStore.map((elem) => {
        return elem.neighborhood
      })
      setComunaList(removeRepeatedValues(comunaList))
      setStoreList(filterStore)
      setStoreSelected(null)
      setStoreSelectorButtonStatus('disabled')
      setComuna('')
    }
  }

  const comunaSelector = (e) => {
    if (e.target.value === 'Seleccionar comuna') {
      setStoreList(store)
      setComuna('')
    } else {
      deleteSelectedStore()
      setComuna(e.target.value)
      const filterStore = store.filter((elem) => {
        return elem.neighborhood === e.target.value
      })
      setStoreList(filterStore)
      setStoreSelected(null)
      setStoreSelectorButtonStatus('disabled')
    }
  }

  // delivery selector functions
  const selectDeliveryMethod = () => {
    eventClick({
      eventCategory: 'interacciones en metodo de entrega',
      eventLabel: 'boton confirmar como quieres recibir tu compra'
    })
    if (!deliveryMethod) {
      setDeliveryMethod(!deliveryMethod)
      setStoreMethod(false)
      setcacSelectedMethod('residential')
      setCACSelectorButtonStatus('initial')
    }
  }

  const selectStoreMethod = () => {
    if (!storeMethod) {
      setStoreMethod(!storeMethod)
      setDeliveryMethod(false)
      setcacSelectedMethod('pickup-in-point')
      setCACSelectorButtonStatus('initial')
    }
  }

  const changeDeliveryMethod = async () => {
    trigger({
      eventType: 'storeLocator',
      data: { toCC: true, dontAllowCloseSL }
    })
    setStoreSelector(false)
  }

  const cacDelivery = () => {
    setopenCAC(!openCAC)
    trigger({ eventType: 'storeLocator', data: { show: true } })
  }

  const cacStore = async (addressId?: string) => {
    setWindowParams('')
    setCACSelectorButtonStatus('loading')
    const responseNewAddress = await getStoresAddress()
    if (!responseNewAddress.error) {
      const {
        data: {
          clientProfileData: { firstName, lastName }
        }
      } = orderFormData || localOrderForm
      const regionFormated: string[] = []
      const stores = []
      responseNewAddress.data.forEach((element) => {
        regionFormated.push(element.name)
        element.neighborhood.forEach((storeListed) => {
          storeListed.store.forEach((info) => {
            stores.push({
              addressId: info.addressId,
              addressType: info.addressType,
              city: info.city,
              country: info.country,
              formatedAddress: `${info.street} ${info.number}, ${info.neighborhood}`,
              geoCoordinates: info.geoCoordinates,
              id: info.id,
              neighborhood: info.neighborhood,
              number: info.number,
              region: element.name,
              selected: addressId ? addressId === info.addressId : false,
              state: info.state,
              storeName: storeListed?.name,
              name: `${firstName} ${lastName}`,
              street: info.street
            })
          })
        })
      })
      const finalRegion = removeItem(
        [...regionFormated].sort((regionA, regionB) => {
          return regionA.localeCompare(regionB)
        }),
        'Region Metropolitana'
      )
      finalRegion.unshift('Region Metropolitana')
      setRegionList(finalRegion)
      setStoreList(stores)
      setStore(stores)
      setopenCAC(false)
      setStoreSelector(!openStoreSelector)
      for (let index = 0; index < stores.length; index++) {
        const storeIterated = stores[index]
        if (storeIterated.selected) storeSelector(storeIterated, index)
      }
    } else {
      setStoresAddressError(true)
    }
  }

  const selectorMethodEventLabel = () => {
    return cacSelectedMethod === 'residential'
      ? 'despacho a domicilio'
      : 'retiro en tienda'
  }

  const cacSelectorButton = () => {
    eventClick({
      eventCategory: 'seleccion de la tienda',
      eventLabel: selectorMethodEventLabel()
    })
    if (!isLoggedIn) {
      if (cacSelectedMethod === AddressType.Delivery) {
        cacDelivery()
      }
      if (cacSelectedMethod === AddressType.Store) {
        cacStore()
      }
    } else if (orderFormData.data.selectedAddresses) {
      if (
        cacSelectedMethod !== orderFormData.data.selectedAddresses.addressType
      ) {
        setFailModal(!failModal)
        setFailModalColorTitle(getGlobalStyle('--color-primary-red'))
        setTitlePosition('left')
        setContentPosition('left')
        setFailModalTitle(
          '¿Estás seguro que quieres cambiar el tipo de entrega?'
        )
        setFailModalButtonText('Cambiar tipo de entrega')
        setFailModalContent(
          'Para cambiar el tipo de entrega, te redirigiremos a tus direcciones. Es posible que los productos que quieres comprar no se encuentren disponibles en la nueva ubicación o tienda.'
        )
      } else {
        if (
          orderFormData.data.selectedAddresses.addressType ===
          AddressType.Delivery
        ) {
          cacDelivery()
        }
        if (
          orderFormData.data.selectedAddresses.addressType === AddressType.Store
        ) {
          cacStore()
        }
      }
    } else {
      if (cacSelectedMethod === AddressType.Delivery) {
        cacDelivery()
      }
      if (cacSelectedMethod === AddressType.Store) {
        cacStore()
      }
    }
  }

  const errorModal = () => {
    setFailModal(!failModal)
  }

  const errorModalAction = () => {
    if (
      orderFormData.data.selectedAddresses.addressType === AddressType.Delivery
    ) {
      cacStore()
      setFailModal(!failModal)
    }
    if (
      orderFormData.data.selectedAddresses.addressType === AddressType.Store
    ) {
      cacDelivery()
      setFailModal(!failModal)
    }
  }

  // close functions
  const closeDeliverySelector = () => {
    setopenCAC(!openCAC)
    setStoreMethod(false)
    setDeliveryMethod(false)
    setcacSelectedMethod('')
    setDeliveryMethod(false)
    setStoreMethod(false)
    setcacSelectedMethod('')
  }

  const closedModalStore = () => {
    setStoreSelector(false)
    setStoreMethod(false)
    setDeliveryMethod(false)
    setcacSelectedMethod('')
    setDeliveryMethod(false)
    setStoreMethod(false)
    setcacSelectedMethod('')
  }

  const closeStoreSelector = () => {
    closedModalStore()
    setStoreSelectorButtonStatus('disabled')
  }

  // errors useEffect
  useEffect(() => {
    if (storesAddressError) {
      throw new Error('Error en la obtención de direcciones de tiendas')
    }
    if (coordsValidateError) {
      throw new Error('Error en la validación de coordenadas de tiendas')
    }
    if (addressObjectError) {
      throw new Error('Error en el seteo de tienda')
    }
  }, [storesAddressError, coordsValidateError, addressObjectError])

  useEffect(() => {
    if (orderFormData?.data) {
      if (windowParams === 'store') {
        setcacSelectedMethod('pickup-in-point')
        cacStore()
      }

      if (windowParams === 'delivery') {
        setcacSelectedMethod('residential')
        trigger({ eventType: 'storeLocator', data: { show: true } })
      }
      if (windowParams === '?sl=true') {
        setopenCAC(true)
      }
    }
  }, [orderFormData])

  useEffect(() => {
    if (window !== undefined) {
      const locationSearch = router.query?.['delivery-type'] || ''
      const hasSl = router.query?.['sl'] || ''

      if (locationSearch === 'store') {
        setcacSelectedMethod('pickup-in-point')
        cacStore()
        setWindowParams(locationSearch)
      }
      if (locationSearch === 'delivery') {
        setcacSelectedMethod('residential')
        trigger({ eventType: 'storeLocator', data: { show: true } })
        setWindowParams(locationSearch)
      }
      if (hasSl === 'true') {
        setopenCAC(true)
        setWindowParams('?sl=true')
      }
      const paramsToDelete = ['delivery-type', 'sl']
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search)
      )
      paramsToDelete.forEach((param) => {
        delete params?.[param]
      })
      const searchParams =
        Object.entries(params).length > 0
          ? '?' + new URLSearchParams(params).toString()
          : ''
      const url =
        window.location.origin + window.location.pathname + searchParams

      router.replace(url)
    }
  }, [typeof window])

  useEffect(() => {
    cleanUrlParamsLogin({
      router,
      ignoreItems: []
    })
  }, [])

  const deliverySelectorTypeProps = {
    CACSelectorButtonStatus,
    comunaList,
    comunaSelected: comuna,
    contentPosition,
    dontAllowCloseSL,
    enabledDeliverySelector: deliveryMethod,
    enabledStoreSelector: storeMethod,
    failModalButtonText,
    failModalColorTitle,
    failModalContent,
    failModalIsOpen: failModal,
    failModalTitle,
    openDeliverySelector: openCAC,
    openStoreSelector,
    regionList,
    storeList,
    storeSelectorButtonStatus,
    titlePosition,
    activeDeliverySelector: selectDeliveryMethod,
    activeStoreSelector: selectStoreMethod,
    cacSelectorButton,
    changeDeliveryMethod,
    closeDeliverySelector,
    closeStoreSelector,
    comunaSelector,
    errorModal,
    errorModalAction,
    regionSelector,
    storeSelector,
    storeSelectorButton
  }

  return (
    <>
      <ModalConfirmChangeAddress
        confirmAddressModalImage={confirmAddressModalImage}
        confirmUserAddress={storeSelectorButton}
        isMobile={isMobile}
        openModalConfirmChangeAddress={openModalConfirmChangeAddress}
        setOpenModalConfirmChangeAddress={setOpenModalConfirmChangeAddress}
      />
      <DeliverySelectorType {...deliverySelectorTypeProps} />
    </>
  )
}
