import NextLink from 'next/link'
import { Link, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { capitalize } from 'shared/helpers'

export interface LinkComponentPropsV2 {
  categoryLevel2?: boolean
  categoryLevel3?: boolean
  key?: string | number
  label: string
  url: string
  urlEndsWith?: string
  value: string
  setSelectedCategory?: (type: string) => void
  handleClickPill?: (props: { [key: string]: unknown }) => void
}

export const LinkComponentV2 = ({
  categoryLevel2,
  categoryLevel3,
  label,
  url,
  urlEndsWith,
  value,
  setSelectedCategory
}: LinkComponentPropsV2) => {
  const categoryIsSelected = value === urlEndsWith

  const categoryLevel3Change = categoryLevel3
    ? `/${url.slice(0, url.lastIndexOf(urlEndsWith))}/${value}`
    : `${url}/${value}`
  const hrefToSend = categoryIsSelected
    ? url.slice(0, url.lastIndexOf(urlEndsWith))
    : categoryLevel3Change

  const textColor = categoryIsSelected && categoryLevel3 ? 'light-red' : 'gray'
  const textWeight =
    categoryIsSelected && categoryLevel3 ? 'semibold' : 'regular'

  const secondLine = label.length >= 17

  const handleOnClick = () => {
    if (!categoryLevel2) setSelectedCategory(label)
  }

  return (
    <Row
      alignItems='center'
      justifyContent='center'
      maxHeight={secondLine ? '35px' : ''}
      maxWidth={secondLine ? '120px' : 'fit-content'}
      minHeight='35px'
      minWidth={secondLine ? '120px' : 'max-content'}
      padding='5px 8px'
    >
      <Link
        href={hrefToSend}
        onClick={handleOnClick}
        preventDefault={false}
        underline='none'
        wrapper={NextLink}
      >
        <Text
          color={textColor}
          fontSize='sm'
          fontWeight={textWeight}
          lineClamp={2}
          lineHeight={secondLine ? '16px' : ''}
          textAlign='center'
        >
          {capitalize(label)}
        </Text>
      </Link>
    </Row>
  )
}
