import { MutableRefObject, useEffect } from 'react'
import { ScrollData } from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/helpers/useScrollListener'
import styles from '../../CustomHeader.module.css'

type UseCustomHeaderProps<T extends HTMLElement> = {
  isMobile: boolean
  innerWidth: number
  scroll: ScrollData
  primary: MutableRefObject<T>
}

export const useCustomHeader = <T extends HTMLElement>({
  isMobile,
  innerWidth,
  scroll,
  primary
}: UseCustomHeaderProps<T>) => {
  useEffect(() => {
    const isScrollingUp = scroll.lastY > scroll.y
    const isScrollingDown = scroll.lastY < scroll.y
    let classToAdd = ''
    let classToRemove = ''

    if (isMobile || innerWidth <= 768) {
      // add or remove fixed class based on scroll position
      if (isScrollingUp) {
        classToAdd = styles['customHeader']
        classToRemove = styles['customHeaderDown']
      } else if (isScrollingDown) {
        classToAdd = styles['customHeaderDown']
        classToRemove = styles['customHeader']
      }
    }
    // apply the styles
    if (classToAdd) {
      primary.current?.classList.add(classToAdd)
    }
    if (classToRemove) {
      primary.current?.classList.remove(classToRemove)
    }
    if (primary.current) {
      primary.current.style.top = styles['customHeader']
    }
  }, [scroll.y, scroll.lastY, isMobile])
}
