import {
  breadcrumbJoin,
  dataLayerProductEvent,
  datalayerSimpleEvent,
  productProps
} from '@smu-chile/pkg-unimarc-hooks'

const handlePdpTags = ({ product, quantity, orderForm }) => {
  const site = 'Unimarc'
  const listId = 'PDP_' + breadcrumbJoin.replace(/\//g, '_')
  const listName = 'PDP-' + breadcrumbJoin.replace(/\//g, '-')
  const salesChannel = orderForm?.['salesChannel'] || '1'
  const store = orderForm?.['salesChannel']
    ? `${orderForm?.['salesChannel']} ${site} - ${orderForm?.['selectedAddresses']?.['neighborhood']}`
    : ''
  const orderformId = orderForm?.['orderFormId']
  const items = [
    productProps({
      product,
      listId,
      listName,
      quantity
    })
  ]
  dataLayerProductEvent({
    assigned_store: store,
    event: 'view_item',
    items,
    orderformId,
    saleschannel: salesChannel || process.env.NEXT_PUBLIC_SALES_CHANNEL || '1'
  })
}

const clickPlp = (labelVal: string | number, event: string) => {
  const clickedText = labelVal == '/' ? 'Inicio' : labelVal
  const category = breadcrumbJoin?.search(/búsqueda/i) == -1 ? 'PLP' : 'SRP'
  datalayerSimpleEvent({
    event,
    eventCategory: event == 'breadcrumb' ? breadcrumbJoin : category,
    eventLabel: clickedText.toString()
  })
}

export { clickPlp, handlePdpTags }
