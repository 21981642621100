import { useEffect } from 'react'
import { useOnScreen } from '@smu-chile/pkg-unimarc-hooks'

interface FetchMoreInteractionsProps {
  render?: React.ReactElement
  fetchMore?: boolean
  callback?: () => void
}
export const LoadingInfinityScroll = ({
  render,
  fetchMore = true,
  callback = () => {
    return
  }
}: FetchMoreInteractionsProps) => {
  const { elementRef, isVisibleChild } = useOnScreen({
    oneChild: true,
    rootMargin: '25px'
  })

  useEffect(() => {
    if (isVisibleChild && fetchMore) callback()
  }, [isVisibleChild, fetchMore])

  return (
    <div ref={elementRef}>
      <div>{isVisibleChild ? render : null}</div>
    </div>
  )
}
