import { slugify } from 'shared/helpers/slugify'
import { ClubBlockFaq } from './ClubBlockFaq'
import { ClubBlockDiscounts } from './ClubBlockDiscounts'
import { MembershipBlock } from 'components/MembershipBlock'
import { MEMBERSHIP_STATUS } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { IMembershipBlock } from 'shared/interfaces/IMembership'
import { ClubBlockBannersTabs } from './ClubBlockBannersTabs/ClubBlockBannersTabs'

export enum ClubBlockIds {
  BotonesMembresia = 'Botones membresia',
  CarruselDescuentosPorNivel = 'Carrusel descuentos por nivel',
  DescuentosPorNivelDesktop = 'Descuentos por nivel desktop',
  DescuentosPorNivelInvitadoDesktop = 'Descuentos por nivel invitado desktop',
  DescuentosPorNivelInvitadoMobile = 'Descuentos por nivel invitado mobile',
  DescuentosPorNivelMobile = 'Descuento por nivel mobile',
  PreguntasFrecuentes = 'Preguntas frecuentes',
  MembershipButtons = 'Botones Membresía'
}

export type ClubBlockProps = {
  blockId?: string
  description?: string
  isMobile?: boolean
  items?: unknown[]
  membershipData?: IMembershipBlock
  membershipLevel?: string
}

export const ClubBlock = ({
  blockId,
  description,
  items,
  isMobile,
  membershipData,
  membershipLevel
}: ClubBlockProps) => {
  const isBlockId = (value: string) => {
    return slugify(value) === slugify(blockId)
  }

  const bannersTabsProps = () => {
    return {
      items,
      title: description
    }
  }

  return (
    <>
      {blockId === ClubBlockIds.PreguntasFrecuentes && (
        <ClubBlockFaq
          description={description}
          items={items}
        />
      )}

      {(isBlockId(ClubBlockIds.CarruselDescuentosPorNivel) ||
        isBlockId(ClubBlockIds.DescuentosPorNivelDesktop) ||
        isBlockId(ClubBlockIds.DescuentosPorNivelMobile)) && (
        <ClubBlockDiscounts
          description={description}
          items={items}
        />
      )}

      {(isBlockId(ClubBlockIds.MembershipButtons) ||
        isBlockId(ClubBlockIds.BotonesMembresia)) &&
        membershipData &&
        membershipLevel !== MEMBERSHIP_STATUS.ACTIVE && (
          <>
            <MembershipBlock
              buttonLabel={membershipData?.buttonLabel}
              isMobile={isMobile}
              labelColorBackground={membershipData?.backgroundColor}
              labelColorText={membershipData?.textColor}
              labelIcon={membershipData?.icon}
              labelText={membershipData?.labelText}
              subtitle={membershipData?.subtitle}
              title={membershipData?.title}
            />
          </>
        )}

      {isBlockId(ClubBlockIds.DescuentosPorNivelInvitadoDesktop) && (
        <ClubBlockBannersTabs {...bannersTabsProps()} />
      )}

      {isBlockId(ClubBlockIds.DescuentosPorNivelInvitadoMobile) && (
        <ClubBlockBannersTabs {...bannersTabsProps()} />
      )}

      {/** add here new blocks by block-id */}
    </>
  )
}
