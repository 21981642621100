import {
  Button,
  Column,
  Container,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Image } from '@smu-chile/pkg-unimarc-components/stories/organisms/AddToListModal/Image'
import { trigger } from '@smu-chile/pkg-unimarc-hooks'

interface MembershipBlockProps {
  buttonLabel?: string
  isMobile?: boolean
  labelColorBackground?: string
  labelColorText?: string
  labelIcon?: string
  labelText?: string
  title?: string
  subtitle?: string
}

export const MembershipBlock = ({
  buttonLabel,
  isMobile,
  labelColorBackground,
  labelColorText,
  labelIcon,
  labelText,
  title,
  subtitle
}: MembershipBlockProps) => {
  const handleClick = () => {
    trigger({
      eventType: 'openMembershipModal',
      data: { eventContext: 'Club Unimarc' }
    })
  }
  return (
    <Row
      alignItems={isMobile ? 'end' : 'center'}
      backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
      borderRadius={getGlobalStyle('--border-radius-md')}
      gap='10px'
      height={isMobile ? '' : '98px'}
      padding='10px 12px'
      width={isMobile ? '' : '100%'}
    >
      <Column gap='12px'>
        <Row
          alignItems='center'
          backgroundColor={labelColorBackground}
          borderRadius={getGlobalStyle('--border-radius-full')}
          gap='5px'
          id={getBemId('membershipBlock', 'tag')}
          padding='3px 5px'
          width='auto'
        >
          <Container
            margin='-2px 0 0'
            width='12px'
          >
            <Image
              altPicture='Icono Socio'
              customSize='12px'
              mobileCustomSize='12px'
              srcPicture={labelIcon}
            />
          </Container>
          <Text
            customColor={labelColorText}
            fontSize='sm'
            fontWeight='medium'
          >
            {labelText}
          </Text>
        </Row>
        <Column
          gap='2px'
          id={getBemId('membershipBlock', 'text')}
          margin='0 0 4px'
        >
          <Text
            fontSize={isMobile ? 'lg' : 'xl'}
            fontWeight={isMobile ? 'bold' : 'semibold'}
          >
            {title}
          </Text>
          {!isMobile ? (
            <Text
              fontSize={isMobile ? 'sm' : 'md'}
              fontWeight='regular'
            >
              {subtitle}
            </Text>
          ) : (
            <Row
              alignItems='end'
              justifyContent='between'
            >
              <Text
                customColor={getGlobalStyle('--color-neutral-gray-dark')}
                fontSize={isMobile ? 'sm' : 'md'}
                fontWeight='regular'
              >
                {subtitle}
              </Text>

              <Button
                borderRadius={getGlobalStyle('--border-radius-full')}
                fontSize={getGlobalStyle(
                  isMobile ? '--font-size-sm' : '--font-size-md'
                )}
                fontWeight={getGlobalStyle('--font-weight-medium')}
                height='auto'
                id={getBemId('membershipBlock', 'button')}
                label={buttonLabel}
                lineHeight='18px'
                minWidth={isMobile ? '135px' : '234px'}
                onClick={handleClick}
                padding={6}
                type='secondary'
              />
            </Row>
          )}
        </Column>
      </Column>
      {!isMobile && (
        <Button
          borderRadius={getGlobalStyle('--border-radius-full')}
          fontSize={getGlobalStyle(
            isMobile ? '--font-size-sm' : '--font-size-md'
          )}
          fontWeight={getGlobalStyle('--font-weight-medium')}
          height='32px'
          id={getBemId('membershipBlock', 'button')}
          label={buttonLabel}
          minWidth={isMobile ? '135px' : '234px'}
          onClick={handleClick}
          type='secondary'
        />
      )}
    </Row>
  )
}
