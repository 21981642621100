import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  Backbone,
  Column,
  Container,
  Icon,
  QuantityButton,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { Item } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMiniCart'
import { Image } from './ProductDetails/Image'
import { Name } from './ProductDetails/Name'
import { Price } from './ProductDetails/Price'
import { updateProductQuantity, useEvents } from '@smu-chile/pkg-unimarc-hooks'

const getShowValue = (item: Item, quantity: number): string => {
  let showValue = quantity.toString()

  if (item.measurementUnit != 'un') {
    const multipleForTen =
      parseFloat(
        (quantity * item.unitMultiplier)
          ?.toFixed(2)
          ?.toString()
          ?.split('.')?.[1]
      ) %
        10 ===
      0
    const showOneORtwoDec = multipleForTen ? 1 : 2
    showValue = `${parseFloat(
      (quantity * item.unitMultiplier).toString()
    ).toFixed(showOneORtwoDec)} ${item.measurementUnit}`
  }
  return showValue
}

const Shelf = (
  {
    item,
    handleOnSubmit,
    catchShelfRef
  }: {
    item: Item
    seller?: string
    handleOnSubmit: (props?: object) => void
    catchShelfRef?: (props?: object) => void
  },
  ref?: React.LegacyRef<HTMLInputElement>
): React.ReactElement => {
  const tempCartLimit =
    item?.cartLimit && item.cartLimit !== 0 ? item.cartLimit : 100
  const site = 'Unimarc'
  const { id } = item
  const [quantity, setQuantity] = useState(item.quantity)
  const [prevQuantity, setPrevQuantity] = useState(item.quantity)
  const [isLoadingQuantity, setIsLoadingQuantity] = useState(false)
  const [isLoadingMutate, setIsLoadingMutate] = useState(false)
  const [inputQuantity, setInputQuanity] = useState(false)
  const showValue = getShowValue(item, quantity)
  const isCartLimit: boolean = quantity >= tempCartLimit
  const isUnitOrHeavy =
    typeof showValue === 'string' && /kg/.test(showValue) ? 'kg' : 'unidades'
  const maxUnitByProduct: string =
    quantity >= tempCartLimit ? `Máximo x ${isUnitOrHeavy}: ${showValue}` : ''
  const unitPrice = item?.sellingPrice
  const [shelfRef, setShelfRef] =
    useState<React.ForwardedRef<HTMLInputElement>>()

  const handleColVal = useMemo(() => {
    shelfRef && catchShelfRef && catchShelfRef(shelfRef)
    return true
  }, [shelfRef])

  const updateProductQuantityTag = () => {
    if (quantity === prevQuantity) return

    updateProductQuantity({
      cartPrev: prevQuantity,
      cartRes: quantity,
      inputQuantity: inputQuantity,
      productId: item.productId,
      site
    })
    setPrevQuantity(quantity)
  }

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      if (loading && items?.length) {
        const countItems = items.find((element: Item) => {
          return element.id == id
        })

        if (countItems) {
          setIsLoadingQuantity(loading)
          setQuantity(
            countItems?.quantity > tempCartLimit
              ? tempCartLimit
              : countItems?.quantity
          )
        }
      } else if (!loading) {
        updateProductQuantityTag()
        setInputQuanity(false)
        const validQuantity =
          quantity > item?.quantity ? item?.quantity : quantity
        setQuantity(
          validQuantity > tempCartLimit ? tempCartLimit : validQuantity
        )
        setIsLoadingQuantity(loading)
      }

      setIsLoadingMutate(loading)
    }
  })

  const sumQuantity = () => {
    setQuantity(quantity + 1)
  }

  const restQuantity = () => {
    setQuantity(quantity - 1)
  }

  const changeQuantity = (element) => {
    setInputQuanity(true)
    const elementVal = parseInt(element.target.value)
    setQuantity(elementVal)
  }

  useEffect(() => {
    const maxQuantityByCartlimit =
      quantity > tempCartLimit ? tempCartLimit : quantity
    handleOnSubmit({ quantity: maxQuantityByCartlimit, item })
  }, [quantity])

  useEffect(() => {
    setQuantity(item.quantity)
  }, [item.quantity])

  if (quantity == 0) {
    updateProductQuantityTag()
    return (
      <Column>
        <Row>
          <Backbone height={90} />
        </Row>
        <Spacer.Horizontal size={16} />
      </Column>
    )
  }

  return (
    <Column
      ref={(columnRef: React.ForwardedRef<HTMLInputElement>) => {
        if (!ref && columnRef) ref = columnRef
        setShelfRef(columnRef)
      }}
      val={handleColVal}
    >
      <Container
        alignItems='center'
        backgroundColor={getGlobalStyle('--color-base-white')}
        borderRadius={getGlobalStyle('--border-radius-md')}
        isWrap
        maxHeight='163px'
        minWidth='min(100%, 344px)'
        padding='16px'
        position='relative'
        tagName='article'
      >
        <Row maxWidth='100%'>
          {/* Image [Mobile] */}
          <Column
            maxWidth='80px'
            minWidth='80px'
          >
            <Image
              altPicture={item.name}
              srcPicture={item.image}
            />
          </Column>
          <Spacer.Horizontal size={8} />
          <Column
            maxWidth='224px'
            minWidth='224px'
          >
            {/* Brand & Name [Mobile] */}
            <Row isWrap>
              <Name
                brand={item?.brandName}
                measurement={`${item?.unitMultiplier} ${item.measurementUnit}`}
                name={item.name}
              />
            </Row>
            <Spacer.Horizontal size={4} />
            <Spacer.Horizontal size={2} />
            <Row
              alignItems='end'
              justifyContent='between'
            >
              {/* Price [Mobile] */}
              <Column customWidth='42'>
                <Price price={unitPrice} />
              </Column>
              {/* Quantity Button [Mobile] */}
              <Column
                alignItems='start'
                maxWidth='120px'
              >
                <QuantityButton
                  alert={maxUnitByProduct}
                  disabled={isLoadingMutate}
                  disabledPlus={isCartLimit}
                  handleAdd={sumQuantity}
                  handleChange={changeQuantity}
                  handleRemove={restQuantity}
                  isEditable={false}
                  isLoading={isLoadingQuantity}
                  maxQuantity={tempCartLimit}
                  maxWidth='120px'
                  quantity={quantity}
                  quantityButtonStyles={{
                    maxHeight: '34px',
                    maxWidth: '130px',
                    minWidth: '130px',
                    maxHeightIcon: '34px',
                    sizeCircle: 18,
                    fontSize: 'sm',
                    padding: '0px 2px'
                  }}
                  value={showValue}
                />
              </Column>
            </Row>
          </Column>
          {/* Delete Button [Mobile] */}
          <Container
            absoluteDefault='topRight'
            alignItems='start'
            justifyContent='end'
            maxWidth='40px'
            onClick={() => {
              return setQuantity(0)
            }}
            padding='16px'
            position='absolute'
          >
            <Icon
              clickable='pointer'
              color={getGlobalStyle('--color-icons-gray')}
              customSize={10}
              name='Close'
            />
          </Container>
        </Row>
      </Container>
    </Column>
  )
}

const MemoShelf = memo(Shelf)

export { MemoShelf }
