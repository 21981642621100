export const handleUserName = (
  isLoggedIn: boolean,
  firstName: string,
  lastName: string
) => {
  if (isLoggedIn) {
    if (!firstName) return 'Mi Cuenta'
    const username = !lastName ? firstName : `${firstName} ${lastName}`
    if (username.length > 12) return `${username.slice(0, 12)}...`
    return username
  }
  return undefined
}
