import {
  Column,
  Container,
  Row,
  Spacer,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { ClubFeatureProps } from './ClubFeature'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  getContentfulFileUrl,
  isValidArrayWithData,
  trigger,
  triggerLoginEvent,
  useSession
} from '@smu-chile/pkg-unimarc-hooks'
import { OnlyOneBanner } from '@smu-chile/pkg-unimarc-components/stories/organisms/Banners/OnlyOneBanner'
import { MEMBERSHIP_ID } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

export type ClubFeatureCollageProps = ClubFeatureProps

export const ClubFeatureCollage = ({
  imageBanner,
  title,
  subtitle
}: ClubFeatureCollageProps) => {
  const { isLoggedIn, isLoading } = useSession()
  const hasHeader = !!subtitle || !!title

  const reduceBanners = () => {
    const banners: unknown[] = []

    for (let i = 0; i < imageBanner.length; i += 3) {
      banners.push(imageBanner.slice(i, 3))
    }

    return banners
  }

  const bannersCollege = reduceBanners()

  const handleClickBanner = async (promosData) => {
    if (promosData?.['fields']?.promotionId === MEMBERSHIP_ID.BANNER_HOME) {
      if (!isLoading && isLoggedIn) {
        trigger({
          eventType: 'openMembershipModal',
          data: { eventContext: 'Club Unimarc' }
        })
      } else {
        triggerLoginEvent({ eventType: 'loginModal', data: { show: true } })
      }
    }
  }

  return (
    <Container id={getBemId('clubFeatureCollage', 'container')}>
      {/** desktop view */}
      <BigScreen>
        <Column>
          <Column alignItems='center'>
            {title && (
              <Title
                fontWeight='medium'
                headingLevel='h1'
                id={getBemId('clubFeatureCollage', 'title')}
              >
                {title}
              </Title>
            )}

            {subtitle && <Text lineHeight='24px'>{subtitle}</Text>}
          </Column>

          {hasHeader && <Spacer.Horizontal customSize={40} />}

          <Container>
            <Column>
              {isValidArrayWithData(bannersCollege) &&
                bannersCollege.map(([primary, secondary, tertiary]) => {
                  return (
                    <Row
                      alignItems='center'
                      gap={14}
                      key={primary?.['sys']?.['id']}
                    >
                      {primary && (
                        <Container
                          id={getBemId('clubFeatureCollage', 'bannerPrimary')}
                          maxWidth='496px'
                          onClick={() => {
                            return handleClickBanner(primary)
                          }}
                        >
                          <OnlyOneBanner
                            banner={getContentfulFileUrl(primary?.['image'])}
                            url={primary?.['reference']}
                          />
                        </Container>
                      )}

                      {secondary && (
                        <Column gap={16}>
                          <Container
                            id={getBemId(
                              'clubFeatureCollage',
                              'bannerSecondary'
                            )}
                            maxWidth='610px'
                            onClick={() => {
                              return handleClickBanner(secondary)
                            }}
                          >
                            <OnlyOneBanner
                              banner={getContentfulFileUrl(
                                secondary?.['image']
                              )}
                              url={secondary?.['reference']}
                            />
                          </Container>

                          {tertiary && (
                            <Container
                              id={getBemId(
                                'clubFeatureCollage',
                                'bannerTertiary'
                              )}
                              maxWidth='610px'
                              onClick={() => {
                                return handleClickBanner(tertiary)
                              }}
                            >
                              <OnlyOneBanner
                                banner={getContentfulFileUrl(
                                  tertiary?.['image']
                                )}
                                url={tertiary?.['reference']}
                              />
                            </Container>
                          )}
                        </Column>
                      )}
                    </Row>
                  )
                })}
            </Column>
          </Container>
        </Column>
      </BigScreen>

      {/** mobile view */}
      <SmallScreen>
        <Column>
          <Column>
            {title && (
              <Text
                fontSize='lg'
                fontWeight='medium'
                id={getBemId('clubFeatureCollage', 'title')}
                lineHeight='22px'
              >
                {title}
              </Text>
            )}

            {subtitle && <Text>{subtitle}</Text>}
          </Column>

          {hasHeader && <Spacer.Horizontal customSize={24} />}

          <Column>
            {isValidArrayWithData(bannersCollege) &&
              bannersCollege.map(([primary, secondary, tertiary]) => {
                return (
                  <Row
                    alignItems='center'
                    gap={10}
                    key={primary?.['sys']?.['id']}
                  >
                    {primary && (
                      <Container
                        id={getBemId('clubFeatureCollage', 'bannerPrimary')}
                        onClick={() => {
                          return handleClickBanner(primary)
                        }}
                      >
                        <OnlyOneBanner
                          banner={getContentfulFileUrl(primary?.['image'])}
                          url={primary?.['reference']}
                        />
                      </Container>
                    )}

                    {secondary && (
                      <Column gap={10}>
                        <Container
                          id={getBemId('clubFeatureCollage', 'bannerSecondary')}
                          onClick={() => {
                            return handleClickBanner(secondary)
                          }}
                        >
                          <OnlyOneBanner
                            banner={getContentfulFileUrl(secondary?.['image'])}
                            url={secondary?.['reference']}
                          />
                        </Container>

                        {tertiary && (
                          <Container
                            id={getBemId(
                              'clubFeatureCollage',
                              'bannerTertiary'
                            )}
                            onClick={() => {
                              return handleClickBanner(tertiary)
                            }}
                          >
                            <OnlyOneBanner
                              banner={getContentfulFileUrl(tertiary?.['image'])}
                              url={tertiary?.['reference']}
                            />
                          </Container>
                        )}
                      </Column>
                    )}
                  </Row>
                )
              })}
          </Column>
        </Column>
      </SmallScreen>
    </Container>
  )
}
