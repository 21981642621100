import { NextRouter } from 'next/router'
import { matchOptionValue } from '.'

export interface HandleOrderByProps {
  router: NextRouter
  place?: string
}

export const handleOrderBy = ({ router, place }: HandleOrderByProps) => {
  return (orderByName: string): void => {
    const { query, asPath } = router
    const pathname = asPath.split('?')[0]
    if (place === 'PLP') {
      delete query.categories
    }
    delete query.offers

    const currentOrderByValue = query?.orderBy as string
    const newOrderByValue = matchOptionValue(orderByName)

    if (currentOrderByValue !== newOrderByValue) {
      delete query.page

      if (newOrderByValue === '') {
        delete query.orderBy
      } else {
        query.orderBy = newOrderByValue
      }

      router.push({ pathname, query }, undefined, { shallow: true })
    }
  }
}
