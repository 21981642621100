/*OrderByNameASC (ordena por nombre ascendete),
OrderByNameDESC (ordena por nombre descendente),
OrderByPriceASC (orden por precio ascendete),
OrderByPriceDESC (orden por precio descendente),
OrderByTopSaleDESC (ordena por los productos mas vendidos),
OrderByReviewRateDESC (ordena por mejores reseñas),
OrderByReleaseDateDESC (ordena por fecha de lanzamiento),
OrderByBestDiscountDESC (Ordena por mejores descuentos),
OrderByScoreDESC (Ordena por puntaje del producto).*/

export const orderByOptions = [
  { orderKey: '', value: 'Recomendados' },
  { orderKey: 'OrderByPriceASC', value: 'Precio más bajo' },
  { orderKey: 'OrderByPriceDESC', value: 'Precio más alto' },
  { orderKey: 'OrderByTopSaleDESC', value: 'Más vendidos' },
  { orderKey: 'OrderByBestDiscountDESC', value: 'Mejor descuento' }
]

export const matchOptionValue = (orderByValue: string): string => {
  return orderByOptions.filter((orderValue) => {
    return orderValue?.value === orderByValue
  })?.[0]?.['orderKey']
}

export const matchOptionKey = (orderByValue: string): string => {
  return orderByOptions.filter((orderValue) => {
    return orderValue?.orderKey === orderByValue
  })?.[0]?.['orderKey']
}

export const returnMatchedKeyName = (orderByValue: string): string => {
  return (
    orderByOptions.filter((orderValue) => {
      return orderValue?.orderKey === orderByValue
    })?.[0]?.['value'] || ''
  )
}
