import { Text } from '@smu-chile/pkg-unimarc-components'

export interface PriceProps {
  price: string | number
}

export const Price = ({
  price
}: PriceProps): React.ReactElement<PriceProps> => {
  return (
    <Text
      color='guardsman-red'
      fontSize='md'
      fontWeight='semibold'
    >{`${price} c/u`}</Text>
  )
}
