import { ReactChild } from 'react'
import { Modal } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface CouponDetailModalProps {
  children?: ReactChild
  onToggle?: () => void
  open: boolean
}

export const CouponDetailModal = ({
  children,
  onToggle,
  open
}: CouponDetailModalProps) => {
  const handleModalToggle = () => {
    if (onToggle) {
      onToggle()
    }
  }

  return (
    <Modal
      backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
      iconProps={{ customSize: 20 }}
      isOpen={open}
      level='1'
      maxWidth='1158px'
      padding='32px'
      toggle={handleModalToggle}
    >
      {children}
    </Modal>
  )
}
