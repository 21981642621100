import { useEffect, useRef } from 'react'
import {
  AddToListModal,
  Column,
  Container
} from '@smu-chile/pkg-unimarc-components'
import {
  ISelectedList,
  ListData
} from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { datalayerSimpleEvent, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { TooltipAddOrderToList } from './components/TooltipAddOrderToList'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import useScrollListener from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/helpers/useScrollListener'
import styles from './components/TooltipAddOrderToList/TooltipAddOrderToList.module.css'

export interface AddToListModalWrapperProps {
  createListButton: boolean
  emptyOrangeImage?: string
  innerWidth: number
  listData: ListData
  notificationAddToList: boolean
  openModalCreateList: boolean
  selectedListName: string
  handleAddProductsToList: (selected: ISelectedList) => void
  handleCreateNewList: (value: string, selected: ISelectedList) => void
  handleOnClosed: () => void
  setCreateListButton: (type: boolean) => void
  setNotificationAddToList: (type: boolean) => void
  setOpenModalCreateList: (type: boolean) => void
}

export const AddToListModalWrapper = ({
  createListButton,
  emptyOrangeImage,
  innerWidth,
  listData,
  notificationAddToList,
  openModalCreateList,
  selectedListName,
  handleAddProductsToList,
  handleCreateNewList,
  handleOnClosed,
  setCreateListButton,
  setNotificationAddToList,
  setOpenModalCreateList
}: AddToListModalWrapperProps) => {
  const { isMobile } = useMobile()
  const scroll = useScrollListener()
  const tooltipContainer = useRef(null)

  useEffect(() => {
    if (openModalCreateList === true) {
      datalayerSimpleEvent({
        event: 'interacciones_mis_listas',
        eventAction: 'view',
        eventCategory: 'inicio_mis_listas',
        eventLabel: 'modal_nueva_lista'
      })
    }
  }, [openModalCreateList])

  useEffect(() => {
    if (!isMobile) {
      if (scroll.y > scroll.lastY) {
        tooltipContainer?.current?.classList?.remove(styles['hide__tootip'])
        tooltipContainer?.current?.classList?.add(styles['default__tooltip'])
      } else {
        tooltipContainer?.current?.classList?.remove(styles['default__tooltip'])
        tooltipContainer?.current?.classList?.add(styles['hide__tooltip'])
      }
    }
  }, [scroll.y, scroll.lastY])

  return (
    <Container
      position='relative'
      width={getGlobalStyle('--width-max-desktop')}
    >
      {notificationAddToList && (
        <Column
          absoluteDefault='topRight'
          left='0'
          margin='0 auto'
          maxWidth={getGlobalStyle('--width-max-desktop')}
          position='fixed'
          ref={tooltipContainer}
          right='0'
          top={isMobile ? '103' : '80'}
          zIndex={getGlobalStyle('--z-index-999')}
        >
          <TooltipAddOrderToList
            createListButton={createListButton}
            isMobile={isMobile}
            onClose={handleOnClosed}
            selectedListName={selectedListName}
            setNotificationAddToList={setNotificationAddToList}
          />
        </Column>
      )}
      <AddToListModal
        createListButton={createListButton}
        emptyOrangeImage={emptyOrangeImage}
        handleAddProductsToList={handleAddProductsToList}
        handleCreateNewList={handleCreateNewList}
        innerWidth={innerWidth}
        isMobile={isMobile}
        listData={listData}
        openModalCreateList={openModalCreateList}
        setCreateListButton={setCreateListButton}
        setOpenModalCreateList={setOpenModalCreateList}
      />
    </Container>
  )
}
