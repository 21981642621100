import { ReactNode } from 'react'
import {
  Column,
  Container,
  Divider,
  Icon,
  Picture,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Position } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'

export interface SignUpLayoutProps {
  children?: ReactNode
  headerPosition?: Position
  logoImage?: string
  onBackClick?: () => void
  onLogoClick?: () => void
}

export const SignUpLayout = ({
  children,
  headerPosition = 'static',
  logoImage,
  onBackClick,
  onLogoClick
}: SignUpLayoutProps) => {
  return (
    <Column
      alignItems='center'
      gap={32}
    >
      <Column
        alignItems='center'
        backgroundColor='white'
        justifyContent='center'
        position={headerPosition}
        zIndex='10'
      >
        <BigScreen>
          <Container maxWidth='1280px'>
            <Row
              alignItems='center'
              gap={19}
              margin='8px 80px'
              maxHeight='45px'
              maxWidth='1160px'
              minHeight='45px'
            >
              <Container
                alignItems='center'
                clickable='pointer'
                data-testid='signup-header-back-link'
                justifyContent='center'
                maxHeight='24px'
                maxWidth='24px'
                minHeight='24px'
                minWidth='24px'
                onClick={onBackClick}
              >
                <Icon
                  clickable='pointer'
                  color='black'
                  customSize={20}
                  name='Back3'
                  onClick={onBackClick}
                />
              </Container>

              {logoImage && (
                <Picture
                  data-testid='signup-header-home-link'
                  height='30'
                  onClick={onLogoClick}
                  src={logoImage}
                  url='/'
                  width='154'
                />
              )}
            </Row>
          </Container>
        </BigScreen>

        <SmallScreen>
          <Container maxWidth='496px'>
            <Row
              alignItems='center'
              gap={16}
              justifyContent='between'
              maxHeight='50px'
              maxWidth='496px'
              minHeight='50px'
            >
              <Container
                alignItems='center'
                clickable='pointer'
                data-testid='signup-header-back-link'
                justifyContent='center'
                maxHeight='44px'
                maxWidth='44px'
                minHeight='44px'
                minWidth='44px'
                onClick={onBackClick}
                padding='0 4px'
              >
                <Icon
                  clickable='pointer'
                  color='black'
                  customSize={20}
                  name='Back3'
                  onClick={onBackClick}
                />
              </Container>

              {logoImage && (
                <Picture
                  data-testid='signup-header-home-link'
                  height='30'
                  onClick={onLogoClick}
                  src={logoImage}
                  url='/'
                  width='154'
                />
              )}

              <Container
                alignItems='center'
                clickable='pointer'
                data-testid='signup-header-close-link'
                justifyContent='center'
                maxHeight='44px'
                maxWidth='44px'
                minHeight='44px'
                minWidth='44px'
                onClick={onBackClick}
                padding='0 4px'
              ></Container>
            </Row>
          </Container>
        </SmallScreen>

        <Divider
          color={getGlobalStyle('--color-neutral-gray-divider')}
          thickness={1}
          verticalMargin={0}
        />
      </Column>

      <Column
        gap={32}
        maxWidth='80rem'
        padding='1rem'
      >
        {children}
      </Column>
    </Column>
  )
}
