import { ReactNode } from 'react'
import {
  Button,
  Column,
  Container,
  Divider,
  Icon,
  Modal,
  RichText,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { SignUpInfoModalProps } from './types'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './SignUpInfoModal.module.css'

export const SignUpInfoModal = ({
  buttonLabel,
  isOpen,
  onClose,
  onSubmit,
  richTextData,
  title
}: SignUpInfoModalProps) => {
  const handleButtonClick = () => {
    if (onSubmit) {
      onSubmit()
    }
  }
  const handleModalToggle = () => {
    if (onClose) {
      onClose()
    }
  }

  const renderModal = ({
    children,
    fullScreen
  }: {
    children?: ReactNode
    fullScreen?: boolean
  }) => {
    return (
      <Modal
        customContainerClassName={styles['sign-up-info-modal__overlay']}
        fullScreen={fullScreen}
        hiddenX
        iconPadding='1.625rem'
        isOpen={isOpen}
        maxWidth='52rem'
        minHeightFullScreen='70vh'
        padding='0'
        sizeIconClose={20}
        toggle={handleModalToggle}
        toggleOutside={handleModalToggle}
      >
        <Column>
          {title && (
            <Column>
              <Row
                alignItems='center'
                justifyContent='center'
                padding='1.5rem'
                position='relative'
              >
                <Text
                  fontSize='xl'
                  fontWeight='semibold'
                >
                  {title}
                </Text>

                <Container
                  absoluteDefault='topRight'
                  clickable='pointer'
                  onClick={onClose}
                  padding='1.625rem'
                  position='absolute'
                  width='auto'
                  zIndex='10'
                >
                  <Icon
                    clickable='pointer'
                    color='black'
                    customSize={20}
                    name='CloseThin'
                    onClick={onClose}
                  />
                </Container>
              </Row>

              <Divider
                borderHeight='3xs'
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={0}
              />
            </Column>
          )}

          {children}
        </Column>
      </Modal>
    )
  }

  return (
    <>
      <BigScreen>
        {renderModal({
          fullScreen: false,
          children: (
            <>
              <Column
                maxHeight='20rem'
                overflow='auto'
                padding='1rem'
              >
                {richTextData && <RichText richTextData={richTextData} />}
              </Column>

              {buttonLabel && (
                <Column>
                  <Row
                    borderRadius='12px'
                    boxShadow='4px -6px 18px 0px rgba(0, 0, 0, 0.05)'
                    justifyContent='center'
                    padding='1rem'
                  >
                    <Button
                      borderRadius='2rem'
                      fontWeight='normal'
                      label={buttonLabel}
                      onClick={handleButtonClick}
                      width='343px'
                    />
                  </Row>
                </Column>
              )}
            </>
          )
        })}
      </BigScreen>

      <SmallScreen>
        {renderModal({
          fullScreen: true,
          children: (
            <>
              <Column
                maxHeight={`calc(80vh - 150px)`}
                overflow='auto'
                padding='1rem'
              >
                {richTextData && <RichText richTextData={richTextData} />}
              </Column>

              {buttonLabel && (
                <Column>
                  <Row
                    borderRadius='12px'
                    boxShadow='4px -6px 18px 0px rgba(0, 0, 0, 0.05)'
                    justifyContent='center'
                    padding='1rem'
                  >
                    <Button
                      borderRadius='2rem'
                      customClassName={styles['sign-up-info-modal__button']}
                      fontWeight='normal'
                      label={buttonLabel}
                      onClick={handleButtonClick}
                      width='343px'
                    />
                  </Row>
                </Column>
              )}
            </>
          )
        })}
      </SmallScreen>
    </>
  )
}
