import { Container } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ICouponObject } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICoupon'
import { CouponDetailContent } from './CouponDetailContent/CouponDetailContent'
import { CouponDetailModal } from './CouponDetailModal/CouponDetailModal'
import { CouponDetailPage } from './CouponDetailPage/CouponDetailPage'

export interface CouponDetailProps {
  activeLoading?: boolean
  backToText?: string
  coupon?: ICouponObject
  open: boolean
  selectedFilter?: string
  onActive?: () => void
  onShelfCatch?: (payload: unknown) => void
  onToggle?: () => void
}

export const CouponDetail = ({
  activeLoading = false,
  backToText,
  coupon,
  open,
  selectedFilter,
  onActive,
  onShelfCatch,
  onToggle
}: CouponDetailProps) => {
  const handleModalToggle = () => {
    if (onToggle) {
      onToggle()
    }
  }

  return (
    <>
      <BigScreen>
        <CouponDetailModal
          onToggle={handleModalToggle}
          open={open}
        >
          <Container
            isWrap
            minWidth='800px'
          >
            <CouponDetailContent
              activeLoading={activeLoading}
              coupon={coupon}
              onActive={onActive}
              onShelfCatch={onShelfCatch}
              selectedFilter={selectedFilter}
            />
          </Container>
        </CouponDetailModal>
      </BigScreen>
      <SmallScreen>
        <CouponDetailPage
          backToText={backToText}
          onToggle={handleModalToggle}
          open={open}
        >
          <Container isWrap>
            <CouponDetailContent
              activeLoading={activeLoading}
              coupon={coupon}
              onActive={onActive}
              onShelfCatch={onShelfCatch}
              selectedFilter={selectedFilter}
            />
          </Container>
        </CouponDetailPage>
      </SmallScreen>
    </>
  )
}
