import { useEffect } from 'react'

export const useFullHiderFooter = (hideFooter: boolean) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const footerBottomSpacer = document.querySelector(
        '.footer__bottom-spacer'
      ) as HTMLElement
      if (footerBottomSpacer) {
        if (hideFooter) {
          footerBottomSpacer.style.display = 'none'
        } else {
          footerBottomSpacer.style.display = 'block'
        }
      }
      const footerBottom = document.querySelector('footer') as HTMLElement
      if (footerBottom) {
        if (hideFooter) {
          footerBottom.style.display = 'none'
        } else {
          footerBottom.style.display = 'block'
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])
}
