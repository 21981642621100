import {
  Column,
  Container,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { getTotalItemsMiniCart, useEvents } from '@smu-chile/pkg-unimarc-hooks'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMiniCart'
import { useState } from 'react'
import { HeaderMiniCartLoader } from './HeaderMiniCartLoader'

interface HeaderMinicartProps {
  data: Data
  isRefetching?: boolean
}

export const HeaderMinicart = ({
  data,
  isRefetching = false
}: HeaderMinicartProps): React.ReactElement => {
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading } }) => {
      setIsOrderFormLoading(loading)
    }
  })

  if (!data || isOrderFormLoading || isRefetching) {
    return <HeaderMiniCartLoader />
  }

  return (
    <Container
      backgroundColor={getGlobalStyle('--color-base-white')}
      maxHeight='54px'
    >
      <Row justifyContent='between'>
        <Column maxWidth='-webkit-fill-available'>
          <Row alignItems='end'>
            <Column
              justifyContent='center'
              maxWidth='max-content'
              padding='6px 0px'
            >
              <Text
                fontSize='2xl'
                fontWeight='semibold'
              >
                Mi carro
              </Text>
            </Column>
            <Spacer.Vertical size={8} />
            {data?.items?.length > 0 && (
              <Column
                justifyContent='end'
                maxWidth='max-content'
                padding='6px 0px'
              >
                <Text
                  color='gray'
                  fontSize='lg'
                  fontWeight='regular'
                >
                  {`(${getTotalItemsMiniCart(data)} productos)`}
                </Text>
              </Column>
            )}
          </Row>
        </Column>
      </Row>
    </Container>
  )
}
