import { getCookie, setCookie } from '@smu-chile/pkg-unimarc-hooks'

const PROMOTIONAL_MODAL_SEEN_COOKIE_KEY = 'promotionalModal'
const PROMOTIONAL_MODAL_SEEN_COOKIE_DURATION = 60 * 60 * 1000 // 1 hour

export const getPromotionalModalSeenCookie = (): boolean | null => {
  const item = getCookie(PROMOTIONAL_MODAL_SEEN_COOKIE_KEY)
  return item === 'true'
}

export const setPromotionalModalSeenCookie = (value: boolean) => {
  setCookie({
    cookieName: PROMOTIONAL_MODAL_SEEN_COOKIE_KEY,
    cookieValue: JSON.stringify(value),
    options: {
      expires: new Date(
        new Date().getTime() + PROMOTIONAL_MODAL_SEEN_COOKIE_DURATION
      )
    }
  })
}
