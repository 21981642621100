// ref: https://nextjs.org/docs/advanced-features/error-handling
// ret: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/

import { ErrorViewProps } from '@smu-chile/pkg-unimarc-components'
import React, { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorPage } from '../ErrorPage'

interface Props {
  children?: ReactNode
  errorComponent?: ReactNode
}

interface State {
  hasError: boolean
  customViewError?: CustomViewError
}

export class CustomViewError {
  constructor(public props: ErrorViewProps) {}
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    customViewError: undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error | CustomViewError): State {
    if (_ instanceof CustomViewError) {
      return { hasError: true, customViewError: _ }
    }
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        this.props.errorComponent ?? (
          <ErrorPage
            {...this.state.customViewError?.props}
            redirect={false}
          />
        )
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
