import {
  Container,
  Backbone,
  Row,
  Divider,
  Spacer,
  BackboneShelves
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import React from 'react'
import { MobileModalBackBone } from './MobileModalBackBone'
type backBoneType = { isMobile: boolean }
export const ModalBackBone = ({ isMobile = false }: backBoneType) => {
  const fromPDP = window.location.href.includes('product')
  if (isMobile) {
    return <MobileModalBackBone />
  }

  return (
    <Container
      isWrap
      maxWidth='850px'
      padding='0 24px'
      width='820px'
    >
      {/* header */}
      <Row
        justifyContent='center'
        padding={fromPDP ? '0px 24px 0px' : '16px 0px 0px'}
      >
        <Row maxWidth='300px'>
          <Backbone
            borderRadius='12px'
            height={30}
          />
        </Row>
      </Row>

      <Divider
        color={getGlobalStyle('--color-loaders-background')}
        verticalMargin={20}
      />
      <Row
        justifyContent='between'
        maxWidth='800px'
      >
        <Row
          isWrap
          padding='0 30px'
        >
          <Row
            gap='15px'
            maxHeight='50px'
            padding='26px 0 0 38px'
          >
            {Array(3).map((index) => {
              return (
                <Row
                  key={index}
                  maxWidth='50px'
                >
                  <Backbone
                    borderRadius='5px'
                    height={50}
                  />
                </Row>
              )
            })}
          </Row>
        </Row>
      </Row>
      <BackboneShelves
        shelvesForRow={4}
        shelvesQuantityDesktop={4}
      />
      <Spacer size={32} />
    </Container>
  )
}
