import { useEffect, useState } from 'react'
import { OffCanvas } from '@smu-chile/pkg-unimarc-components'
import { sleep, useEvents, useMiniCart } from '@smu-chile/pkg-unimarc-hooks'
import { SelectedAddresses } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { ContainerMiniCart } from './ContainerMiniCart'
import { EmptyMiniCart } from './EmptyMiniCart'
import { FooterMiniCart } from './FooterMiniCart'
import { HeaderMinicart } from './HeaderMinicart'
interface MinicartProps {
  open: boolean
  selectedAddresses?: SelectedAddresses
  onClose: () => void
}

export const Minicart = ({
  open,
  selectedAddresses,
  onClose
}: MinicartProps): React.ReactElement => {
  const { data, isRefetching, refetch } = useMiniCart()
  const [needRefetch, setNeedRefetch] = useState(false)
  const isEmptyCart = data?.data?.items?.length === 0

  useEvents({
    eventType: 'clearCart',
    callBack: async ({ detail: { cleanCart } }) => {
      if (cleanCart && typeof cleanCart === 'boolean') {
        await sleep(1000)
        refetch()
      }
    }
  })

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading } }) => {
      if ((!loading && needRefetch) || (!loading && isEmptyCart)) {
        setNeedRefetch(false)
        refetch()
      }
    }
  })

  useEffect(() => {
    if (open) refetch()
  }, [open])

  return (
    <OffCanvas
      direction='right'
      footerComponent={
        !isEmptyCart && <FooterMiniCart selectedAddresses={selectedAddresses} />
      }
      headerComponent={
        <HeaderMinicart
          data={data?.data}
          isRefetching={isRefetching}
        />
      }
      isOpen={open}
      toggleOutside={onClose}
    >
      {isEmptyCart && <EmptyMiniCart />}
      {!isEmptyCart && <ContainerMiniCart />}
    </OffCanvas>
  )
}
