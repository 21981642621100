import { MutableRefObject, useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  addToHomeData,
  clickedPromos,
  ContentfulResponse,
  generateBreadcrumbJoin,
  IContentfulBasicEntry,
  IContentfulResponse,
  isValidArrayWithData,
  useContentful,
  useMobile,
  useOnScreen,
  useOrderForm,
  useSession,
  visiblePromosScroll
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Container,
  Column,
  PromotionalModalProps,
  TimeOfferBannerProps,
  HtmlRenderProps
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IInAppModal } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IInAppModal'
import {
  BannerHomeObj,
  CarouselFromContentful,
  PropsHome,
  SendPromoDataImageBanner,
  SendPromoDataProps,
  ShowCaseHome,
  TermsData,
  TimeOfferBannerData
} from 'shared/interfaces/Home'
import { RenderProductsCarouselProps } from 'components/ProductsCarousel/RenderProductsCarousel'
import {
  getPromotionalModalSeenCookie,
  setPromotionalModalSeenCookie
} from './PromotionalModal/helpers'
import { BackboneShowCaseWeb } from './Backbone/BackboneShowCaseWeb'
import { BackboneHome } from './Backbone'
import { PropsBannersWeb } from './BannersWeb'
import { IShowCaseWebProps } from './ShowCaseWeb'
import { offerBannerValidator } from '../../shared/helpers/offerBannerValidator'
import { checkDevice } from '../../shared/utils/isMobile'
import { PagesContainerDescription } from 'components/PagesContainerDescription/PagesContainerDescription'
import { useFullHiderFooter } from 'shared/utils/hooks'

const ShowCaseWeb = dynamic<IShowCaseWebProps>(
  () => {
    return import('./ShowCaseWeb').then((mod) => {
      return mod.ShowCaseWeb
    })
  },
  {
    loading: () => {
      return <BackboneShowCaseWeb />
    }
  }
)

const PromotionalModal = dynamic<PromotionalModalProps>(() => {
  return import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.PromotionalModal
  })
})

const TimeOfferBanner = dynamic<TimeOfferBannerProps>(() => {
  return import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.TimeOfferBanner
  })
})

const RenderStringHtml = dynamic<HtmlRenderProps>(() => {
  return import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.RenderStringHtml
  })
})

const RenderProductsCarousel = dynamic<RenderProductsCarouselProps>(
  () => {
    return import('components/ProductsCarousel/RenderProductsCarousel').then(
      (mod) => {
        return mod.RenderProductsCarousel
      }
    )
  },
  { ssr: false }
)

const BannersWeb = dynamic<PropsBannersWeb>(() => {
  return import('./BannersWeb').then((mod) => {
    return mod.BannersWeb
  })
})

export interface CustomTitles {
  h2: {
    customFontSize: string
  }
  h3: {
    customFontSize: string
  }
}

export const Home = ({
  contentTypeId = 'home',
  customMainPadding,
  data: serverData,
  hideMainFooter,
  isMobile,
  salesChannel
}: PropsHome) => {
  const router = useRouter()
  const { innerWidth } = useMobile()
  const orderForm = useOrderForm()
  const dataOrderform = orderForm?.data
  const [promotionalModalOpen, setPromotionalModalOpen] = useState(true)

  const { isLoggedIn } = useSession()
  const site = 'Unimarc'
  const containerPadding =
    customMainPadding || (isMobile ? '16px 0 0 0' : '32px 0 0 0')

  const { elementRef: refCase, isIntersecting: isIntersectingCase } =
    useOnScreen({ rootMargin: '0px' })
  const { elementRef: refTimer, isIntersecting: showTimer } = useOnScreen({
    rootMargin: '0px'
  })
  const isFallback = router.isFallback
  const componentsToFirstLoad = isMobile ? 3 : 4

  const customTitles: CustomTitles = {
    h2: {
      customFontSize: isMobile
        ? getGlobalStyle('--font-size-titles-xs')
        : getGlobalStyle('--font-size-titles-lg')
    },
    h3: {
      customFontSize: isMobile
        ? getGlobalStyle('--font-size-titles-2xs')
        : getGlobalStyle('--font-size-titles-md')
    }
  }

  const clientData = useContentful({
    options: { content_type: contentTypeId },
    reactQuery: { enabled: !serverData }
  })
  const populatedData = clientData.data
    ? new ContentfulResponse(clientData.data as IContentfulResponse)
        .populateEntries()
        .getResponse()
    : []

  const promotionalModalResult = useContentful({
    options: {
      content_type: 'inAppModal',
      include: 1,
      limit: 1,
      'fields.idFormato': 1,
      'fields.startDate[lte]': new Date().toISOString(),
      'fields.endDate[gte]': new Date().toISOString(),
      'fields.platform': 'Web Unimarc eComm',
      'fields.status': true
    }
  })

  const promotionalModal: IInAppModal =
    promotionalModalResult?.data?.['items']?.[0]?.fields || null

  const promotionalModalImage =
    promotionalModalResult.data?.['includes']?.Asset?.[0]?.fields || {}

  const data = serverData || populatedData

  let undefinedContent = 0

  // custom hook to hide footer
  useFullHiderFooter(hideMainFooter)

  const handleTogglePromotionalModal = () => {
    setPromotionalModalOpen(!promotionalModalOpen)
  }

  const handleRenderValidate = ({ isRender = false, index = 0 }) => {
    return (
      isRender || index < componentsToFirstLoad || typeof window === 'undefined'
    )
  }

  const handleClickBanner = (promosData, index) => {
    clickedPromos({
      ...promosData,
      locationId: `${index + 1 - undefinedContent}`,
      site,
      orderForm: dataOrderform
    })
  }

  const sendPromoData = ({ cfIndex, data, ref }: SendPromoDataProps) => {
    let promoData: SendPromoDataImageBanner = data
    promoData = {
      creativeName: promoData['creativeName'],
      creativeSlot: promoData['creativeSlot'],
      locationId: cfIndex,
      promotionId: promoData['promotionId'],
      promotionName: promoData['promotionName'],
      urlPath: promoData['reference']
    }
    addToHomeData({ ref, promoData })
  }

  const renderTermsAndConditions = (
    contentData: IContentfulBasicEntry[],
    refCase: MutableRefObject<null>,
    index: number
  ) => {
    return (
      <Column
        alignItems='center'
        key={index}
        ref={refCase}
      >
        <PagesContainerDescription
          isMobile={isMobile}
          items={contentData}
        />
      </Column>
    )
  }

  const renderShowCaseWeb = (contentData: ShowCaseHome, index: number) => {
    return (
      <Column
        alignItems='center'
        key={index}
        ref={refCase}
      >
        {handleRenderValidate({
          isRender: isIntersectingCase,
          index
        }) && (
          <ShowCaseWeb
            cfIndex={index + 1 - undefinedContent}
            data={contentData.slider}
            dataCarouselProduct={contentData?.['carousel']?.[0] ?? []}
            dataOrderform={dataOrderform}
            isMobile={isMobile}
            salesChannel={salesChannel}
            site={site}
          />
        )}
      </Column>
    )
  }

  const renderTimeOfferBanner = (
    contentData: TimeOfferBannerData,
    contentType: string,
    index: number
  ) => {
    const itemSaleChannels = contentData?.image?.fields?.saleChannels
    // banners
    if (/featureContentBannersWeb/i.test(contentType)) {
      return (
        <BannersWeb
          cfIndex={index + 1 - undefinedContent}
          data={contentData}
          dataOrderform={dataOrderform}
          isMobile={isMobile}
          isPriority={index === 0}
          saleChannel={salesChannel}
          site={site}
          titleSizes={customTitles}
        />
      )
    }

    if (itemSaleChannels && !itemSaleChannels?.includes(salesChannel)) {
      return null
    }

    return (
      <Container
        justifyContent='center'
        key={index}
        ref={refTimer}
      >
        <Column alignItems='center'>
          {handleRenderValidate({ isRender: showTimer, index }) && (
            <TimeOfferBanner
              bannerSalesChannel={contentData?.image?.fields?.saleChannels}
              catchPromoData={(promoData?: object) => {
                sendPromoData({
                  cfIndex: `${index + 1 - undefinedContent}`,
                  data: contentData?.image?.fields,
                  ref: promoData
                })
              }}
              endDate={new Date(contentData?.endDate)}
              href={contentData?.image?.reference}
              imgSrcDesktop={
                contentData?.image?.imageDesktop?.fields?.file?.url
              }
              imgSrcMobile={contentData?.image?.imageMobile?.fields?.file?.url}
              isMobile={isMobile}
              linkWrapper={Link}
              onRedirect={() => {
                handleClickBanner(contentData?.image?.fields, index)
              }}
              orderformSalesChannel={salesChannel}
              startDate={new Date(contentData?.startDate)}
            />
          )}
        </Column>
      </Container>
    )
  }

  const renderBannerWeb = (contentData: BannerHomeObj, index: number) => {
    return (
      <BannersWeb
        cfIndex={index + 1 - undefinedContent}
        data={contentData}
        isMobile={isMobile}
        isPriority={index === 0}
        saleChannel={salesChannel}
        titleSizes={customTitles}
      />
    )
  }

  const renderProductsCarousel = (
    contentData: CarouselFromContentful,
    index: number
  ) => {
    const isMobileView = checkDevice({ isMobile, innerWidth })
    const refType: string = contentData?.referenceType

    if (!['MYUSUALS', 'COLLECTION'].includes(refType?.toUpperCase()))
      return null
    if (refType?.toUpperCase() === 'MYUSUALS' && !isLoggedIn) return null

    return (
      <RenderProductsCarousel
        content={contentData}
        customTitles={customTitles}
        index={index}
        isMobile={isMobileView}
      />
    )
  }

  const renderTermsAndConditionsHTML = (contentData: TermsData, index) => {
    return (
      <RenderStringHtml
        htmlString={contentData.html}
        key={index}
      />
    )
  }

  const renderContent = (content, index: number) => {
    const contentType = content[0]
    const contentData = content[1]

    // terms and conditions
    if (/term/i.test(contentType) && Object.keys(contentData).length > 1) {
      return renderTermsAndConditions(contentData, refCase, index)
    }

    // carousel showcaseWeb
    if (/showcaseWeb/i.test(contentType) && contentData?.slider?.length > 0) {
      return renderShowCaseWeb(contentData, index)
    }

    // time offer banner
    if (/offerBanner/i.test(contentType) && offerBannerValidator(contentData)) {
      return renderTimeOfferBanner(contentData, contentType, index)
    }

    // banners
    if (/featureContentBannersWeb/i.test(contentType)) {
      return renderBannerWeb(contentData, index)
    }

    // carousels web
    if (/productsCarouselWeb/i.test(contentType)) {
      return renderProductsCarousel(contentData, index)
    }

    // render HTML as String
    if (/term/i.test(contentType) && contentData?.html) {
      return renderTermsAndConditionsHTML(contentData, index)
    }

    undefinedContent += 1
    return null
  }
  useEffect(() => {
    generateBreadcrumbJoin([])
    visiblePromosScroll({ site })
    const promotionalModalState = getPromotionalModalSeenCookie()
    if (!promotionalModalState) {
      setPromotionalModalOpen(true)
      setPromotionalModalSeenCookie(true)
    }
  }, [])

  if (!data || isFallback) {
    return <BackboneHome />
  }
  const regex = /showcaseWeb/i
  const found = isValidArrayWithData(Object.entries(data))
    ? Object.entries(data).map((item) => {
        return regex.test(item[0]) && item[1]?.['carousel']?.length > 0
      })
    : null

  const entries = Object.entries(data || {})

  return (
    <Container
      backgroundColor={getGlobalStyle('--color-base-white')}
      margin={isMobile || found?.[0] ? 'none' : '0.7px 0 0 0'}
      padding={containerPadding}
      position='relative'
      tagName='main'
    >
      {promotionalModalOpen && !!promotionalModal && (
        <PromotionalModal
          imageSrc={promotionalModalImage?.file?.url}
          isMobile={isMobile}
          linkWrapper={Link}
          onToggle={handleTogglePromotionalModal}
          open={promotionalModalOpen && !!promotionalModal}
          redirectTo={promotionalModal?.actionUrl}
        />
      )}

      <Column
        alignItems='center'
        gap={32}
      >
        {entries.length > 0 && entries.map(renderContent)}
      </Column>
    </Container>
  )
}
