import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import dynamic from 'next/dynamic'

const TermsAndConditionsContent = dynamic(() => {
  return import('./membershipQueryLevelTermAndConditions.mdx')
})

export interface IModalTermsAndConditionsProps {
  handleToggle: () => void
}

export const ModalTermsAndConditions = ({
  handleToggle
}: IModalTermsAndConditionsProps) => {
  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={getBemId('modal', 'terms-and-conditions')}
          bodyChildrenProps={{
            maxHeight: '300px',
            minHeight: '300px',
            padding: '16px'
          }}
          footerChildrenProps={{
            default: true,
            buttonDefaultProps: {
              label: 'Entendido',
              fontWeight: getGlobalStyle('--font-weight-medium'),
              maxWidth: '400px',
              onClick: () => {
                handleToggle()
              }
            }
          }}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerTitle='Términos y condiciones'
          headerTitleProps={{
            height: '36px'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            isOpen: true,
            toggleOutside: handleToggle,
            toggle: handleToggle,
            maxWidth: '800px',
            maxHeight: '440px',
            minHeight: '450px'
          }}
        >
          <Column padding='0 20px'>
            <TermsAndConditionsContent />
          </Column>
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId={getBemId('modal', 'privacy-policy')}
          body={
            <Column padding='0 15px'>
              <TermsAndConditionsContent />
            </Column>
          }
          bodyContainerProps={{
            maxHeight: 'calc(100vh - 220px)'
          }}
          buttonConfigsProps={{
            label: 'Entendido',
            height: '40px',
            onClick: handleToggle
          }}
          dragProps={{
            draggButton: false
          }}
          footerContainerProps={{
            padding: '12px 16px'
          }}
          hasFooter={true}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          headerTitle='Términos y condiciones'
          hideHeaderDivider={true}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
