import { Text } from '@smu-chile/pkg-unimarc-components'
import { IAlliance } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IAlliance'
import { IAlliancePopulate } from 'components/AlliancePage'
import { getMembershipLabel } from 'components/AlliancePage/helpers/levels'
import { NextRouter } from 'next/router'
import { BannersClubAhorro } from 'shared/interfaces/ClubUnimarc'

export const getBlocks = (content) => {
  return (
    content?.items.length > 0 &&
    content.items.map((block) => {
      const { label, subtitulo, url, icon } = block
      return {
        label,
        subtitulo,
        url,
        icon
      }
    })
  )
}

export const getBannersCarousel = (
  content: BannersClubAhorro,
  router: NextRouter,
  onClick?,
  alliances?: IAlliance[],
  isLoggedIn?: boolean
) => {
  const banners = []
  const showButton = isLoggedIn ? true : false
  for (const banner of content.imageBanner) {
    if (
      banner.referenceType === 'url' &&
      banner?.referenceWeb?.includes('/alliance')
    ) {
      const alliance = alliances.find((a) => {
        return a.refid === banner.referenceWeb.split('=')[1]
      })
      alliance &&
        banners.push({
          image: banner.label,
          img: banner.fields.image.fields.file.url,
          onRedirect: () => {
            onClick && onClick(alliance.refid)
          }
        })
    } else {
      if (banner?.fields?.image?.fields?.file?.url && banner.label)
        banners.push({
          image: banner.label,
          img: banner?.fields?.image?.fields?.file?.url,
          onRedirect: () => {
            banner.fields?.referenceWeb &&
              router.push(banner.fields?.referenceWeb)
          }
        })
    }
  }
  return { banners, showButton }
}

export const getAllianceModalInfo = (
  selectedAlliance: IAlliancePopulate,
  membershipLevel: string,
  isLoggedIn: boolean
) => {
  const activationLevel = selectedAlliance?.['activationLevel'] ?? [
    'clubunimarc'
  ]
  const [levelRequired] = activationLevel

  const activationAvailable =
    !isLoggedIn || activationLevel.includes(membershipLevel)

  const activationStatus = selectedAlliance?.status
  const levelLabel = getMembershipLabel(levelRequired)

  if (activationStatus === 'active') {
    return {
      available: activationAvailable,
      image: selectedAlliance.modalImage,
      legal: selectedAlliance.legal,
      modalTitle: selectedAlliance.modalTitle,
      text: selectedAlliance.textActive,
      title: selectedAlliance.mainTitleActive
    }
  }

  if (activationAvailable) {
    return {
      available: activationAvailable,
      image: selectedAlliance?.modalImage,
      legal: selectedAlliance?.legal,
      modalTitle: selectedAlliance?.modalTitle,
      text: selectedAlliance?.textInactive,
      title: selectedAlliance?.mainTitleInactive
    }
  }

  return {
    available: activationAvailable,
    image: selectedAlliance?.modalImage,
    legal: '',
    modalTitle: selectedAlliance?.modalTitle,
    text: (
      <p>
        Este descuento está disponible{' '}
        <Text
          display='inline'
          fontWeight='medium'
          type='span'
        >
          a partir del nivel {levelLabel}.
        </Text>{' '}
        Para disfrutarlo te invitamos a subir de nivel.
      </p>
    ),
    title: '¡Falta poco!'
  }
}
