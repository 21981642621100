import { useRouter } from 'next/router'
import {
  Text,
  Column,
  Row,
  Picture,
  TextProps
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { MEMBERSHIP_LEVELS } from 'shared/utils/constanst'
import { formatTextWithLineBreaks } from '@smu-chile/pkg-unimarc-components/shared/helpers'
import { TFontSize } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontSize'
import { MembershipUnipayBanner } from 'shared/interfaces/MembershipUnipayBanner'
import { BaseContainer } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'

interface IModalMembershipTypeContentConfig {
  imageWidth: string
  imageHeight: string
  maxWidthRow: string
  modalMessage: string
  titleSize: TFontSize
  titleWeight: TextProps['fontWeight']
  modalMessageSize: TFontSize
  modalMessageBoldyTextWeight: TextProps['fontWeight']
  modalMessageBoldyTextSize: TFontSize
  diamondMemberMessage: string
  direction: 'row' | 'column'
  margin: string
  padding: string
  minHeight: {
    banner: string
    noBanner: string
  }
}
interface IModalMembershipTypeContent {
  mobile: IModalMembershipTypeContentConfig
  desktop: IModalMembershipTypeContentConfig
}

const MODAL_MEMBERSHIP_TYPE_CONTENT: IModalMembershipTypeContent = {
  mobile: {
    imageWidth: '100%',
    imageHeight: 'auto',
    maxWidthRow: '100%',
    modalMessage:
      'Disfruta los beneficios y descuentos que el Club tiene para ti.',
    modalMessageSize: 'md',
    modalMessageBoldyTextWeight: 'bold',
    modalMessageBoldyTextSize: 'md',
    titleSize: 'xl',
    titleWeight: 'semibold',
    diamondMemberMessage:
      'Disfruta los beneficios y descuentos que el Club tiene para ti.',
    direction: 'column',
    margin: '16px 0 0',
    padding: '0 16px',
    minHeight: {
      banner: '419px',
      noBanner: '520px'
    }
  },
  desktop: {
    imageWidth: '354px',
    imageHeight: 'auto',
    maxWidthRow: '354px',
    modalMessage:
      'Disfruta los beneficios y descuentos que el Club tiene para ti.',
    modalMessageSize: 'base',
    modalMessageBoldyTextWeight: 'medium',
    modalMessageBoldyTextSize: 'lg',
    titleSize: '3xl',
    titleWeight: 'medium',
    diamondMemberMessage:
      'Disfruta los beneficios y descuentos que el Club tiene para ti.',
    direction: 'row',
    margin: 'initial',
    padding: '',
    minHeight: {
      banner: '260px',
      noBanner: '333px'
    }
  }
}

interface ModalMembershipTypeContentProps {
  membershipLevel: string
  unipayStatus?: string
  unipayBannersCF?: MembershipUnipayBanner[]
}

export const ModalMembershipTypeContent = ({
  membershipLevel,
  unipayStatus,
  unipayBannersCF
}: ModalMembershipTypeContentProps): React.ReactElement => {
  const router = useRouter()
  const blockId = 'modalMembershipTypeContent'
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const configValue = isMobile
    ? MODAL_MEMBERSHIP_TYPE_CONTENT.mobile
    : MODAL_MEMBERSHIP_TYPE_CONTENT.desktop
  const modalMessage =
    membershipLevel === 'diamante'
      ? configValue.diamondMemberMessage
      : configValue.modalMessage

  const banner = unipayStatus
    ? unipayBannersCF?.find((bannerCF) => {
        return (
          bannerCF?.label ===
          `${unipayStatus}${isMobile ? 'Mobile' : 'Desktop'}`
        )
      })
    : null

  return (
    <>
      {/* content */}
      <Column
        alignItems='center'
        maxWidth='100%'
        minHeight={isMobile ? '530px' : 'inherit'}
        position='relative'
        tagName='section'
      >
        {/* banner unipay */}
        {banner && (
          <Picture
            alt='test'
            clickable={banner ? 'pointer' : 'cursor'}
            height='auto'
            onClick={() => {
              router.push(banner?.referenceWeb)
            }}
            src={banner?.imageUrl}
            width='100%'
          />
        )}
        <BaseContainer
          alignItems='center'
          flexDirection={configValue.direction}
          gap={20}
          justifyContent='center'
          margin={configValue.margin}
          minHeight={
            banner
              ? configValue.minHeight.banner
              : configValue.minHeight.noBanner
          }
          padding={configValue.padding}
        >
          {/* text */}
          <Row
            alignItems='center'
            id={getBemId(blockId, 'altiro-logo')}
            justifyContent='center'
            tagName='article'
            width='328px'
          >
            <Column alignItems='center'>
              <Text
                fontSize={configValue.titleSize}
                fontWeight={configValue.titleWeight}
              >
                ¡Felicidades!
              </Text>
              <Text
                fontSize={configValue.modalMessageSize}
                id={getBemId(blockId, 'welcome-text')}
                textAlign='center'
              >
                {formatTextWithLineBreaks(modalMessage)}
              </Text>
            </Column>
          </Row>
          {/* picture */}
          <Row
            maxWidth={configValue.maxWidthRow}
            tagName='article'
          >
            <Picture
              alt={MEMBERSHIP_LEVELS[membershipLevel]?.alt}
              height={configValue.imageHeight}
              src={`${router.basePath}/${MEMBERSHIP_LEVELS[membershipLevel]?.url}`}
              title={MEMBERSHIP_LEVELS[membershipLevel]?.alt}
              width={configValue.imageWidth}
            />
          </Row>
        </BaseContainer>
      </Column>
    </>
  )
}
