import { UseQueryResult } from '@smu-chile/pkg-unimarc-hooks'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'

export const handleSelectAddress = ({
  orderForm
}: {
  orderForm: UseQueryResult<ResponseOrderFormProps>
}) => {
  const selectedAddresses = orderForm?.data?.data?.selectedAddresses
  const isPickup = selectedAddresses?.addressType == 'pickup-in-point'
  const addressDelivery = selectedAddresses?.street
    ? `${selectedAddresses?.street} ${selectedAddresses?.number}, ${selectedAddresses.neighborhood}`
    : ''
  const addressSelected = isPickup
    ? `Unimarc ${selectedAddresses?.neighborhood}` || ''
    : addressDelivery

  return {
    addressDelivery,
    addressSelected,
    isPickup,
    selectedAddresses
  }
}
