import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Row,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import {
  Data,
  Item
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMiniCart'
import {
  currentOrderformInfoTag,
  castNumberMoney,
  currencyFormat,
  putAcceptCriteriaItems,
  sleep,
  useMiniCart
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { SelectedAddresses } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import styles from '../FooterMiniCart.module.css'

export interface OrderFormItems {
  id: string
  productId: string
  substituteType: string
}

export interface ButtonMakeOrderProps {
  loading?: boolean
  selectedAddresses?: SelectedAddresses
}

const validateAcceptCriteriaCheckBox = () => {
  return document
    .getElementsByClassName('btnMakeOrder')[0]
    ?.classList?.contains('isChecked_true')
}

const desconstructOrderFormItems = (data: Data): OrderFormItems[] => {
  return data.items.map((item: Item) => {
    return {
      id: item.id,
      productId: item.productId,
      substituteType: '2'
    }
  })
}

const redirectCheckout = () => {
  setTimeout(() => {
    window.location.href = `${process.env.NEXT_PUBLIC_URL_CHECKOUT}`
  }, 1000)
}

const ButtonMakeOrder = ({
  selectedAddresses,
  loading = false
}: ButtonMakeOrderProps): React.ReactElement => {
  const { data, isLoading } = useMiniCart()
  const [buttonLoading, setButtonLoading] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(false)
  const miniCartData: Data = data?.data
  const minValue = castNumberMoney(miniCartData?.minValue) ?? 0
  const subtotal = castNumberMoney(miniCartData?.subtotal) ?? 0
  const addressValidator =
    !selectedAddresses || selectedAddresses?.country !== 'CHL'
      ? 'disabled'
      : 'initial'

  const middleLoaders = async () => {
    setButtonLoading(true)
    await sleep(1000)
    setButtonLoading(false)
    handleOnClick()
  }

  const handleOnClick = async () => {
    if (minValue !== 0 && Number(minValue) > Number(subtotal)) {
      setOpenTooltip(true)
      setTimeout(() => {
        return setOpenTooltip(false)
      }, 4000)
      return
    }
    const acceptCriteria = validateAcceptCriteriaCheckBox()
    if (acceptCriteria) {
      const orderFormItems = desconstructOrderFormItems(data.data)
      await putAcceptCriteriaItems(orderFormItems)
    }

    currentOrderformInfoTag({
      context: 'minicarro',
      event: 'begin_checkout',
      site: 'Unimarc'
    })
    redirectCheckout()
  }

  useEffect(() => {
    return () => {
      return setButtonLoading(false)
    }
  }, [])

  if (isLoading) return null

  return (
    <Container isWrap>
      {openTooltip && (
        <Row
          id='minvalue'
          justifyContent='center'
          role='minvalue'
        >
          <Tooltip
            align='start'
            arrow
            background={getGlobalStyle('--color-alvi-base-black')}
            closeIcon
            colorArrow='black'
            colorText='white'
            colorTitle='white'
            left='24px'
            maxWidth='328px'
            onClick={() => {
              return setOpenTooltip(false)
            }}
            padding='10px'
            position='absolute'
            right='24px'
            text=''
            textComponent={
              <Row>
                <Text
                  color='white'
                  fontSize='md'
                  isFlex={false}
                  type='span'
                >
                  Te faltan
                  <Text
                    color='white'
                    fontSize='md'
                    fontWeight='semibold'
                    isFlex={false}
                    type='span'
                  >
                    {` ${currencyFormat({
                      number: Number(minValue) - Number(subtotal),
                      bool: false
                    })} `}
                  </Text>
                  para poder realizar tu compra.
                </Text>
              </Row>
            }
            textSize='md'
            title='Mínimo de compra no alcanzado'
            titleSize='lg'
            titleWeight='semibold'
            top='-55px'
            type='bottom'
            width='388px'
          />
        </Row>
      )}
      <Row justifyContent='center'>
        <Button
          borderRadius={getGlobalStyle('--border-radius-xl')}
          customClassName={styles['button__primary']}
          fontWeight={getGlobalStyle('--font-weight-semibold')}
          height='45px'
          id='GA__button--finishOrder'
          label='Ir a pagar'
          onClick={middleLoaders}
          size='xs'
          status={loading || buttonLoading ? 'loading' : addressValidator}
          width='100%'
        />
      </Row>
    </Container>
  )
}

export default ButtonMakeOrder
