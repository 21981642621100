import { Modal } from '@smu-chile/pkg-unimarc-components'
import { ModalProps } from '@smu-chile/pkg-unimarc-components/stories/molecules/Modal/helpers'

export const FiltersModalMobile = ({
  isOpen = false,
  toggle,
  children
}: ModalProps) => {
  return (
    <Modal
      modalConfigsProps={{
        openAnimation: true,
        fullScreenSticky: true,
        hiddenX: true,
        isAutoHeight: true,
        isOpen,
        isWindowBlocked: true,
        marginFullScreen: '1vh 0 0',
        stickyBottom: true,
        toggle,
        toggleOutside: toggle
      }}
      styleProps={{ padding: '0', height: '100%' }}
    >
      {children}
    </Modal>
  )
}
