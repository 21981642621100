import {
  Button,
  Column,
  Container,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  CarouselBanners,
  CarouselBannersItemProps
} from '@smu-chile/pkg-unimarc-components/stories/organisms/Banners/CarouselBanners'
import { NextRouter } from 'next/router'
import {
  BigScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './PromotionsCarousel.module.css'
import classNames from 'classnames'
import { ClubFeatureCarousel } from 'components/ClubPage/components/features/ClubFeatureCarousel'

export enum PromotionsCarouselLabels {
  MisBeneficios = 'Beneficios por nivel mobile'
}

export enum PromotionsCarouselLabelWithIcon {
  CarruselAlianzasDesktop = 'Carrusel Alianzas Desktop',
  CarruselAlianzaMobile = 'Carrusel Alianza Mobile',
  MisBeneficios = 'Mis Beneficios'
}

const style = (isMobile: boolean) => {
  if (isMobile) {
    return {
      buttonFontSize: getGlobalStyle('--font-size-sm'),
      buttonHeight: '32px',
      buttonMinWidth: '80px',
      blockTopMargin: '0'
    }
  }
  return {
    buttonFontSize: getGlobalStyle('--font-size-lg'),
    buttonMinWidth: '106px',
    buttonHeight: '40px',
    blockTopMargin: '0'
  }
}

export interface PromotionsCarouselProps {
  bannersCarousel?: CarouselBannersItemProps[]
  imageBanner?: unknown[]
  isLoggedIn?: boolean
  isMobile?: boolean
  label?: string
  marginTitle?: string
  pictureWidthMobile?: string
  router?: NextRouter
  showButton?: boolean
  subtitle?: string
  title?: string
}
const PromotionsCarousel = ({
  bannersCarousel,
  imageBanner,
  isLoggedIn,
  isMobile,
  label,
  marginTitle,
  pictureWidthMobile,
  router,
  showButton,
  subtitle,
  title
}: PromotionsCarouselProps) => {
  const { buttonFontSize, buttonHeight, buttonMinWidth, blockTopMargin } =
    style(isMobile)

  const handleClick = () => {
    router.push('/club-unimarc/beneficios')
  }

  const iconSize = isMobile ? '21px' : '30px'
  const labelLower = label?.toLowerCase()
  const shouldShowIcon = isMobile || isLoggedIn

  const titleToDisplay = () => {
    return (
      <Container
        clickable='inherit'
        gap={4}
        width={isLoggedIn ? '100%' : 'auto'}
      >
        {(`${PromotionsCarouselLabelWithIcon.CarruselAlianzasDesktop}`.toLowerCase() ===
          labelLower ||
          `${PromotionsCarouselLabelWithIcon.CarruselAlianzaMobile}`.toLowerCase() ===
            labelLower ||
          `${PromotionsCarouselLabelWithIcon.MisBeneficios}`.toLowerCase() ===
            labelLower) &&
          shouldShowIcon && (
            <Picture
              border='none'
              hasURL={false}
              height={iconSize}
              src='https://images.ctfassets.net/un6yvtd6uq5z/24WkypOI7idc8SdiTY9tr6/b646f34dfded12859a765ad16cb8c1f4/image.png'
              width={iconSize}
            />
          )}

        {isLoggedIn ? (
          <Text
            fontSize={isMobile ? 'lg' : '2xl'}
            fontWeight={isMobile ? 'medium' : 'semibold'}
            id={getBemId('promotionsBanner', 'headerTitle')}
          >
            {title}
          </Text>
        ) : (
          <Text
            fontSize={isMobile ? 'lg' : '3xl'}
            fontWeight='medium'
            id={getBemId('promotionsBanner', 'headerTitle')}
          >
            {title}
          </Text>
        )}
      </Container>
    )
  }

  return (
    <Column
      className={styles['promotions-carousel__content']}
      gap={isMobile ? 16 : 24}
      margin={blockTopMargin}
      tagName='section'
    >
      {title && (
        <Row
          alignItems={'center'}
          justifyContent='between'
          margin={marginTitle}
        >
          <Column alignItems={isMobile ? 'start' : 'center'}>
            {titleToDisplay()}

            {subtitle && (
              <Text
                id={getBemId('promotionsBanner', 'headerSubtitle')}
                lineHeight={isMobile ? '10px' : '24px'}
              >
                {subtitle}
              </Text>
            )}
          </Column>

          {showButton && (
            <Button
              borderRadius={getGlobalStyle('--border-radius-xl')}
              fontSize={buttonFontSize}
              fontWeight={getGlobalStyle('--font-weight-medium')}
              height={buttonHeight}
              id={getBemId('promotionsBanner', 'headerButton')}
              label='Ver todos'
              minWidth={buttonMinWidth}
              onClick={handleClick}
              padding='0'
              size='sm'
              type='secondary'
            />
          )}
        </Row>
      )}

      <BigScreen>
        <Spacer.Horizontal customSize={isLoggedIn ? 12 : 35} />
      </BigScreen>

      <Container
        className={classNames({
          [styles['promotions-carousel__mobile']]: isMobile,
          [styles['promotions-carousel__benefits']]:
            label === PromotionsCarouselLabels.MisBeneficios
        })}
        id={getBemId('promotionBanner', 'carouselContainer')}
        margin={isMobile ? '0 -16px 0' : '0'}
      >
        {label === PromotionsCarouselLabels.MisBeneficios && (
          <ClubFeatureCarousel
            imageBanner={imageBanner}
            showDots
          />
        )}

        {label !== PromotionsCarouselLabels.MisBeneficios && (
          <CarouselBanners
            banners={bannersCarousel}
            infinite={false}
            itemsToShow={4}
            minWidthMobile={pictureWidthMobile}
            showDots={false}
            zoomIn={!isMobile}
          />
        )}
      </Container>
    </Column>
  )
}

export default PromotionsCarousel
