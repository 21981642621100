import {
  IContentfulResponse,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'

export type useClubPageProps = {
  cfData?: IContentfulResponse
  cfDataFilter?: string
}

export const useClubPage = ({ cfData, cfDataFilter }: useClubPageProps) => {
  const [cfClubPage] = searchClubGuestPage(cfData?.items ?? [], cfDataFilter)

  const blocks = cfClubPage?.['blocks'] ?? []
  const blocksDesktop = cfClubPage?.['blocksDesktop'] ?? []

  const mobileBlocks = searchClubBlocks(blocks)
  const desktopBlocks = searchClubBlocks(blocksDesktop)

  return {
    // header
    hasHeaderDesktopBlocks: isValidArrayWithData(desktopBlocks.header),
    hasHeaderMobileBlocks: isValidArrayWithData(mobileBlocks.header),
    headerDesktopBlocks: desktopBlocks.header,
    headerMobileBlocks: mobileBlocks.header,
    // middle
    hasMiddleDesktopBlocks: isValidArrayWithData(desktopBlocks.middle),
    hasMiddleMobileBlocks: isValidArrayWithData(mobileBlocks.middle),
    middleDesktopBlocks: desktopBlocks.middle,
    middleMobileBlocks: mobileBlocks.middle,
    // footer
    footerDesktopBlocks: desktopBlocks.footer,
    footerMobileBlocks: mobileBlocks.footer,
    hasFooterDesktopBlocks: isValidArrayWithData(desktopBlocks.footer),
    hasFooterMobileBlocks: isValidArrayWithData(mobileBlocks.footer)
  }
}

export const searchClubBlocks = (items: unknown[]) => {
  const blocks = {
    header: [],
    middle: [],
    footer: []
  }

  for (const item of items) {
    if (item?.['bottomBanner']) {
      blocks.footer = [...blocks.footer, item]
    }
    if (item?.['topBanner']) {
      blocks.header = [...blocks.header, item]
    }
    if (!item?.['bottomBanner'] && !item?.['topBanner']) {
      blocks.middle = [...blocks.middle, item]
    }
  }

  return blocks
}

export const searchClubGuestPage = (items: unknown[], filter?: string) => {
  if (isValidArrayWithData(items)) {
    return items?.filter((item) => {
      return item?.['viewId']?.toString().includes(filter)
    })
  }

  return []
}
