import { useEffect, useMemo, useRef, useState } from 'react'
import {
  CodeInput,
  Column,
  Container,
  Divider,
  Icon,
  Link,
  Modal,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './ConfirmCodeModal.module.css'
import { ConfirmCodeToast } from './ConfirmCodeToast'
import { SignUpLayout } from 'components/SignUpPage/SignUpLayout'

export interface ConfirmCodeModalProps {
  errorMessage?: string
  infoMessage?: string
  isConfirming?: boolean
  isOpen?: boolean
  logoImage?: string
  onClose?: () => void
  onChange?: (value?: string) => void
  onInfoToastClick?: () => void
  onSubmit?: () => void
  onResendSubmit?: () => void
  onTooltipClick?: () => void
  safeValue?: string
  top?: string
}

export const ConfirmCodeModal = ({
  errorMessage,
  infoMessage,
  isConfirming,
  isOpen,
  logoImage,
  onClose,
  onChange,
  onInfoToastClick,
  onResendSubmit,
  onTooltipClick,
  safeValue
}: ConfirmCodeModalProps) => {
  const [code, setCode] = useState('')
  const [countdown, setCountdown] = useState(0)
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)

  const timedown = useMemo(() => {
    const countdownTime = countdown < 10 ? `0${countdown}` : countdown
    return `00:${countdown < 10 ? countdownTime : countdown}`
  }, [countdown])

  const handleChange = (value?: string) => {
    setCode(value)

    if (onChange) onChange(value)
  }

  const handleResendClick = () => {
    setCountdown(30)

    if (onResendSubmit) onResendSubmit()
  }

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    }
  }, [countdown])

  useEffect(() => {
    setCountdown(30)
  }, [])

  useEffect(() => {
    if (!isConfirming && code.length > 5) {
      fieldsetRef.current
        .querySelector<HTMLInputElement>('input:last-child')
        .focus()
    }
  }, [isConfirming])

  const formPortal = (
    <Container justifyContent='center'>
      <Column
        alignItems='center'
        gap={32}
        maxWidth='22rem'
      >
        <BigScreen>
          <Text
            fontSize='md'
            textAlign='center'
          >
            Por tu seguridad y la de tus datos enviaremos un código a tu correo
            para verificar tu identidad.
          </Text>
        </BigScreen>

        <Container
          backgroundColor='var(--global-neutral-solid-colors-80, #F5F5F5)'
          borderRadius='.5em'
          justifyContent='center'
          maxWidth='20rem'
          padding='1rem 5rem'
        >
          <Text
            fontSize='lg'
            fontWeight='semibold'
          >
            {safeValue}
          </Text>
        </Container>

        <fieldset
          className={styles.codeInput}
          disabled={isConfirming}
          ref={fieldsetRef}
        >
          <CodeInput
            height={50}
            onChange={handleChange}
            size={{
              bigScreen: '3rem',
              smallScreen: '3rem'
            }}
            width={50}
          />
        </fieldset>

        <Row
          alignItems='center'
          borderRadius='.75rem'
          boxShadow='0px 4px 8px 0px rgba(27, 33, 56, 0.06)'
          gap={8}
          padding='1em'
        >
          <Icon
            color={getGlobalStyle('--color-feedback-warning-light')}
            name='Info'
            sizes='md'
          />

          <Text fontSize='sm'>
            Asegúrate de revisar las carpetas de promociones, spam o correo no
            deseado.
          </Text>
        </Row>

        <Text
          fontSize='md'
          textAlign='center'
        >
          ¿No recibiste el código? &nbsp;
          {countdown ? (
            `Pide uno nuevo en ${timedown}`
          ) : (
            <Link
              color='red'
              fontSize='md'
              fontWeight='semibold'
              href=''
              onClick={handleResendClick}
              underline='none'
            >
              Reenviar
            </Link>
          )}
        </Text>
      </Column>
    </Container>
  )

  return (
    <>
      <SmallScreen>
        <Container
          backgroundColor='white'
          justifyContent='center'
          minHeight='100vh'
          position='absolute'
          zIndex='1000'
        >
          <SignUpLayout
            headerPosition='sticky'
            logoImage={logoImage}
            onBackClick={onClose}
            onLogoClick={onClose}
          >
            <Column margin='-48px 0 0'>
              {errorMessage && (
                <ConfirmCodeToast
                  background={getGlobalStyle('--color-feedback-error-bg')}
                  iconColor={getGlobalStyle('--color-feedback-error-light')}
                  message={errorMessage}
                  onClick={onTooltipClick}
                />
              )}

              {infoMessage && (
                <ConfirmCodeToast
                  background={getGlobalStyle('--color-feedback-info-bg')}
                  iconColor={getGlobalStyle('--color-feedback-info')}
                  message={infoMessage}
                  onClick={onInfoToastClick}
                />
              )}
            </Column>

            <Column
              alignItems='center'
              gap={56}
            >
              <Column width='352px'>
                <Column
                  gap={8}
                  padding='0 4px'
                >
                  <Text fontWeight='semibold'>Código de verificación</Text>

                  <Text fontSize='md'>
                    Por tu seguridad y la de tus datos enviaremos un código a tu
                    correo para verificar tu identidad.
                  </Text>
                </Column>
              </Column>

              {formPortal}
            </Column>
          </SignUpLayout>
        </Container>
      </SmallScreen>

      <BigScreen>
        <Modal
          hiddenX
          iconPadding='1.625rem'
          isOpen={isOpen}
          maxWidth='50rem'
          padding='0 0 2rem 0'
          sizeIconClose={20}
          toggle={onClose}
          toggleOutside={onClose}
        >
          <Column gap={32}>
            <Column>
              <Row
                alignItems='center'
                justifyContent='center'
                padding='1.5rem'
              >
                <Text
                  fontSize='xl'
                  fontWeight='semibold'
                  textAlign='center'
                >
                  Código de verificación
                </Text>

                <Container
                  justifyContent='end'
                  padding='26px'
                  position='absolute'
                >
                  <Icon
                    clickable='pointer'
                    color='black'
                    customSize={20}
                    name='CloseThin'
                    onClick={onClose}
                  />
                </Container>
              </Row>

              <Divider
                borderHeight='3xs'
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={0}
              />

              {errorMessage && (
                <ConfirmCodeToast
                  background={getGlobalStyle('--color-feedback-error-bg')}
                  iconColor={getGlobalStyle('--color-feedback-error-light')}
                  message={errorMessage}
                  onClick={onTooltipClick}
                />
              )}

              {infoMessage && (
                <ConfirmCodeToast
                  background={getGlobalStyle('--color-feedback-info-bg')}
                  iconColor={getGlobalStyle('--color-feedback-info')}
                  message={infoMessage}
                  onClick={onInfoToastClick}
                />
              )}
            </Column>

            {formPortal}
          </Column>
        </Modal>
      </BigScreen>
    </>
  )
}
