import { Column, Picture } from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

export interface StaticBannerProps {
  imgUrl: string
  isMobile?: boolean
  onClickBanner?: () => void
}
const StaticBanner = ({ imgUrl, onClickBanner }: StaticBannerProps) => {
  return (
    <Column
      clickable={onClickBanner ? 'pointer' : null}
      id={getBemId('staticBanner', 'container')}
      onClick={onClickBanner}
    >
      <Picture
        hasURL={true}
        height='auto'
        lineHeight={0}
        src={imgUrl}
        width='100%'
      />
    </Column>
  )
}

export default StaticBanner
