import { IProductsV2 } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsV2'

export const mapProductsToOldSchema = (products: IProductsV2[]) => {
  return (
    products?.map((product) => {
      const price = String(product?.price?.price)
      const listPrice = String(product?.price?.listPrice)

      if (price !== 'undefined') {
        product.price.price = Number(price.replace(/[\D]/g, ''))
      }
      if (listPrice !== 'undefined') {
        product.price.listPrice = Number(listPrice.replace(/[\D]/g, ''))
      }

      return {
        ...product,
        ...product.item,
        price: product.price,
        promotion: product.promotion,
        detailUrl: product.item.slug,
        sellers: [product.price]
      }
    }) || []
  )
}
