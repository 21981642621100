import React from 'react'
import { ProductCertificates, Row } from '@smu-chile/pkg-unimarc-components'
import { Certificates as certificates } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
interface CertificatesProps {
  handleCertificates: () => void
  showMoreCertificates: boolean
  isLoading: boolean
  isMobile?: boolean
  certificates: Array<certificates>
}

export const Certificates = ({
  handleCertificates,
  showMoreCertificates,
  isLoading,
  isMobile = false,
  certificates
}: CertificatesProps) => {
  return (
    <Row>
      <ProductCertificates
        certificates={certificates}
        isLoading={isLoading}
        isMobile={isMobile}
        isOpen={showMoreCertificates}
        onClickShowLessMore={handleCertificates}
      />
    </Row>
  )
}
