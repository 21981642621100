import { getContentfulData } from '@smu-chile/pkg-unimarc-hooks'

export const getTyCAndPP = async () => {
  const resultTC = await getContentfulData({
    options: {
      content_type: 'terms-of-service',
      'fields.name': 'WEBclubahorro-tyc'
    }
  })

  const resultPP = await getContentfulData({
    options: {
      content_type: 'terms-of-service',
      'fields.name': 'WEBclubahorro-pp'
    }
  })

  const [tycResult] = resultTC.items
  const [ppResult] = resultPP.items

  return {
    ppRichtextData: [ppResult?.fields?.terms?.content ?? []],
    tycRichtextData: [tycResult?.fields?.terms?.content ?? []]
  }
}
