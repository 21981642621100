import {
  Carousel,
  Container,
  MobileModalWrapper,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData, useEvents } from '@smu-chile/pkg-unimarc-hooks'
import { useState } from 'react'
import { variableBreakdownCfBenefits } from '../helpers/variableBreakdownCfBenefits'
import styles from './HeaderClubBenefits.module.css'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

export const HeaderClubBenefitsModal = () => {
  const [show, setShow] = useState(false)

  const variables = variableBreakdownCfBenefits()

  const handleClose = () => {
    setShow(false)
  }

  const handleOpen = () => {
    setShow(true)
  }

  useEvents({
    eventType: 'closeClubBenefitsModal',
    callBack: () => {
      handleClose()
    }
  })

  useEvents({
    eventType: 'openClubBenefitsModal',
    callBack: () => {
      handleOpen()
    }
  })

  return (
    <MobileModalWrapper
      blockId={getBemId('header', 'benefits-modal')}
      body={
        <>
          <Container
            alignItems='center'
            customHeight='100%'
            justifyContent='center'
          >
            <Carousel
              arrows={false}
              autoPlay={false}
              containerClass={styles['carousel-container']}
              containerProps={{
                borderRadius: 'initial',
                maxHeight: '100%',
                maxWidth: '425px'
              }}
              infinite={false}
              itemClass={styles['carousel-item']}
              partialVisbile
              shouldResetAutoplay={false}
              showDots
              ssr
            >
              {isValidArrayWithData(variables.banners) &&
                variables.banners.map((banner) => {
                  return (
                    <Picture
                      alt='test-image'
                      height='auto'
                      key={banner.id}
                      objectFit='contain'
                      src={banner.pictureSrc}
                      width='100%'
                    />
                  )
                })}
            </Carousel>
          </Container>
        </>
      }
      bodyContainerProps={{
        padding: '0'
      }}
      dragProps={{
        isDraggable: false
      }}
      draggButton={false}
      hasFooter={false}
      hasShadow={false}
      headerCloseIconProps={{
        name: 'CloseThin',
        sizes: '2xs'
      }}
      headerTitle='Mis beneficios'
      hideHeaderDivider
      iconSize={0}
      isEnableButton={true}
      modalConfigsProps={{
        isAutoHeight: false,
        isOpen: show,
        minHeightFullScreen: 'calc(100vh - 150px)',
        maxHeightFullScreen: '800px',
        toggle: handleClose,
        toggleOutside: handleClose
      }}
      onClose={handleClose}
      styleProps={{
        padding: '0'
      }}
    />
  )
}
