import { Container } from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { ClubFeature } from './features/ClubFeature'

export enum ClubContentType {
  FeatureContentBanners = 'featureContentBanners',
  ViewBlock = 'viewBlock'
}

export type ClubContentProps = {
  items?: unknown[]
  isBanner?: boolean
}

export const ClubContent = ({ isBanner, items }: ClubContentProps) => {
  const itemProps = (item: unknown) => {
    const contentType = item?.['sys']?.['contentType']?.['sys']?.['id']
    const id = item?.['sys']?.['id']

    return {
      contentType,
      id
    }
  }

  const featureProps = (item: unknown) => {
    return {
      bottomBanner: item?.['bottomBanner'],
      imageBanner: item?.['imageBanner'],
      isBanner: isBanner,
      layout: item?.['layout'],
      topBanner: item?.['topBanner']
    }
  }

  return (
    <>
      {isValidArrayWithData(items) &&
        items.slice(0, 1).map((item) => {
          const { contentType, id } = itemProps(item)

          return (
            <Container key={id}>
              {contentType === ClubContentType.FeatureContentBanners && (
                <ClubFeature {...featureProps(item)} />
              )}
            </Container>
          )
        })}
    </>
  )
}
