import { Container } from '@smu-chile/pkg-unimarc-components'
import { ChildrenBaseContainerProps } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { useMemo } from 'react'

export type ClubBlockMobileContainer = ChildrenBaseContainerProps & {
  spacing?: number
}

export const ClubBlockMobileContainer = ({
  spacing,
  ...props
}: ClubBlockMobileContainer) => {
  const { customMargin, customWidth } = useMemo(() => {
    const customMargin = `0 -${spacing}px`
    const customWidth = `calc(100% + ${spacing * 2}px)`

    return {
      customMargin,
      customWidth
    }
  }, [spacing])

  return (
    <Container
      margin={customMargin}
      width={customWidth}
      {...props}
    />
  )
}
