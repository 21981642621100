import dynamic from 'next/dynamic'
import { Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  addToHomeData,
  useAndesMLClickEvent,
  UseQueryResult,
  visiblePromosScroll
} from '@smu-chile/pkg-unimarc-hooks'
import { ICategories } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICategories'
import { MonetiseBannerProps } from '../../MonetiseBanner'
import { bannerData } from 'shared/helpers/bannerData'

export const MonetiseBanner = dynamic<MonetiseBannerProps>(
  import('../../').then((mod) => {
    return mod.MonetiseBanner
  })
)

interface IMoneticeBanner extends MonetiseBannerProps {
  isLoadingPage: boolean
  resultAisles: UseQueryResult<ICategories, unknown>
}

export const MonetiseBannerPLP = ({
  data,
  images,
  imageAndes,
  isLoadingPage,
  padding,
  resultAisles,
  urlLink
}: IMoneticeBanner) => {
  const site = 'Unimarc'
  const { handleClickOnAndesMLBanner } = useAndesMLClickEvent()
  const catchMoneticeRefBanner = (ref: HTMLElement) => {
    if (data) {
      const promoData = bannerData(data)
      addToHomeData({ ref, promoData })
      visiblePromosScroll({ site })
    }
  }

  return (
    <>
      {resultAisles?.data && !isLoadingPage && (
        <MonetiseBanner
          catchMoneticeRefBanner={catchMoneticeRefBanner}
          data={data}
          handleClickOnAndesMLBanner={handleClickOnAndesMLBanner}
          imageAndes={imageAndes}
          images={images}
          padding={padding}
          urlLink={urlLink}
        />
      )}
      {!images && <Spacer.Horizontal size={24} />}
    </>
  )
}
