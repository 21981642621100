import {
  Banners,
  BannersLayoutEnum,
  Column,
  Container,
  Spacer,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { ClubFeatureProps } from './ClubFeature'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'

export type ClubFeatureGridProps = ClubFeatureProps

export const ClubFeatureGrid = ({
  imageBanner,
  title,
  subtitle
}: ClubFeatureGridProps) => {
  const hasHeader = !!subtitle || !!title

  const bannersProps = () => {
    return {
      bannersGrid: imageBanner.map((item) => {
        return {
          img: item?.['image']?.['fields']?.['file']?.['url']
        }
      })
    }
  }

  return (
    <Container>
      {/** desktop view */}
      <BigScreen>
        <Column>
          <Column alignItems='center'>
            {title && (
              <Title
                fontWeight='medium'
                headingLevel='h1'
                id={getBemId('clubFeatureGrid', 'title')}
              >
                {title}
              </Title>
            )}

            {subtitle && <Text lineHeight='24px'>{subtitle}</Text>}
          </Column>

          {hasHeader && <Spacer.Horizontal customSize={40} />}

          <Container>
            <Banners
              catchPromoData={() => {
                return
              }}
              imageBanner={imageBanner}
              layout={BannersLayoutEnum.Grid}
              minHeight='100px'
              {...bannersProps()}
            />
          </Container>
        </Column>
      </BigScreen>

      {/** mobile view */}
      <SmallScreen>
        <Column>
          <Column>
            {title && (
              <Text
                fontSize='lg'
                fontWeight='semibold'
                id={getBemId('clubFeatureGrid', 'title')}
                lineHeight='22px'
              >
                {title}
              </Text>
            )}

            {subtitle && <Text>{subtitle}</Text>}
          </Column>

          {hasHeader && <Spacer.Horizontal customSize={24} />}

          <Column
            alignItems='center'
            gap={8}
          >
            <Banners
              bannersGrid={bannersProps()
                .bannersGrid.slice(0, 2)
                .map((item) => {
                  return { ...item, minWidth: '100px' }
                })}
              catchPromoData={() => {
                return
              }}
              gap={8}
              layout={BannersLayoutEnum.Grid}
            />
            <Banners
              bannersGrid={bannersProps()
                .bannersGrid.slice(2, 4)
                .map((item) => {
                  return { ...item, minWidth: '100px' }
                })}
              catchPromoData={() => {
                return
              }}
              gap={8}
              layout={BannersLayoutEnum.Grid}
            />
          </Column>
        </Column>
      </SmallScreen>
    </Container>
  )
}
