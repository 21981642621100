import React from 'react'
import { Text } from '@smu-chile/pkg-unimarc-components'
import { degreesCleaner } from '../../../shared/helpers'

export interface ProductDetailFormatProp {
  container?: string
  validatedFormat?: string
  volume?: string
  degrees?: string
  isMobile?: boolean
}

export const ProductDetailFormat = ({
  container,
  validatedFormat,
  volume,
  degrees,
  isMobile
}: ProductDetailFormatProp): React.ReactElement => {
  const cleanedDegrees = degreesCleaner(degrees)

  const fontSize = isMobile ? 'md' : 'lg'

  if (degrees && volume) {
    return (
      <Text
        color='gray'
        fontSize={fontSize}
        fontWeight='regular'
        type='p'
      >
        {`${volume} - ${container} - ${cleanedDegrees}`}
      </Text>
    )
  }

  return (
    <Text
      color='gray'
      fontSize={fontSize}
      fontWeight='regular'
      type='p'
    >
      {validatedFormat}
    </Text>
  )
}
