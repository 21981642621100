import { Backbone, Column, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

const ShelfLoader = (): React.ReactElement => {
  return (
    <Row tagName='article'>
      <Column
        justifyContent='center'
        minHeight='30px'
        width='344px'
      >
        <Backbone
          backgroundColor={getGlobalStyle('--color-neutral-gray')}
          borderRadius={getGlobalStyle('--border-radius-xs')}
          height={120}
          width={100}
        />
      </Column>
    </Row>
  )
}

export const ShelvesLoader = (): React.ReactElement => {
  return (
    <Column
      gap='8px'
      padding='16px'
    >
      <ShelfLoader />
      <ShelfLoader />
      <ShelfLoader />
      <ShelfLoader />
      <ShelfLoader />
      <ShelfLoader />
    </Column>
  )
}
