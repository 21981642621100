import { useRouter } from 'next/router'
import {
  Container,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'
import { SignUpForm } from './SignUpForm'
import { SignUpLayout } from './SignUpLayout'
import { datalayerSimpleEvent } from '@smu-chile/pkg-unimarc-hooks'
import { useEffect } from 'react'

// default images: https://dummyimage.com/480x830/000/fff
export const SignUpPage = ({
  bannerImage,
  logoImage,
  ppRichtextData,
  tycRichtextData
}: {
  bannerImage?: string
  logoImage?: string
  ppRichtextData?: object[]
  tycRichtextData?: object[]
}) => {
  const router = useRouter()

  const handleLayoutBackClick = () => {
    router.back()
  }

  const handleLayoutLogoClick = () => {
    router.push('/')
  }

  useEffect(() => {
    datalayerSimpleEvent({
      event: 'register',
      eventAction: 'view',
      eventCategory: 'paso_1',
      eventLabel: 'enter_data'
    })
  }, [])

  return (
    <SignUpLayout
      headerPosition='sticky'
      logoImage={logoImage}
      onBackClick={handleLayoutBackClick}
      onLogoClick={handleLayoutLogoClick}
    >
      <Container
        justifyContent='center'
        padding='.75rem 0'
      >
        <Text
          fontSize='5xl'
          fontWeight='semibold'
        >
          ¡Regístrate aquí!
        </Text>
      </Container>

      <Row
        gap={32}
        justifyContent='center'
      >
        <SignUpForm
          logoImage={logoImage}
          ppRichTextData={ppRichtextData}
          tycRichTextData={tycRichtextData}
        />

        {bannerImage && (
          <BigScreen>
            <Picture
              borderRadius='1rem'
              height='auto'
              src={bannerImage}
              width='528'
            />
          </BigScreen>
        )}
      </Row>
    </SignUpLayout>
  )
}
