import { useEffect, useRef } from 'react'
import {
  Button,
  Column,
  Container,
  Picture,
  Text,
  Row,
  ButtonStatus
} from '@smu-chile/pkg-unimarc-components'
import { ICouponObject } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICoupon'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  formatDate,
  getCouponName,
  initCouponsImpression,
  TaggableCoupon
} from '@smu-chile/pkg-unimarc-hooks'

import { CouponDetailHeaderCenter } from './CouponDetailHeaderCenter/CouponDetailHeaderCenter'
import { CouponDetailHeaderStamp } from './CouponDetailHeaderStamp/CouponDetailHeaderStamp'

const COUPON_DEFAULT_IMAGE = process?.env?.NEXT_PUBLIC_COUPON_DEFAULT_IMAGE
const UNIPAY_IMAGE_URL = process?.env?.NEXT_PUBLIC_COUPON_UNIPAY_IMAGE

const CouponButtonBackgroundColorMap: Record<string, string> = {
  ACTIVATED: getGlobalStyle('--color-base-white'),
  LOADING: getGlobalStyle('--color-background-wewak')
}

const CouponButtonColorMap: Record<string, string> = {
  ACTIVATED: getGlobalStyle('--color-primary-light-carmine-pink')
}

export const CouponChannelColorMap: Record<string, string> = {
  all: '',
  'Solo App': getGlobalStyle('--color-campaigns-orange'),
  'Solo tienda': getGlobalStyle('--color-campaigns-blue')
}

const CouponGrayscaleMap: Record<string, boolean> = {
  UNACTIVATED: true,
  ACTIVATED: false,
  USED: false,
  LOADING: true
}

const CouponLabelMap: Record<string, string> = {
  UNACTIVATED: 'Activar cupón',
  ACTIVATED: 'Cupón activado',
  USED: 'Cupón utilizado'
}

const CouponStatusMap: Record<string, ButtonStatus> = {
  UNACTIVATED: 'initial',
  ACTIVATED: 'disabled-red',
  USED: 'disabled',
  LOADING: 'loading'
}

export interface CouponDetailHeaderProps {
  active?: boolean
  activeLoading?: boolean
  coupon?: ICouponObject
  selectedFilter?: string
  onActive?: () => void
}

export const CouponDetailHeader = ({
  activeLoading,
  coupon,
  selectedFilter,
  onActive
}: CouponDetailHeaderProps) => {
  const activeButtonRef = useRef<TaggableCoupon>(null)

  const {
    countUsed,
    image,
    endDate,
    maximumUses,
    status,
    channel,
    description,
    lead,
    maximumUnits,
    subTitle,
    title,
    unipay
  } = coupon

  const couponButtonColor = CouponButtonColorMap[status]
  const couponButtonBackgroundColor =
    CouponButtonBackgroundColorMap[activeLoading ? 'LOADING' : status]
  const couponChannelColor = CouponChannelColorMap[channel ?? 'all']
  const couponChannelLabel = channel ?? 'all'
  const couponImage = image !== '' ? image : COUPON_DEFAULT_IMAGE
  const couponGrayscale = CouponGrayscaleMap[status]
  const couponLabel = CouponLabelMap[status ?? 'UNACTIVATED']
  const couponStatus = CouponStatusMap[activeLoading ? 'LOADING' : status]
  const couponCheckIcon = couponStatus === 'disabled-red' ? 'CheckBorder' : null
  const couponNewLabel =
    couponStatus === 'loading' ? 'Activando cupón' : couponLabel

  const handleActive = () => {
    if (onActive) {
      onActive()
    }
  }

  const assignActiveCouponRef = (ref: HTMLButtonElement) => {
    if (coupon.status != 'UNACTIVATED') return
    activeButtonRef.current = {
      id: coupon.id,
      index: '1',
      name: getCouponName({
        title: coupon.title,
        subtitle: coupon.subTitle,
        description: coupon.description
      }),
      ref
    }
  }

  useEffect(() => {
    if (!activeButtonRef.current)
      return () => {
        return
      }
    const removeEventListener = initCouponsImpression({
      eventKey: 'active-button-detail',
      creativeName: 'activar-cupon',
      creativeSlot: '1',
      coupons: [activeButtonRef.current],
      locationId: 'detalle-cupon',
      couponType: selectedFilter
    })

    return removeEventListener
  }, [activeButtonRef.current])

  return (
    <CouponDetailHeaderStamp>
      <Container>
        <Row alignItems='center'>
          <BigScreen>
            <Column width='100px'>
              <Picture
                grayscale={couponGrayscale}
                height='100'
                src={couponImage}
                width='100'
              />
            </Column>
          </BigScreen>

          <CouponDetailHeaderCenter>
            <BigScreen>
              <Row>
                {couponChannelLabel != 'all' && (
                  <Container
                    backgroundColor={couponChannelColor}
                    borderRadius={'4px'}
                    padding='0 8px'
                    width={'auto'}
                  >
                    <Text
                      color='white'
                      fontSize='xs'
                      lineHeight='1.5'
                    >
                      {couponChannelLabel}
                    </Text>
                  </Container>
                )}

                {unipay && (
                  <Container
                    alignItems='center'
                    backgroundColor={getGlobalStyle(
                      '--color-campaigns-turquoise'
                    )}
                    borderRadius='4px'
                    margin='0 8px'
                    padding='0 8px'
                    width='auto'
                    zIndex='1'
                  >
                    <Text
                      color='black'
                      fontSize='xs'
                      lineHeight='1.5'
                    >
                      Exclusivo
                    </Text>

                    <Container margin='0 0 -5px 4px'>
                      <Picture
                        height='9'
                        src={UNIPAY_IMAGE_URL}
                        width='30'
                      />
                    </Container>
                  </Container>
                )}
              </Row>
            </BigScreen>

            <Text
              fontSize='xl'
              fontWeight='semibold'
              lineHeight='1.75'
            >
              {title}
            </Text>

            <Text
              color='gray'
              fontSize='sm'
            >
              {subTitle} {lead ? `(${lead})` : ''}
            </Text>

            <Text
              fontSize='md'
              fontWeight='semibold'
            >
              {description}
            </Text>

            <Column padding='4px 0 0'>
              <Text fontSize='sm'>Máximo {maximumUnits} por cliente</Text>

              <Text fontSize='sm'>
                Expira el: {formatDate(endDate)} - Has usado {countUsed}/
                {maximumUses} canjes
              </Text>
            </Column>

            <SmallScreen>
              <Container padding='8px 0 0'>
                <Button
                  background={couponButtonBackgroundColor}
                  borderRadius='24px'
                  color={couponButtonColor}
                  fontSize={getGlobalStyle('--font-size-md')}
                  fontWeight='500'
                  height='45px'
                  label={couponNewLabel}
                  minWidth='150px'
                  onClick={handleActive}
                  prevIcon={couponCheckIcon}
                  prevIconColor={couponButtonColor}
                  size='small'
                  status={couponStatus}
                  type='coupons'
                />
              </Container>
            </SmallScreen>
          </CouponDetailHeaderCenter>

          <SmallScreen>
            <Column
              alignItems='center'
              justifyContent='center'
              margin='0 0 0 16px'
              width='100px'
            >
              {unipay && (
                <Container
                  alignItems='center'
                  backgroundColor={getGlobalStyle(
                    '--color-campaigns-turquoise'
                  )}
                  borderRadius='4px'
                  margin='8px 0'
                  padding='0 8px'
                  width='auto'
                  zIndex='1'
                >
                  <Text
                    color='black'
                    fontSize='xs'
                    lineHeight='1.5'
                  >
                    Exclusivo
                  </Text>

                  <Container margin='0 0 -5px 4px'>
                    <Picture
                      height='9'
                      src={UNIPAY_IMAGE_URL}
                      width='30'
                    />
                  </Container>
                </Container>
              )}

              <Container margin='0 0 8px'>
                <Picture
                  grayscale={couponGrayscale}
                  height='100'
                  src={couponImage}
                  width='100'
                />
              </Container>

              {couponChannelLabel != 'all' && (
                <Container
                  alignItems='center'
                  backgroundColor={couponChannelColor}
                  borderRadius='4px'
                  margin='0 auto'
                  padding='0 8px'
                  width='auto'
                >
                  <Text
                    color='white'
                    fontSize='xs'
                    lineHeight='1.5'
                  >
                    {couponChannelLabel}
                  </Text>
                </Container>
              )}
            </Column>
          </SmallScreen>

          <BigScreen>
            <Column
              alignItems='end'
              margin='auto'
            >
              <Button
                background={couponButtonBackgroundColor}
                borderRadius='24px'
                buttonRef={assignActiveCouponRef}
                color={couponButtonColor}
                fontWeight='500'
                height='45px'
                label={couponNewLabel}
                onClick={handleActive}
                prevIcon={couponCheckIcon}
                prevIconColor={couponButtonColor}
                status={couponStatus}
                type='coupons'
              />
            </Column>
          </BigScreen>
        </Row>
      </Container>
    </CouponDetailHeaderStamp>
  )
}
