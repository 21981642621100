import { useEffect, useState } from 'react'
import { Column, Row, Spacer } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMiniCart } from '@smu-chile/pkg-unimarc-hooks'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMiniCart'
import { TooltipAlcohol } from 'components/TooltipAlcohol'
import { CTAClearCart } from './CTAClearCart'
import { Shelves } from './Shelves'

export const ContainerMiniCart = (): React.ReactElement => {
  const { data, isLoading } = useMiniCart()
  const [alcoholLaw, setAlcoholLaw] = useState(false)

  const orderForm: Data = data?.data

  useEffect(() => {
    if (orderForm?.needIdentification) {
      setAlcoholLaw(true)
      setTimeout(() => {
        setAlcoholLaw(false)
      }, 5000)
    }
  }, [isLoading, orderForm?.needIdentification])

  return (
    <>
      <Row
        position='sticky'
        zIndex={getGlobalStyle('--z-index-5')}
      >
        {alcoholLaw && (
          <Column maxWidth='max-content'>
            <TooltipAlcohol
              onClose={() => {
                return setAlcoholLaw(false)
              }}
            />
          </Column>
        )}
      </Row>
      <Row
        backgroundColor={getGlobalStyle('--color-base-white')}
        borderRadius={getGlobalStyle('--border-radius-md')}
        boxShadow={getGlobalStyle('--box-shadow-2xs')}
        tagName='section'
      >
        <Column>
          <Row tagName='section'>
            <Column tagName='section'>
              <Shelves />
            </Column>
          </Row>
        </Column>
      </Row>
      <Spacer.Horizontal size={8} />
      <CTAClearCart />
    </>
  )
}
