import {
  ApplyFiltersEntryProps,
  BodyPageProductsPropsFilterSelector,
  FoundAppliedFilterEntryProps,
  GenerateOptionsToFiltersProps,
  GetDataFiltersEntryProps,
  GetSubCategoriesFiltersProps
} from 'shared/interfaces/helpers'

export const OPTION_FILTERS_KEY = {
  FILTER: 'filter',
  BRAND: 'brand',
  CATEGORY: 'category',
  FORMAT: 'format',
  ORDERBY: 'orderBy'
}

const initialFilterOptionsValue = { checked: false, numberResult: 0 }

const foundAppliedFilter = ({
  value,
  applyFilters
}: FoundAppliedFilterEntryProps) => {
  const found = applyFilters.find((filter) => {
    return filter.value === value
  })?.checked
  return Boolean(found)
}

export const getDataFilters = ({
  filters,
  applyFilters
}: GetDataFiltersEntryProps): BodyPageProductsPropsFilterSelector['filters'] => {
  return [
    {
      title: 'Formato',
      options: generateOptionsToFilters({
        data: filters?.format,
        applyFilters,
        type: OPTION_FILTERS_KEY.FORMAT
      }),
      placeholderSearch: `Buscar formato`
    },
    {
      title: 'Marcas',
      options: generateOptionsToFilters({
        data: filters?.brands,
        applyFilters,
        type: OPTION_FILTERS_KEY.BRAND
      }),
      placeholderSearch: `Buscar marca`
    }
  ]
}

const generateOptionsToFilters = ({
  applyFilters,
  data,
  type
}: GenerateOptionsToFiltersProps) => {
  return (
    data
      ?.map((value) => {
        const checked = foundAppliedFilter({ value, applyFilters })
        return {
          ...initialFilterOptionsValue,
          type,
          checked,
          value
        }
      })
      ?.sort((a, b) => {
        return a.value?.localeCompare(b.value)
      }) ?? []
  )
}

export const getDataApplyFilters = (applyFilters: ApplyFiltersEntryProps[]) => {
  const data = Object.values(OPTION_FILTERS_KEY)
    .map((key) => {
      const data = applyFilters.find((item) => {
        return item?.type === key
      })?.value
      if (!data) return null
      return [key, data]
    })
    .filter((item) => {
      return item
    })

  return Object.fromEntries(data)
}

export const getQueryKeyToRequest = (
  data: (ApplyFiltersEntryProps | unknown)[]
): string | string[] => {
  const applyFilters = data as ApplyFiltersEntryProps[]
  if (applyFilters.length === 0) return ''
  return applyFilters.map((item) => {
    return item?.value
  })
}

export const getInitialFilters = (initialFilters: {
  [key: string]: string[] | string
}): ApplyFiltersEntryProps[] => {
  return Object.values(OPTION_FILTERS_KEY)
    .map((filter) => {
      let value = initialFilters?.[filter] || ''
      if (typeof value === 'object' || typeof value === 'function') {
        value = JSON.stringify(value)
      }
      return {
        ...initialFilterOptionsValue,
        checked: true,
        type: filter,
        value
      }
    })
    ?.filter((item) => {
      return item.value !== ''
    })
}

// This function processes the facetsFilterV2 object to extract filters for subcategories.
export const getSubCategoriesFilters = ({
  facetsFilterV2
}: GetSubCategoriesFiltersProps) => {
  // If the facetsFilterV2 is not provided, return an empty array immediately.
  if (!facetsFilterV2) return []

  // Get all property names from the facetsFilterV2 object.
  const propertyNames = Object.getOwnPropertyNames(facetsFilterV2)

  // Check if there is at least one property in the facetsFilterV2 object.
  if (propertyNames.length === 0) return []

  // Filter out property names that match the pattern "category".
  const categoryProperties = propertyNames.filter((type) => {
    return /category/.test(type)
  })

  // Map these category properties to their corresponding arrays in the facetsFilterV2 object.
  // Then flatten the array of arrays into a single array of category items.
  const categories = categoryProperties.flatMap((category) => {
    return facetsFilterV2[category]
  })

  // Map each category item to an object that contains a label and an onClick function.
  // The onClick function will call the provided callback with the category as an argument.
  const categoryFilters = categories.map((category) => {
    return {
      label: category
    }
  })

  // Return the array of filter options.
  return categoryFilters
}
