import NextImage from 'next/image'
import {
  ERROR_400_IMG,
  ERROR_404_IMG,
  ERROR_500_IMG_BREAD
} from 'shared/constants/contentfulAssets'
import { ErrorType } from '..'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'
import { Backbone, Picture, Row } from '@smu-chile/pkg-unimarc-components'

interface ErrorImageProps {
  backboneHeight?: number
  backboneWidth?: number
  errorType: ErrorType
  imageUrl: string
  imgHeight?: string
  imgWidth?: string
  isMobile?: boolean
}

export const ErrorImage = ({
  errorType,
  imageUrl,
  imgHeight = '649px',
  imgWidth = '667px',
  backboneHeight = 249,
  backboneWidth = 249
}: ErrorImageProps) => {
  // get contenful error image
  const errorImages: Record<ErrorType | '500', string> = {
    '400': ERROR_400_IMG[0],
    '404': ERROR_404_IMG[0],
    '500': ERROR_500_IMG_BREAD[0]
  }
  const getErrorImageId = (errorType: ErrorType | '500'): string => {
    return errorImages[errorType] || errorImages['500']
  }
  const errorImage = useContentful({
    options: {
      'sys.id': getErrorImageId(errorType)
    },
    type: 'assets'
  })
  const image =
    imageUrl !== ''
      ? imageUrl
      : errorImage?.data?.['items'][0]?.fields?.file?.url

  return errorImage.isLoading ? (
    <Row maxWidth='250px'>
      <Backbone
        borderRadius='8px'
        height={backboneHeight}
        width={backboneWidth}
      />
    </Row>
  ) : (
    <Picture
      alt='imagen de error de un donuts'
      height={imgHeight}
      nextImage={NextImage}
      src={image}
      title='imagen de error de un donuts'
      url={image}
      width={imgWidth}
    />
  )
}
