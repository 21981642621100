import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  IModalMembershipLevelQueryContentProps,
  ModalMembershipLevelQueryContent
} from './components/ModalMembershipLevelQueryContent'
import { useQueryLevelMembershipForm } from './helpers/useQueryLevelMembershipForm'
import styles from './index.module.css'

export interface ModalMembershipLevelQueryWrapperProps {
  isBlockedBySecurity: boolean
  isLoading: boolean
  handleToggle: () => void
  handleContinue: (rut: string, email?: string) => void
}
export const MODAL_MEMBERSHIP_LEVEL_QUERY_WRAPPER_ID = {
  MOBILE: 'modalMembershipLevelQueryMobile',
  DESKTOP: 'modalMembershipLevelQueryDesktop'
}
export const ModalMembershipLevelQueryWrapper = ({
  isBlockedBySecurity,
  isLoading,
  handleToggle,
  handleContinue
}: ModalMembershipLevelQueryWrapperProps): React.ReactElement => {
  const queryLevelFormHandlers = useQueryLevelMembershipForm()

  const handleKnowMyLevel = (rut: string, email?: string) => {
    handleContinue(rut, email)
  }
  const modalMembershipLevelQueryContentProps: IModalMembershipLevelQueryContentProps =
    {
      ...queryLevelFormHandlers,
      isBlockedBySecurity,
      isLoading,
      blockId: MODAL_MEMBERSHIP_LEVEL_QUERY_WRAPPER_ID.MOBILE,
      handleKnowMyLevel
    }
  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={MODAL_MEMBERSHIP_LEVEL_QUERY_WRAPPER_ID.DESKTOP}
          bodyChildrenProps={{
            padding: '38px 49px'
          }}
          borderEdge='none'
          hasfooter={false}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerTitleProps={{
            fontWeight: 'medium'
          }}
          modalConfigsProps={{
            maxWidth: '800px',
            isWindowBlocked: true,
            maxHeight: '440px',
            minHeight: '450px',
            isOpen: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          styleProps={{
            customHeightModalContent: '100%'
          }}
        >
          <ModalMembershipLevelQueryContent
            {...modalMembershipLevelQueryContentProps}
          />
        </DesktopModalWrapper>
      </BigScreen>

      <SmallScreen>
        <MobileModalWrapper
          blockId={MODAL_MEMBERSHIP_LEVEL_QUERY_WRAPPER_ID.MOBILE}
          body={
            <ModalMembershipLevelQueryContent
              {...modalMembershipLevelQueryContentProps}
            />
          }
          bodyContainerProps={{
            customClassName: styles['modalBalanceInquiryMobile__body'],
            overflow: 'visible'
          }}
          buttonConfigsProps={{
            fontWeight: getGlobalStyle('--font-weight-medium'),
            background: getGlobalStyle('--color-primary-red2'),
            label: 'Pagar',
            isLoading: false
          }}
          dragProps={{
            draggButton: false
          }}
          hasFooter={false}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          hideHeaderDivider={false}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={16}
          isEnableButton
          isOpen={true}
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
