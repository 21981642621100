import {
  Banners,
  BannersLayoutEnum,
  Column,
  Container,
  Icon,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { ClubBlockProps } from './ClubBlock'
import {
  isValidArrayWithData,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './ClubBlockDiscounts.module.css'

export const ClubBlockDiscountsBlockIds = {
  clubunimarc: 'Socio Club',
  oro: 'Socio Oro',
  platino: 'Socio Platino',
  diamante: 'Socio Diamante'
}

export type ClubBlockDiscountsProps = ClubBlockProps

export const ClubBlockDiscounts = ({
  description,
  items
}: ClubBlockDiscountsProps) => {
  const user = useUserWithMembership({
    staleTime: 1000 * 60 * 60
  })

  const userMembership = user?.data?.level ?? 'clubunimarc'

  const searchMembershipBlock = () => {
    const blockId = ClubBlockDiscountsBlockIds[userMembership]

    if (isValidArrayWithData(items)) {
      return items.filter((item) => {
        return item?.['blockId'].includes(`${blockId}`)
      })
    }

    return []
  }

  const mapMembershipBanners = (items?: unknown[]) => {
    if (isValidArrayWithData(items)) {
      return items.map((item: unknown) => {
        return {
          img: item?.['image']?.['fields']?.['file']?.['url']
        }
      })
    }

    return []
  }

  const [block] = searchMembershipBlock()
  const banners = mapMembershipBanners(block?.['items'] ?? [])

  return (
    <Container id={getBemId('ClubBlockDiscounts', 'container')}>
      <Column gap={24}>
        {description && (
          <Row alignItems='center'>
            <Container
              id={getBemId('ClubBlockDiscounts', 'icon')}
              justifyContent='center'
              width='26px'
            >
              <Icon
                customSize={26}
                name='Coupon6'
              />
            </Container>

            <BigScreen>
              <Text
                fontSize='2xl'
                fontWeight='semibold'
                id={getBemId('ClubBlockDiscounts', 'title')}
              >
                {description}
              </Text>
            </BigScreen>

            <SmallScreen>
              <Text
                fontSize='lg'
                fontWeight='medium'
                id={getBemId('ClubBlockDiscounts', 'title')}
              >
                {description}
              </Text>
            </SmallScreen>
          </Row>
        )}

        <BigScreen>
          <Container
            margin='0 -8px'
            width='calc(100% + 16px)'
          >
            <Banners
              bannersCarousel={banners}
              itemsToShow={2}
              layout={BannersLayoutEnum.Carousel}
              marginArrows='0px'
              partialVisible={banners.length > 2 ? 172 : 0}
            />
          </Container>
        </BigScreen>

        <SmallScreen>
          <Container
            customClassName={styles['clubBlockDiscounts__container']}
            margin='0 -16px 0 -16px'
            width='calc(100% + 32px)'
          >
            <Banners
              bannersCarousel={banners}
              itemsToShow={2}
              layout={BannersLayoutEnum.Carousel}
              marginArrows='0px'
              partialVisible={banners.length > 2 ? 172 : 0}
              zoomIn={false}
            />
          </Container>
        </SmallScreen>
      </Column>
    </Container>
  )
}
