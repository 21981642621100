import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'

export const ClubBackbone = () => {
  const renderBackboneItem = (
    circleRadius: number,
    primaryItemWidth: number,
    secondaryItemWidth: number
  ) => {
    return (
      <Row
        alignItems='center'
        gap={20}
      >
        <Container
          borderRadius={`${circleRadius}px`}
          overflow='hidden'
          width={circleRadius}
        >
          <Backbone height={circleRadius} />
        </Container>

        <Column
          gap={8}
          width='auto'
        >
          <Container
            borderRadius='20px'
            overflow='hidden'
            width={primaryItemWidth}
          >
            <Backbone height={14} />
          </Container>

          <Container
            borderRadius='20px'
            overflow='hidden'
            width={secondaryItemWidth}
          >
            <Backbone height={14} />
          </Container>
        </Column>
      </Row>
    )
  }

  return (
    <Container id={getBemId('clubBackbone', 'container')}>
      <BigScreen>
        <Column
          gap={80}
          id={getBemId('clubBackbone', 'bigScreen')}
        >
          <Container overflow='hidden'>
            <Backbone height={360} />
          </Container>

          <Container justifyContent='center'>
            <Column
              gap={80}
              maxWidth='1200px'
            >
              <Row
                alignItems='center'
                justifyContent='between'
              >
                <Container
                  borderRadius='24px'
                  overflow='hidden'
                  width={524}
                >
                  <Backbone height={417} />
                </Container>

                <Container width={542}>
                  <Column gap={40}>
                    <Container
                      borderRadius='30px'
                      overflow='hidden'
                      width={410}
                    >
                      <Backbone height={21} />
                    </Container>

                    <Column gap={30}>
                      {renderBackboneItem(60, 238, 290)}

                      <Row
                        alignItems='center'
                        gap={20}
                      >
                        <Container
                          borderRadius='60px'
                          overflow='hidden'
                          width={60}
                        >
                          <Backbone height={60} />
                        </Container>

                        <Column
                          gap={8}
                          width='auto'
                        >
                          <Container
                            borderRadius='20px'
                            overflow='hidden'
                            width={437}
                          >
                            <Backbone height={14} />
                          </Container>
                        </Column>
                      </Row>

                      {renderBackboneItem(60, 238, 290)}
                    </Column>
                  </Column>
                </Container>
              </Row>

              <Container
                borderRadius='24px'
                overflow='hidden'
              >
                <Backbone height={60} />
              </Container>

              <Spacer.Horizontal size={16} />
            </Column>
          </Container>
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column id={getBemId('clubBackbone', 'smallScreen')}>
          <Container overflow='hidden'>
            <Backbone height={127} />
          </Container>

          <Container justifyContent='center'>
            <Column
              gap={24}
              padding='24px'
            >
              <Container
                borderRadius='24px'
                overflow='hidden'
              >
                <Backbone height={272} />
              </Container>

              <Row
                alignItems='center'
                justifyContent='between'
              >
                <Container width={542}>
                  <Column gap={26}>
                    <Container
                      borderRadius='30px'
                      overflow='hidden'
                      width={187}
                    >
                      <Backbone height={16} />
                    </Container>

                    <Column gap={16}>
                      <Row
                        alignItems='center'
                        gap={20}
                      >
                        <Container
                          borderRadius='32px'
                          overflow='hidden'
                          width={32}
                        >
                          <Backbone height={32} />
                        </Container>

                        <Column
                          gap={4}
                          width='auto'
                        >
                          <Container
                            borderRadius='16px'
                            overflow='hidden'
                            width={172}
                          >
                            <Backbone height={10} />
                          </Container>

                          <Container
                            borderRadius='16px'
                            overflow='hidden'
                            width={232}
                          >
                            <Backbone height={10} />
                          </Container>
                        </Column>
                      </Row>

                      <Row
                        alignItems='center'
                        gap={20}
                      >
                        <Container
                          borderRadius='32px'
                          overflow='hidden'
                          width={32}
                        >
                          <Backbone height={32} />
                        </Container>

                        <Column
                          gap={4}
                          width='auto'
                        >
                          <Container
                            borderRadius='16px'
                            overflow='hidden'
                            width={141}
                          >
                            <Backbone height={10} />
                          </Container>

                          <Container
                            borderRadius='16px'
                            overflow='hidden'
                            width={205}
                          >
                            <Backbone height={10} />
                          </Container>
                        </Column>
                      </Row>

                      <Row
                        alignItems='center'
                        gap={20}
                      >
                        <Container
                          borderRadius='32px'
                          overflow='hidden'
                          width={32}
                        >
                          <Backbone height={32} />
                        </Container>

                        <Column
                          gap={4}
                          width='auto'
                        >
                          <Container
                            borderRadius='16px'
                            overflow='hidden'
                            width={203}
                          >
                            <Backbone height={10} />
                          </Container>

                          <Container
                            borderRadius='16px'
                            overflow='hidden'
                            width={195}
                          >
                            <Backbone height={10} />
                          </Container>
                        </Column>
                      </Row>
                    </Column>
                  </Column>
                </Container>
              </Row>

              <Container
                borderRadius='12px'
                overflow='hidden'
              >
                <Backbone height={60} />
              </Container>
            </Column>
          </Container>
        </Column>
      </SmallScreen>
    </Container>
  )
}
