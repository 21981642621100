import {
  Container,
  Backbone,
  Row,
  Divider,
  Column,
  Spacer,
  BackboneShelves
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import React from 'react'
import { MobileBackBone } from './MobileBackBone'
import styles from './ProductDetailModal.module.css'

const BackBoneHeader = () => {
  return (
    <Column>
      <Divider
        color={getGlobalStyle('--color-loaders-background')}
        verticalMargin={18}
      />
      <Row maxWidth='546px'>
        <Backbone
          borderRadius='8px'
          height={30}
        />
      </Row>
      <Divider
        color={getGlobalStyle('--color-loaders-background')}
        verticalMargin={18}
      />
    </Column>
  )
}

const BackBoneDetail = () => {
  return (
    <Row
      isWrap
      maxWidth='350px'
    >
      <Container justifyContent='between'>
        <Row
          gap='8px'
          isWrap
          maxWidth='245px'
        >
          <Row maxWidth='71px'>
            <Backbone
              borderRadius='5px'
              height={20}
            />
          </Row>
          <Row maxWidth='245px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
          <Row maxWidth='71'>
            <Backbone
              borderRadius='5px'
              height={15}
            />
          </Row>
          <Row maxWidth='171px'>
            <Backbone
              borderRadius='5px'
              height={15}
            />
          </Row>
          <Row maxWidth='171px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
        </Row>
        <Row
          gap='5px'
          isWrap
          maxWidth='50px'
        >
          <Row maxWidth='30px'>
            <Backbone
              borderRadius='20px'
              height={30}
            />
          </Row>
          <Row maxWidth='30px'>
            <Backbone
              borderRadius='20px'
              height={30}
            />
          </Row>
        </Row>
      </Container>
      <Container isWrap>
        <Row
          maxWidth='358px'
          padding='27px 0'
        >
          <Backbone height={30} />
        </Row>
        <Row justifyContent='between'>
          <Row maxWidth='171px'>
            <Backbone height={30} />
          </Row>
          <Row
            margin='10px 0 0 0'
            maxWidth='59px'
          >
            <Backbone height={15} />
          </Row>
        </Row>
        <Divider
          color={getGlobalStyle('--color-loaders-background')}
          verticalMargin={15}
        />
        <Row
          gap='17'
          isWrap
          justifyContent='between'
        >
          <Row
            maxWidth='171px'
            padding='0 0 10px'
          >
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
          <Row maxWidth='171px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
          <Row maxWidth='112px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
          <Row maxWidth='112px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
          <Row maxWidth='112px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
        </Row>
        <Spacer size={32} />
        <Row gap='7px'>
          <Row maxWidth='30px'>
            <Backbone
              borderRadius='20px'
              height={30}
            />
          </Row>
          <Row maxWidth='205px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
        </Row>
        <Divider
          color={getGlobalStyle('--color-loaders-background')}
          verticalMargin={15}
        />
        <Row gap='20px'>
          <Row maxWidth='84px'>
            <Backbone
              borderRadius='50%'
              height={84}
            />
          </Row>
          <Row maxWidth='84px'>
            <Backbone
              borderRadius='50%'
              height={84}
            />
          </Row>
          <Row maxWidth='84px'>
            <Backbone
              borderRadius='50%'
              height={84}
            />
          </Row>
        </Row>
      </Container>
    </Row>
  )
}

const BackBoneDescription = () => {
  return (
    <Container
      justifyContent='between'
      width='100%'
    >
      <Column>
        <Row isWrap>
          <Row gap='7px'>
            <Row maxWidth='30px'>
              <Backbone
                borderRadius='20px'
                height={30}
              />
            </Row>
            <Row maxWidth='205px'>
              <Backbone
                borderRadius='5px'
                height={30}
              />
            </Row>
          </Row>
          <Row
            gap='5px'
            isWrap
            maxWidth='375px'
            padding='25px 0 0 0'
          >
            <Row>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
            <Row>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
            <Row maxWidth='324px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
        </Row>
        <Row
          isWrap
          padding='45px 0 0 0'
        >
          <Row gap='7px'>
            <Row maxWidth='30px'>
              <Backbone
                borderRadius='20px'
                height={30}
              />
            </Row>
            <Row maxWidth='205px'>
              <Backbone
                borderRadius='5px'
                height={30}
              />
            </Row>
          </Row>
          <Row
            gap='5px'
            isWrap
            maxWidth='375px'
            padding='25px 0 0 0'
          >
            <Row>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
            <Row>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
            <Row maxWidth='324px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
        </Row>
        <Row
          isWrap
          padding='45px 0 0 0'
        >
          <Row gap='7px'>
            <Row maxWidth='30px'>
              <Backbone
                borderRadius='20px'
                height={30}
              />
            </Row>
            <Row maxWidth='205px'>
              <Backbone
                borderRadius='5px'
                height={30}
              />
            </Row>
          </Row>
          <Row
            gap='5px'
            isWrap
            maxWidth='375px'
            padding='25px 0 0 0'
          >
            <Row>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
            <Row>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
            <Row maxWidth='324px'>
              <Backbone
                borderRadius='5px'
                height={15}
              />
            </Row>
          </Row>
        </Row>
      </Column>
      <div
        style={{
          display: 'flex',
          borderLeft: `1px solid ${getGlobalStyle(
            '--color-loaders-background'
          )}`,
          alignSelf: 'stretch',
          margin: '0 30px'
        }}
      ></div>
      <Column padding='0 0 0 30px'>
        <Row gap='7px'>
          <Row maxWidth='30px'>
            <Backbone
              borderRadius='20px'
              height={30}
            />
          </Row>
          <Row maxWidth='205px'>
            <Backbone
              borderRadius='5px'
              height={30}
            />
          </Row>
        </Row>
        <Row
          maxWidth='328px'
          padding='20px 0 0 0'
        >
          <Backbone height={535} />
        </Row>
      </Column>
    </Container>
  )
}

type backBoneType = { isMobile: boolean }
export const BackBoneModal = ({ isMobile = false }: backBoneType) => {
  if (isMobile) {
    return <MobileBackBone />
  }

  return (
    <>
      <Container
        isWrap
        maxWidth='820px'
        padding='27px 24px 0 24px'
        width='820px'
      >
        <Row maxWidth='546px'>
          <Backbone
            borderRadius='12px'
            height={30}
          />
        </Row>
        <Divider
          color={getGlobalStyle('--color-loaders-background')}
          verticalMargin={20}
        />
        <Row
          justifyContent='between'
          maxWidth='800px'
        >
          <Row
            isWrap
            padding='0 30px'
          >
            {/* image */}
            <Row>
              <Row maxWidth='250px'>
                <Backbone
                  borderRadius='12px'
                  height={250}
                />
              </Row>
            </Row>
            <Row
              gap='15px'
              maxHeight='50px'
              padding='26px 0 0 38px'
            >
              {Array(3).map((index) => {
                return (
                  <Row
                    key={index}
                    maxWidth='50px'
                  >
                    <Backbone
                      borderRadius='5px'
                      height={50}
                    />
                  </Row>
                )
              })}
            </Row>
          </Row>

          {/* Product detail */}
          <BackBoneDetail />
        </Row>
        <BackBoneHeader />
        <BackBoneDescription />
        <BackBoneHeader />
        <BackboneShelves
          shelvesForRow={4}
          shelvesQuantityDesktop={4}
        />
        <BackBoneHeader />
        <BackboneShelves
          shelvesForRow={4}
          shelvesQuantityDesktop={4}
        />
        <Spacer size={32} />
      </Container>
      <Row
        alignItems='end'
        backgroundColor={getGlobalStyle('--color-base-white')}
        borderRadius='0px 0px 12px 12px'
        boxShadow='0px 0px 6px rgba(27, 33, 56, 0.15)'
        customClassName={styles.containerStickyButtons}
        gap={16}
        maxWidth='850px'
      >
        <Row maxWidth='400px'>
          <Backbone
            borderRadius='43px'
            height={45}
          />
        </Row>
        <Row maxWidth='400px'>
          <Backbone
            borderRadius='43px'
            height={45}
          />
        </Row>
      </Row>
    </>
  )
}
