import {
  Column,
  Container,
  Icon,
  Row,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useRef } from 'react'
import useScrollListener from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/helpers/useScrollListener'
import styles from '../../AddToListModalWrapper/components/TooltipAddOrderToList/TooltipAddOrderToList.module.css'

export interface TooltipAddressChangedProps {
  onClose: () => void
}

export const TooltipAddressChanged = ({
  onClose
}: TooltipAddressChangedProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const scroll = useScrollListener()
  const tooltipRef = useRef(null)

  useEffect(() => {
    if (!isMobile) {
      if (scroll.y > scroll.lastY) {
        tooltipRef?.current?.classList?.remove(styles['hide__tootip'])
        tooltipRef?.current?.classList?.add(styles['default__tooltip'])
      } else {
        tooltipRef?.current?.classList?.remove(styles['default__tooltip'])
        tooltipRef?.current?.classList?.add(styles['hide__tooltip'])
      }
    }
  }, [scroll.y, scroll.lastY])

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 4000)
  }, [])

  return (
    <Container
      left='0'
      margin='0 auto'
      maxWidth={getGlobalStyle('--width-max-desktop')}
      position='fixed'
      ref={tooltipRef}
      right='0'
      top={isMobile ? '103' : '80'}
      zIndex={getGlobalStyle('--z-index-999')}
    >
      <Tooltip
        alignAllItems='center'
        background={getGlobalStyle('--color-feedback-success-bg')}
        borderRadius={getGlobalStyle('--border-radius-2sm')}
        boxShadow={getGlobalStyle('--box-shadow-3md')}
        closeColor={getGlobalStyle('--color-icons-black')}
        customJustifyContent='end'
        display='flex'
        height='56px'
        iconMargin='0 4px 0 0'
        left={isMobile ? '0' : ''}
        margin={isMobile ? '0 auto' : ''}
        maxWidth='343px'
        onClick={onClose}
        padding='0 8px'
        position='absolute'
        positionContainer='sticky'
        right='0'
        textComponent={
          <Container>
            <Row
              alignItems='center'
              gap='8px'
            >
              <Column maxWidth='max-content'>
                <Icon
                  color={getGlobalStyle('--color-feedback-success-light')}
                  customSize={20}
                  name='CheckCircle'
                />
              </Column>
              <Column margin='0 8px 0 0'>
                <Text
                  color='black'
                  display='inline-block'
                  fontSize='md'
                  fontWeight='medium'
                >
                  Tu dirección fue actualizada.
                </Text>
              </Column>
              <Column
                clickable='pointer'
                maxWidth='35px'
                onClick={onClose}
                padding='10px'
              >
                <Icon
                  clickable='pointer'
                  color={getGlobalStyle('--color-neutral-gray-dark')}
                  customSize={14}
                  name='Close'
                />
              </Column>
            </Row>
          </Container>
        }
        type='bottom'
        width='343px'
      />
    </Container>
  )
}
