import Image from 'next/future/image'
import NextLink from 'next/link'
import { Column, Link, Picture } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  addToHomeData,
  clickedPromos,
  sleep,
  useMobile,
  useOrderForm,
  visiblePromosScroll
} from '@smu-chile/pkg-unimarc-hooks'
import { bannerData } from '../../shared/helpers/bannerData'
import {
  IAndesML,
  TPostAndesMlSponsoredBannersClickEventProps
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IAndesML'

export interface ImagesProps {
  desktop?: string
  mobile?: string
}
export interface MonetiseBannerProps {
  data?: TMonetiseBannerDataProps
  images: ImagesProps[]
  imageAndes?: string
  padding: string
  urlLink: string
  catchMoneticeRefBanner?: (props?: object) => void
  handleClickOnAndesMLBanner?: (
    props?: TPostAndesMlSponsoredBannersClickEventProps
  ) => void
}
export type TMonetiseBannerDataProps = IAndesML[0] & {
  alt?: string
  creativeName?: string
  creativeSlot?: string
  locationId?: string
  promotionId?: string
  promotionName?: string
  reference?: string
  referenceType?: string
  url?: string
  urlPath?: string
  saleChannels?: string | string[]
}

export const MonetiseBanner = ({
  handleClickOnAndesMLBanner,
  catchMoneticeRefBanner,
  data,
  images,
  imageAndes,
  padding,
  urlLink
}: MonetiseBannerProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const borderRadius = isMobile ? '12px' : '8px'
  const site = 'Unimarc'
  const orderForm = useOrderForm()
  const dataOrderform = orderForm?.data
  const image = isMobile
    ? images?.find((img) => {
        return img.mobile
      })?.mobile
    : images?.find((img) => {
        return img.desktop
      })?.desktop

  const catchMonetiseRef = (ref) => {
    if (typeof catchMoneticeRefBanner === 'function')
      catchMoneticeRefBanner(ref)
    if (data) {
      const promoData = bannerData(data)
      addToHomeData({ ref, promoData })
      visiblePromosScroll({ site })
    }
  }
  const handleRedirect = (promosData) => {
    if (promosData) {
      const promoData = bannerData(promosData)
      clickedPromos({ ...promoData, site, orderForm: dataOrderform })
    }
  }

  return (
    <>
      {(imageAndes || image) && (
        <Column
          alignItems='center'
          id={getBemId('monetiseBanner', 'image', imageAndes || image)}
          maxWidth={getGlobalStyle('--width-max-desktop')}
          padding={padding}
        >
          <Link
            clickable={urlLink !== undefined}
            href={urlLink}
            onClick={async () => {
              if (typeof handleClickOnAndesMLBanner === 'function') {
                handleClickOnAndesMLBanner({
                  adId: data?.adId,
                  campaignId: data?.campaignId,
                  adCost: parseInt(data?.providerMetaData?.effective_ad_cost),
                  searchedCategory: data?.searchedCategory,
                  searchedText: data?.searchText
                })
                await sleep(1500)
                handleRedirect(data)
                return
              }
              handleRedirect({ ...data, site })
            }}
            underline='none'
            wrapper={NextLink}
          >
            <Picture
              alt={data?.alt || 'banner'}
              borderRadius={borderRadius}
              catchImgRef={catchMonetiseRef}
              height='100%'
              nextImage={Image}
              priority
              src={imageAndes || image}
              style={{ cursor: 'pointer' }}
              width='100%'
            />
          </Link>
        </Column>
      )}
    </>
  )
}
