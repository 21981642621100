import classNames from 'classnames'
import { Column, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './SignUpSwitch.module.css'

export interface SignUpSwitchProps {
  onClick?: () => void
  value?: boolean
}

export const SignUpSwitch = ({ onClick, value }: SignUpSwitchProps) => {
  return (
    <Row
      backgroundColor={classNames({
        [getGlobalStyle('--color-neutral-gray-divider')]: !value,
        [getGlobalStyle('--color-neutral-black')]: value
      })}
      borderRadius='16px'
      clickable='pointer'
      minWidth='52px'
      onClick={onClick}
      padding='2px'
    >
      <Column
        backgroundColor={getGlobalStyle('--color-base-white')}
        borderRadius='15px'
        clickable='pointer'
        customClassName={styles['sign-up-switch__container']}
        margin={value ? '0 0 0 24px' : '0 24px 0 0'}
        maxWidth='24px'
        minHeight='24px'
        onClick={onClick}
      />
    </Row>
  )
}
