import { getContentfulData } from '@smu-chile/pkg-unimarc-hooks'

export async function getContentfulMembershipLevelBanner() {
  const data = await getContentfulData({
    options: {
      content_type: 'featureContentBanners',
      'fields.idFormato': 1,
      'fields.label[in]': 'membershipModalUnipay',
      include: 5
    }
  })

  const banners = data?.items?.[0]?.fields?.imageBanner.map((entry) => {
    const banner = data?.includes?.Entry.find((imageBannerEntry) => {
      return imageBannerEntry?.sys?.id === entry?.sys?.id
    })
    const image = data?.includes?.Asset.find((imageAsset) => {
      return imageAsset?.sys?.id === banner?.fields?.image?.sys?.id
    })

    return {
      imageUrl: `https:${image?.fields?.file?.url}`,
      referenceWeb: banner?.fields?.referenceWeb,
      label: banner?.fields?.label
    }
  })

  return banners
}
