import { Acordeon, Container } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { formatDate } from '@smu-chile/pkg-unimarc-hooks'
import styles from './CouponDetailLegal.module.css'

export interface CouponDetailLegalProps {
  couponEndDate?: string
  couponLegal?: string
  couponStartDate?: string
}

export const CouponDetailLegal = ({
  couponEndDate,
  couponLegal,
  couponStartDate
}: CouponDetailLegalProps) => {
  const formatLegal = (strLegal: string) => {
    if (strLegal) {
      return strLegal
        .replace('{FECHA_INICIO}', formatDate(couponStartDate))
        .replace('{FECHA_FIN}', formatDate(couponEndDate))
    }

    return ''
  }

  return (
    <Acordeon
      alignItemsIcon='end'
      fontWeightText='regular'
      maxWidthText='100%'
      positionText='bottom'
      titleClose='Bases Legales'
      titleOpen='Bases Legales'
      titleProps={{
        buttonProps: {
          type: 'plain'
        },
        containerProps: {
          backgroundColor: getGlobalStyle('--color-neutral-white'),
          border: `1px solid ${getGlobalStyle('--color-neutral-gray')}`,
          borderRadius: '32px',
          padding: '0 24px'
        }
      }}
    >
      <Container
        className={styles.container}
        color={getGlobalStyle('--color-text-black')}
        margin='8px 0 0 24px'
        padding='8px'
      >
        {formatLegal(couponLegal)}
      </Container>
    </Acordeon>
  )
}
