import { Container, CouponV2 } from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export interface CouponSimpleListLoaderProps {
  length?: number
}

export const CouponSimpleListLoader = ({
  length = 9
}: CouponSimpleListLoaderProps) => {
  const { isMobile } = useMobile()
  return (
    <>
      {[...Array(length)].map((key) => {
        return (
          <Container
            customHeight='100%'
            key={key}
          >
            <CouponV2
              couponStatus={''}
              cutterHeight={isMobile ? 16 : 24}
              expiration={''}
              imageUrl={''}
              isLoading={true}
              isMobile={isMobile}
              subtitle={''}
              title={''}
              unitsPerClient={''}
            />
          </Container>
        )
      })}
    </>
  )
}
