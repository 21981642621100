import NextLink from 'next/link'
import { Button, Link } from '@smu-chile/pkg-unimarc-components'
import { SubcategoriesDataProps } from '..'

export interface LinkComponentProps extends SubcategoriesDataProps {
  handleClickPill: (props: { [key: string]: unknown }) => void
}

export const LinkComponent = ({
  defaultUrl,
  url,
  label,
  onClick,
  handleClickPill
}: LinkComponentProps) => {
  const hrefToSend = defaultUrl || `/category${url}`

  const handleOnClick = () => {
    handleClickPill({ label: label })
    if (onClick) onClick()
  }

  if (!url && !defaultUrl) {
    return (
      <Button
        label={label}
        minWidth='auto'
        onClick={handleOnClick}
        type='sub-category'
      />
    )
  }

  return (
    <Link
      href={hrefToSend}
      onClick={handleOnClick}
      preventDefault={false}
      wrapper={NextLink}
    >
      <Button
        label={label}
        minWidth='auto'
        type='sub-category'
      />
    </Link>
  )
}
