import { useEffect, useState } from 'react'

export const useRetryAfter = ({ increase }: { increase: number }) => {
  const [retryAfter, setRetryAfter] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (retryAfter > 0) {
        setRetryAfter(retryAfter + increase)
      }
    }, 1000)
    return () => {
      return clearInterval(interval)
    }
  }, [retryAfter])

  return {
    retryAfter,
    setRetryAfter
  }
}
