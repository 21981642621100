import { IEntriesProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IContentful'

export const contentBannerLegal = (content: IEntriesProps) => {
  if (!content?.items[0]) return {}
  const bannerImages = content?.includes?.Asset?.map((imageUrl) => {
    const url = imageUrl?.fields?.file?.url

    if (imageUrl?.fields?.title === 'PLP mobile') {
      return { mobile: url }
    }
    return { desktop: url }
  })

  const contentLegalTitle =
    content?.items?.[0]?.fields?.legalPromo?.content[0]?.content[0]?.value
  const contentLegalParagraph =
    content?.items?.[0]?.fields?.legalPromo?.content[1]?.content.map((data) => {
      if (data?.nodeType === 'hyperlink') {
        return {
          value: data?.content[0]?.value,
          type: 'link',
          url: data?.data?.uri
        }
      }

      return {
        value: data?.value,
        type: data?.marks ? data?.marks[0]?.type : ''
      }
    })

  const {
    alt,
    creativeName,
    creativeSlot,
    locationId,
    promotionId,
    promotionName,
    reference,
    saleChannels,
    url,
    urlPath
  } = content?.items?.[0]?.fields || {}
  const searchBannerData = {
    alt,
    creativeName: creativeName,
    creativeSlot: creativeSlot,
    locationId: locationId,
    promotionId: promotionId,
    promotionName: promotionName,
    saleChannels: saleChannels,
    urlPath: reference || url || urlPath
  }

  return {
    bannerImages,
    contentLegalTitle,
    contentLegalParagraph,
    searchBannerData
  }
}
