import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const getValidationTextColor = (success?: boolean) => {
  if (typeof success === 'boolean') {
    return success
      ? getGlobalStyle('--color-feedback-success-light')
      : getGlobalStyle('--color-feedback-error-light')
  }

  return getGlobalStyle('--color-neutral-gray-dark')
}
